var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-header"
  }, [_c('router-link', {
    staticClass: "logo-wrapper",
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo.png")
    }
  })])]), _c('div', {
    staticClass: "group"
  }, [_c('sygni-context-select', {
    attrs: {
      "searchable": true,
      "options": _vm.dropdownUserContextOptions,
      "hasAfterlist": _vm.activeUserDataContext === 'fund-manager' || _vm.activeUserDataContext === 'private-fund-manager'
    },
    on: {
      "input": _vm.contextHandler,
      "addNewFund": function addNewFund($event) {
        return _vm.$router.push({
          name: 'select-fund'
        });
      }
    },
    model: {
      value: _vm.activeUserContextId,
      callback: function callback($$v) {
        _vm.activeUserContextId = $$v;
      },
      expression: "activeUserContextId"
    }
  }), _c('logout-button', {
    staticClass: "logout-button",
    on: {
      "click": function click($event) {
        return _vm.logout();
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }