import Vue from 'vue'
import i18n from './plugins/i18n';
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store/rootStore'
import './styles/styles.scss'
import './plugins/filters';
import './plugins/vuelidate';
import './plugins/notifications';
import './plugins/tooltips';
import './plugins/popovers';
import './plugins/clipboard';
import './plugins/vClickOutside';
import './plugins/simplebar';
import PortalVue from 'portal-vue';
const VueScrollTo = require('vue-scrollto');
import VueCookies from 'vue-cookies'
// @ts-ignore
import Pivot from "vue-flexmonster";
import 'flexmonster/flexmonster.css';
Vue.use(Pivot);

export const EventBus = new Vue();

Vue.config.productionTip = false
Vue.use(VueScrollTo, {
  container: '.app-content',
  duration: 200,
  force: false,
  offset: -15,
});
Vue.use(PortalVue);

Vue.use(VueCookies, { expire: '1d'})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

const html = document.querySelector('html') as HTMLElement;
html.dataset.theme = `theme-light`;
// html.dataset.theme = `theme-dark`;

/*
window.store = store; // TODO: delete it if not commented, used to debug vuex
*/
