import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex'
import { _axios as axios } from "@/plugins/axios";
import {RootState} from "@/store/types";
import {auth} from "@/modules/auth/store";
import {investors } from "@/modules/genprox/modules/fund/modules/fundraising/store";
import {capital } from "@/modules/genprox/modules/fund/modules/capital/store";
import {genprox} from "@/modules/genprox/store";
import {dictionary} from "@/modules/shared/dictionary-store";
import {AIFM} from "@/modules/genprox/modules/AIFM/store";
import {regReporting} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/store";
import {fund} from "@/modules/genprox/modules/fund/store";
import {profile} from "@/modules/genprox/modules/profile/store";
import {accounting} from "@/modules/accounting/store";
import {statements} from "@/modules/statements/store";
import { whistleblower } from "@/modules/genprox/modules/whistleblower/store";
import { portfolio } from "@/modules/genprox/modules/fund/modules/portfolio/store";
import { insights } from "@/modules/genprox/modules/insights/store";
import { transactions } from "@/modules/genprox/modules/fund/modules/transactions/store";
import { products } from '@/modules/genprox/modules/fund/modules/fundraising/modules/products/store';
import { templates } from '@/modules/genprox/modules/fund/modules/fundraising/modules/templates/store';
import { campaigns } from '@/modules/genprox/modules/fund/modules/fundraising/modules/campaigns/store';
import { advisors } from '@/modules/genprox/modules/fund/modules/fundraising/modules/advisors/store';
import { subscriptions } from '@/modules/genprox/modules/fund/modules/fundraising/modules/subscriptions/store';
import { reporting } from '@/modules/genprox/modules/reporting/store';

Vue.prototype.$http = axios;
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

const store: StoreOptions<RootState> = {
  modules: {
    auth,
    investors,
    genprox,
    dictionary,
    AIFM,
    regReporting,
    fund,
    profile,
    accounting,
    whistleblower,
    portfolio,
    insights,
    capital,
    transactions,
    products,
    templates,
    campaigns,
    advisors,
    reporting,
    subscriptions,
    statements
  },
  strict: true,
  plugins: debug ? [] : [],
};

export default new Vuex.Store<RootState>(store);
