import {RouteConfig} from "vue-router";
import {AddRegRepType} from "@/modules/genprox/modules/fund/modules/reg-reporting/store/types";
import store from "@/store/rootStore";

const regReportingModule = () => import('./containers/RegReportingModule.vue');
const regDashboardModule = () => import('./containers/RegDashboardModule.vue');
const regReportingBlindModule = () => import('./containers/RegReportingBlindModule.vue');
const addReport = () => import('./../../../AIFM/modules/reg-reporting/containers/AddNewReport.vue');
const knfXmlPreview = () => import('./../../../AIFM/modules/reg-reporting/containers/KnfXmlPreview.vue');
const xmlPreview = () => import('./../../../AIFM/modules/reg-reporting/containers/XmlPreview.vue');

export const regReporting: Array<RouteConfig>  = [
    {
        path: 'regulatory-reporting',  /* /fund */
        name: 'fund-regulatory-reporting',
        component: regReportingModule,
        props: true,
        children: [],
        async beforeEnter(to, from, next) {
            await store.dispatch('genprox/getAccessModules');
            const accessModules = store.getters['genprox/accessModules'];
            if(accessModules.regulatoryReporting) {
                next();
            } else {
                next({ name: 'reg-reporting-access-denied' });
            }
        },
    },
    {
        path: 'dashboard',  /* /fund */
        name: 'fund-reg-dashboard',
        component: regDashboardModule,
    },
    {
        path: 'reg-reporting/add-report',
        name: 'fund-reg-reporting',
        props: (route) => ({ pageType: AddRegRepType.FUND}),
        component: addReport,
        async beforeEnter(to, from, next) {
            await store.dispatch('genprox/getAccessModules');
            const accessModules = store.getters['genprox/accessModules'];
            if(accessModules.regulatoryReporting) {
                next();
            } else {
                next({ name: 'reg-reporting-access-denied' });
            }
        },
    },
    {
        path: 'reg-reporting/knf-xml-preview',
        name: 'fund-reg-xml-preview',
        props: true,
        component: knfXmlPreview,
    },
    {
        path: 'reg-reporting/xml-preview',
        name: 'fund-xml-preview',
        props: true,
        component: xmlPreview,
    },
    {
        path: 'regulatory-reporting/access-denied',
        name: 'reg-reporting-access-denied',
        component: regReportingBlindModule,
    }
];
