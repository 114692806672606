import {MutationTree} from "vuex";
import {SelectOption} from "@/store/types";
import {DictionaryState} from "@/modules/shared/dictionary-store/types";

const investorSelects = {
    setInvestmentKnowledgeSelectOptions(state: DictionaryState, payload: Array<SelectOption>): void {
        state.investorSelects.investmentKnowledge = payload;
    },
    setDocumentTypeSelectOptions(state: DictionaryState, payload: Array<SelectOption>): void {
        state.investorSelects.documentType = payload;
    },
    setInvestmentPotentialsSelectOptions(state: DictionaryState, payload: Array<SelectOption>): void {
        state.investorSelects.investmentPotentials = payload;
    },
    setSourceNames(state: DictionaryState, payload: Array<SelectOption>): void {
        state.investorSelects.sources = payload;
    },
    setIndustries(state: DictionaryState, payload:Array<SelectOption<string>>){
        state.investorSelects.industries = payload;
    },
}

const addProductSelects = {
    setProductTypeSelectOptions(state: DictionaryState, payload: Array<SelectOption>): void {
        state.addProductsSelects.productType = payload;
    },
    setDurationSelectOptions(state: DictionaryState, payload: Array<SelectOption<string>>){
        state.addProductsSelects.duration = payload;
    },
    setCommisionsPayment(state: DictionaryState, payload: Array<SelectOption<string>>){
        state.addProductsSelects.commisionPayment = payload;
    },
    setInterestType(state: DictionaryState, payload: Array<SelectOption<string>>){
        state.addProductsSelects.interestType = payload;
    },
    setMarginPayment(state: DictionaryState, payload: Array<SelectOption<string>>){
        state.addProductsSelects.marginPayment = payload;
    },
    setInterestPayment(state: DictionaryState, payload: Array<SelectOption<string>>){
        state.addProductsSelects.interestPayment = payload;
    },
    setInterestCalculationMethod(state: DictionaryState, payload: Array<SelectOption<string>>){
        state.addProductsSelects.interestCalculationMethod = payload;
    },
    setProductClass(state: DictionaryState, payload: Array<SelectOption<string>>){
        state.addProductsSelects.class = payload;
    },
    setProductSourceName(state: DictionaryState, payload: Array<SelectOption>): void {
        state.addProductsSelects.source = payload;
    },
    setAIFMCategories(state: DictionaryState, payload: Array<SelectOption>): void {
        state.AIFMSelects.categories = payload;
    },
    setLegalForm(state: DictionaryState, payload: Array<SelectOption>): void {
        state.legalEntitySelects.legalForm = payload;
    },
    setProgramme(state: DictionaryState, payload: Array<SelectOption>): void {
        state.legalEntitySelects.programme = payload;
    },
    setEndOfFinancialPeriod(state: DictionaryState, payload: Array<SelectOption>): void {
        state.legalEntitySelects.endOfFinancialPeriod = payload;
    }
}

const globalSelects = {
    setCountries(state: DictionaryState, payload: Array<SelectOption<string>>): void {
        state.globalSelects.countries = payload;
    },
    setBrokerPermissionsSelectOptions(state: DictionaryState, payload: Array<SelectOption>): void {
        state.globalSelects.brokerPermissions = payload;
    },
}

export const mutations: MutationTree<DictionaryState> = {
    ...investorSelects,
    ...globalSelects,
    ...addProductSelects,
}
