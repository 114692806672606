import Vue from "vue";
import Vuelidate from "vuelidate";
import Utils from "@/modules/shared/utils/utils";

export function bankAccount(value: string){
    if (!value) return true;
    return Utils.getNumbersFromString(value).length === 26 &&   // has 26 numbers
        ( value.slice(0,2).toUpperCase() === 'PL' || Boolean(String(value).match('^[0-9 ]*$'))) // AND starts with 'PL' OR has only numbers
}

export function zipCode(value: string){
    if (!value) return true;
    return Boolean(value.match('^[0-9-]*$')) && value.length >= 5
}
Vue.use(Vuelidate);

