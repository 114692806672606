import { ActionTree, GetterTree, Module, MutationTree, Store } from "vuex";
import { RootState } from "@/store/types";
import { StatementState, StatementItem } from "./types";
import { _axios as axios } from "@/plugins/axios";
import {
  BACKEND_API_URL,
} from "@/shared/consts";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";
import _ from "lodash";



export const state: StatementState = {
  statementsTableIsBusy: false,
  statementsTable: {
    items: [],
    totalCount: 0,
    perPage: 10,
    query: new TableQuery(10),
  },
  transactionsTableIsBusy: false,
  transactionsTable: {
    items: [],
    totalCount: 0,
    perPage: 10,
    query: new TableQuery(10),
  },
  statementProductsTableIsBusy: false,
  statementProductsTable: {
    items: [],
    totalCount: 0,
    perPage: 20,
    query: new TableQuery(20),
  },
  statementTransactionsTableIsBusy: false,
  statementTransactionsTable: {
    items: [],
    totalCount: 0,
    perPage: 20,
    query: new TableQuery(20),
  },
};

export const getters: GetterTree<StatementState, RootState> = {
  // statements
  getStatementsTableItems(state) {
    return JSON.parse(JSON.stringify(state.statementsTable.items));
  },
  getStatementsTableQuery(state) {
    return JSON.parse(JSON.stringify(state.statementsTable.query));
  },
  getStatementsTablePerPage(state) {
    return JSON.parse(JSON.stringify(state.statementsTable.perPage));
  },
  getStatementsTableFiltersQuery(state) {
    return JSON.parse(JSON.stringify(state.statementsTable.query.filters));
  },
  // transactions
  getTransactionsTableItems(state) {
    return JSON.parse(JSON.stringify(state.transactionsTable.items));
  },
  getTransactionsTableQuery(state) {
    return JSON.parse(JSON.stringify(state.transactionsTable.query));
  },
  getTransactionsTablePerPage(state) {
    return JSON.parse(JSON.stringify(state.transactionsTable.perPage));
  },
  getTransactionsTableFiltersQuery(state) {
    return JSON.parse(JSON.stringify(state.transactionsTable.query.filters));
  },
  // statement products
  getStatementProductsTableItems(state) {
    return JSON.parse(JSON.stringify(state.statementProductsTable.items));
  },
  getStatementProductsTableQuery(state) {
    return JSON.parse(JSON.stringify(state.statementProductsTable.query));
  },
  getStatementProductsTablePerPage(state) {
    return JSON.parse(JSON.stringify(state.statementProductsTable.perPage));
  },
  getStatementProductsTableFiltersQuery(state) {
    return JSON.parse(JSON.stringify(state.statementProductsTable.query.filters));
  },
  // statement transactions
  getStatementTransactionsTableItems(state) {
    return JSON.parse(JSON.stringify(state.statementTransactionsTable.items));
  },
  getStatementTransactionsTableQuery(state) {
    return JSON.parse(JSON.stringify(state.statementTransactionsTable.query));
  },
  getStatementTransactionsTablePerPage(state) {
    return JSON.parse(JSON.stringify(state.statementTransactionsTable.perPage));
  },
  getStatementTransactionsTableFiltersQuery(state) {
    return JSON.parse(JSON.stringify(state.statementTransactionsTable.query.filters));
  },
};

export const mutations: MutationTree<StatementState> = {
  // statements
  setStatementsTableItems(
    state,
    payload: { items: Array<any>; totalCount: number }
  ): void {
    state.statementsTable = {
      ...state.statementsTable,
      items: payload.items,
      totalCount: payload.totalCount,
    };
  },
  setStatementsTableQuery(state, payload: TableQuery): void {
    state.statementsTable.query = payload;
  },
  setStatementsTableBusy(state, payload: boolean): void {
    state.statementsTableIsBusy = payload;
  },
  setStatementsTablePerPage(state, payload: number): void {
    state.statementsTable.perPage = payload;
  },
  setStatementsTableSortingQuery(state, payload: Sorting): void {
    state.statementsTable.query.sorting = payload;
    state.statementsTable.query.offset = 0;
  },
  setStatementsTableFiltersQuery(state, payload: string) {
    state.statementsTable.query.filters = payload;
  },
  // transactions
  setTransactionsTableItems(
    state,
    payload: { items: Array<any>; totalCount: number }
  ): void {
    state.transactionsTable = {
      ...state.transactionsTable,
      items: payload.items,
      totalCount: payload.totalCount,
    };
  },
  setTransactionsTableQuery(state, payload: TableQuery): void {
    state.transactionsTable.query = payload;
  },
  setTransactionsTableBusy(state, payload: boolean): void {
    state.transactionsTableIsBusy = payload;
  },
  setTransactionsTablePerPage(state, payload: number): void {
    state.transactionsTable.perPage = payload;
  },
  setTransactionsTableSortingQuery(state, payload: Sorting): void {
    state.transactionsTable.query.sorting = payload;
    state.transactionsTable.query.offset = 0;
  },
  setTransactionsTableFiltersQuery(state, payload: string) {
    state.transactionsTable.query.filters = payload;
  },
  // statement products
  setStatementProductsTableItems(
    state,
    payload: { items: Array<any>; totalCount: number }
  ): void {
    state.statementProductsTable = {
      ...state.statementProductsTable,
      items: payload.items,
      totalCount: payload.totalCount,
    };
  },
  setStatementProductsTableQuery(state, payload: TableQuery): void {
    state.statementProductsTable.query = payload;
  },
  setStatementProductsTableBusy(state, payload: boolean): void {
    state.statementProductsTableIsBusy = payload;
  },
  setStatementProductsTablePerPage(state, payload: number): void {
    state.statementProductsTable.perPage = payload;
  },
  setStatementProductsTableSortingQuery(state, payload: Sorting): void {
    state.statementProductsTable.query.sorting = payload;
    state.statementProductsTable.query.offset = 0;
  },
  setStatementProductsTableFiltersQuery(state, payload: string) {
    state.statementProductsTable.query.filters = payload;
  },
  // statement transactions
  setStatementTransactionsTableItems(
    state,
    payload: { items: Array<any>; totalCount: number }
  ): void {
    state.statementTransactionsTable = {
      ...state.statementTransactionsTable,
      items: payload?.items || [],
      totalCount: payload?.totalCount,
    };
  },
  setStatementTransactionsTableQuery(state, payload: TableQuery): void {
    state.statementTransactionsTable.query = payload;
  },
  setStatementTransactionsTableBusy(state, payload: boolean): void {
    state.statementTransactionsTableIsBusy = payload;
  },
  setStatementTransactionsTablePerPage(state, payload: number): void {
    state.statementTransactionsTable.perPage = payload;
  },
  setStatementTransactionsTableSortingQuery(state, payload: Sorting): void {
    state.statementTransactionsTable.query.sorting = payload;
    state.statementTransactionsTable.query.offset = 0;
  },
  setStatementTransactionsTableFiltersQuery(state, payload: string) {
    state.statementTransactionsTable.query.filters = payload;
  },
};

export const actions: ActionTree<StatementState, RootState> = {
  // get statements
  async getStatements(
    { state, commit },
    filtersQuery?: string
  ): Promise<Array<StatementItem>> {
    const queryString = state.statementsTable.query.getStringQuery();
    filtersQuery = filtersQuery ? filtersQuery : "";
    const queryData = await axios.get(
      `${BACKEND_API_URL}/bank-statement/statement${queryString}${filtersQuery}`,
      { headers: { "x-total-count": true } }
    );

    const payload: any = {
      items: queryData.data,
      totalCount: queryData.headers["x-total-count"],
    };
    commit("setStatementsTableItems", {
      items: payload.items,
      totalCount: payload.totalCount,
    });

    return payload.items;
  },
  // get transactions
  async getTransactions({ state, commit }, payload: { filtersQuery: string, id: string }): Promise<StatementItem> {
    const queryString = state.transactionsTable.query.getStringQuery();
    payload.filtersQuery = payload.filtersQuery ? payload.filtersQuery : "";
    const queryData = await axios.get(
      `${BACKEND_API_URL}/bank-statement/${payload.id}/transaction${queryString}${payload.filtersQuery}`,
      { headers: { "x-total-count": true } }
    );

    const payloadData: any = {
      items: queryData.data,
      totalCount: queryData.headers["x-total-count"],
    };
    commit("setTransactionsTableItems", {
      items: payloadData.items,
      totalCount: payloadData.totalCount,
    });

    return payloadData.items;
  },
  // get statement products
  async getStatementProducts({ state, commit }, filtersQuery?: string): Promise<StatementItem> {
    const queryString = state.statementProductsTable.query.getStringQuery();
    filtersQuery = filtersQuery ? `${filtersQuery}&filters[type][0][value]=product&filters[type][0][operator]=in` : "&filters[type][0][value]=product&filters[type][0][operator]=in";
    const queryData = await axios.get(
      `${BACKEND_API_URL}/bank-statement/payment/for-product${queryString}${filtersQuery}`,
      { headers: { "x-total-count": true } }
    );

    const payloadData: any = {
      items: queryData.data,
      totalCount: queryData.headers["x-total-count"],
    };
    commit("setStatementProductsTableItems", {
      items: payloadData.items,
      totalCount: payloadData.totalCount,
    });

    return payloadData.items;
  },
  // get statement transactions
  async getStatementTransactions({ state, commit }, filtersQuery?: string): Promise<StatementItem> {
    const queryString = state.statementTransactionsTable.query.getStringQuery();
    filtersQuery = filtersQuery ? filtersQuery : "";
    const queryData = await axios.get(
      `${BACKEND_API_URL}/bank-statement/transaction/for-products${queryString}${filtersQuery}`,
      { headers: { "x-total-count": true } }
    );

    const payloadData: any = {
      items: queryData.data,
      totalCount: queryData.headers["x-total-count"],
    };
    commit("setStatementTransactionsTableItems", {
      items: payloadData?.items || [],
      totalCount: payloadData.totalCount,
    });

    return payloadData.items;
  },
  async getStatement({ state, commit }, id: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/bank-statement/statement/${id}`)

    return data
  },
  async getAllStatements({ state, commit }): Promise<Array<StatementItem>> {
    const response = await axios.get(
      `${BACKEND_API_URL}/bank-statement/statement?offset=0&limit=99999`,
      { headers: { "x-total-count": true } }
    );

    return response.data.items;
  },
  async patchBankStatements({ state, commit }) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/bank-statement/statement/sync`)

    return data
  },
  async patchBankTransactions({ state, commit }, id: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/bank-statement/${id}/transaction/sync`)

    return data
  },
  async setClosedBankStatement({ state, commit }, id: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/bank-statement/statement/${id}/set-closed`)

    return data
  },
  async bulkSetClosedBankStatement({ state, commit }, ids: string[]) {
    const promises: Array<any> = [];
    if(typeof ids === 'string') {
      ids = [ids];
    }

    ids.forEach((id: string) => {
      promises.push(axios.patch(`${BACKEND_API_URL}/bank-statement/statement/${id}/set-closed`));
    })

    const response = await Promise.all(promises);

    return response;
  },
  async setInProgressBankStatement({ state, commit }, id: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/bank-statement/statement/${id}/set-in-progress`)

    return data
  },
  async bulkSetInProgressBankStatement({ state, commit }, ids: string[]) {
    const promises: Array<any> = [];
    if(typeof ids === 'string') {
      ids = [ids];
    }

    ids.forEach((id: string) => {
      promises.push(axios.patch(`${BACKEND_API_URL}/bank-statement/statement/${id}/set-in-progress`));
    })

    const response = await Promise.all(promises);

    return response;
  },
  async matchBankTransaction({ state, commit }, payload: { transactionId: string, objectId: string, objectType: string }) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/bank-statement/transaction/${payload.transactionId}/match`, { objectId: payload.objectId, objectType: payload.objectType })

    return data
  },
  async unmatchBankTransaction({ state, commit }, transactionId: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/bank-statement/transaction/${transactionId}/unmatch`)

    return data
  },
  async getInvestorCounterpartyList({ state, commit }, investmentClientId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/bank-statement/investment-client/${investmentClientId}/counterparty`)

    return data
  },
  async addNewCounterpartyToInvestmentClient({ state }, investmentClientId: string) {
    const { data } = await axios.post(`${BACKEND_API_URL}/bank-statement/investment-client/${investmentClientId}/counterparty`)

    return data
  },
  async syncNewCounterpartyToInvestmentClient({ state }, investmentClientId: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/bank-statement/investment-client/${investmentClientId}/counterparty/sync`)

    return data
  },
  async approveNewCounterpartyForInvestmentClient({ state }, payload: { investmentClientId: string, counterpartyId: string }) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/bank-statement/investment-client/${payload.investmentClientId}/counterparty/${payload.counterpartyId}`)

    return data
  },
  async checkInvestmentClientCounterpartyInfo({ state }, investmentClientId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/bank-statement/investment-client/${investmentClientId}/counterparty/info`)

    return data
  },
  async getBankAccounts({ state }) {
    const { data } = await axios.get(`${BACKEND_API_URL}/bank-statement/statement/bank-account`)

    return data
  },
  async generateOppositeAccount({ state }, payload: { transactionId: string, objectType: string, objectId: string }) {
    const { data } = await axios.get(`${BACKEND_API_URL}/bank-statement/transaction/${payload.transactionId}/${payload.objectType}/${payload.objectId}/generate-opposite-account`)

    return data
  },
  async checkOppositeAccount({ state }, payload: { oppositeAccount: string, accountingPeriod: string }) {
    const { data } = await axios.get(`${BACKEND_API_URL}/bank-statement/opposite-account/${payload.oppositeAccount}/accounting-period/${payload.accountingPeriod}-01-01/exists`)

    return data
  },
  async addOppositeAccount({ state }, payload: { oppositeAccount: string, accountingPeriod: string, object: { objectType: string, objectId: string } }) {
    const { data } = await axios.post(`${BACKEND_API_URL}/bank-statement/opposite-account/${payload.oppositeAccount}/accounting-period/${payload.accountingPeriod}-01-01/sync`, payload.object)

    return data
  },
  async bookTransaction({ state }, payload: { transactionId: string, oppositeAccount: string, accountingDate: string }) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/bank-statement/transaction/${payload.transactionId}/book`, { oppositeAccount: payload.oppositeAccount, accountingDate: `${payload.accountingDate}-01-01` })

    return data
  }
};

export const statements: Module<StatementState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
