





















import Vue from "vue";
import Component from "vue-class-component";
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop} from "vue-property-decorator";

@Component({
  components: {SygniCard},
})
export default class FundFinancials extends Vue {
  @Prop() fundFinancials: any;

  formatK(n: number) {
    const isNegative: boolean = !!(n < 0)
    const value = Math.abs(n)
    if (value < 1e3) return value;
    if (value >= 1e3) {
      let number: string | number = +(value / 1e3).toFixed(0);
      number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      return `${isNegative ? '-' : ''}` + number + " K"
    }
  }

  setCurrency(el: any) {
    if (el.value == null) {
      return '---';
    } else {
      return this.formatK(el.value.value);
    }
  }
}
