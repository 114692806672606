











import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component
export default class SygniDetails extends Vue {
  @Prop() items!: Array<any>;
}
