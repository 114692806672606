
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL} from "@/shared/consts";
import { SubscriptionsState } from "./types";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";

export const state: SubscriptionsState = {
  subscriptionsTable: {
    items: [],
    totalCount: 0,
    perPage: 10,
    query: new TableQuery(10),
  },
  subscriptionsTableIsBusy: false,
}

export const getters: GetterTree<SubscriptionsState, RootState> = {
  getSubscriptionsTableItems(state) {
    return JSON.parse(JSON.stringify(state.subscriptionsTable.items));
  },
  getSubscriptionsTableQuery(state) {
    return JSON.parse(JSON.stringify(state.subscriptionsTable.query));
  },
  getSubscriptionsTablePerPage(state) {
    return JSON.parse(JSON.stringify(state.subscriptionsTable.perPage));
  },
}

export const mutations: MutationTree<SubscriptionsState> = {
  setSubscriptionsTableItems(state, payload: {items: Array<any>, totalCount: number}): void {
    state.subscriptionsTable = { ...state.subscriptionsTable, items: payload.items, totalCount: payload.totalCount};
  },
  setSubscriptionsTableQuery(state, payload: TableQuery): void {
    state.subscriptionsTable.query = payload;
  },
  setSubscriptionsTableSortingQuery(state, payload: Sorting): void { 
    state.subscriptionsTable.query.sorting = payload;
    state.subscriptionsTable.query.offset = 0;
  },
  setSubscriptionsTableBusy(state,payload: boolean): void {
    state.subscriptionsTableIsBusy = payload;
  },
  setSubscriptionsTablePerPage(state, payload: number): void {
    state.subscriptionsTable.perPage = payload;
  },
  setSubscriptionsTableFiltersQuery(state, payload: string) {
    state.subscriptionsTable.query.filters = payload;
  },
}

export const actions: ActionTree<SubscriptionsState, RootState> = {
  async getSubscriptions({state,commit}, filtersQuery?: string): Promise<Array<any>> {
    const queryString = state.subscriptionsTable.query.getStringQuery();
    filtersQuery = filtersQuery ? filtersQuery : '';
    const queryData = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/subscription${queryString}${filtersQuery}`, { headers: { 'x-total-count': true} });

    const payload: any = { items: queryData.data, totalCount: queryData.headers['x-total-count'] }

    commit('setSubscriptionsTableItems', {
      items: payload.items,
      totalCount: payload.totalCount,
    });

    return payload.items;
  },
  async getAllSubscriptions({state,commit}): Promise<Array<any>> {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/subscription?limit=99999&offset=0`);

    return data
  },
  async getSubscription({state, commit}, subscriptionId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/subscription/${subscriptionId}`);

    return data;
  },
  async postSubscription({state, commit}, payload: any) {
    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/subscription`, payload);

    return data;
  },
  async registerSubscription({state, commit}, payload: { data: { registrationDate: string }, id: string }) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/subscription/${payload?.id}/register`, payload.data)

    return data;
  },
  async unregisterSubscription({state, commit}, id: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/subscription/${id}/unregister`)

    return data;
  },
  async editSubscription({state, commit}, payload: { subscriptionId: string, data: any }) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/subscription/${payload.subscriptionId}`, payload.data);

    return data;
  },
  async updateSubscriptionAttachments({state, commit}, payload: { id: string, data: any }) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/subscription/${payload.id}/attachments`, payload.data);

    return data;
  },
  async getSubscriptionProducts({state, commit}, subscriptionId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/products/by-subscription/${subscriptionId}`)

    return data
  },
  async passSubscription({state, commit}, subscriptionId: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/subscription/${subscriptionId}/pass`)

    return data
  },
  async forceSubscriptionPass({state, commit}, subscriptionId: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/subscription/${subscriptionId}/pass/force`)

    return data
  },
  async cancelSubscription({state, commit}, subscriptionId: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/subscription/${subscriptionId}/cancel`)

    return data
  },
  async getSeriesOfShares({state}) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/series-of-shares`)
    
    return data
  },
  async getSlots({state}, subscriptionId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/subscription/${subscriptionId}/available-slots`)
    
    return data
  },
}

export const subscriptions: Module<SubscriptionsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

