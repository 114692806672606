

import {ActionTree, GetterTree, Module, MutationTree, Store} from "vuex";
import {RootState} from "@/store/types";
import { DocumentItem, GetDocumentsResponse, CounterParty, ReportMessageDto } from './types';
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL, BACKEND_BASE_URL, ibanSupportedCountries} from "@/shared/consts";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";
import { Address, TableData } from "@/modules/genprox/modules/profile/store/types";
import _ from 'lodash';
import Utils from "@/modules/shared/utils/utils";

export interface ContextData {
    address: Address,
    description: string | null,
    email: string,
    establishmentDate: string,
    hasApprovers: boolean,
    id: string,
    legalForm: string,
    logo: string | null,
    name: string,
    registerNumber: string,
    statisticalNumber: string,
    taxNumber: string | null,
    twoFactorRequired: boolean,
    www: string | null,
}

export interface AccountingState {
    documentsUploadProgress: {
        progress: number,
    },
    attachmentsUploadProgress: {
        progress: number,
    },
    documentsTableIsBusy: boolean,
    documentsTable: TableData<DocumentItem>,
    dictionaries: any,
    paginationData: {
        selectedDocument: string,
        activeDocumentIndex?: number,
        nextDocumentIndex?: number,
        prevDocumentIndex?: number,
        items: Array<DocumentItem>,
    },
    bankAccounts?: any,
    contextData: ContextData,
}

export const state: AccountingState = {
    documentsUploadProgress: {
        progress: -1,        
    },
    attachmentsUploadProgress: {
        progress: -1,
    },
    documentsTableIsBusy: false,
    documentsTable: {
        items: [],
        totalCount: 0,
        perPage: 10,
        query: new TableQuery(10),
    },
    dictionaries: null,
    paginationData: {
        selectedDocument: '',
        activeDocumentIndex: undefined,
        nextDocumentIndex: undefined,
        prevDocumentIndex: undefined,
        items: [],
    },
    bankAccounts: undefined,
    contextData: null,
}

export const getters: GetterTree<AccountingState, RootState> = {
    getContextData(state) {
        return JSON.parse(JSON.stringify(state.contextData));
    },
    getDocumentsTableItems(state) {
        return JSON.parse(JSON.stringify(state.documentsTable.items));
    },
    getDocumentsTableQuery(state) {
        return JSON.parse(JSON.stringify(state.documentsTable.query));
    },
    getDocumentsTablePerPage(state) {
        return JSON.parse(JSON.stringify(state.documentsTable.perPage));
    },
    getDocumentsUploadProgress(state) {
        return JSON.parse(JSON.stringify(state.documentsUploadProgress));
    },
    getDictionaries(state) {
        return JSON.parse(JSON.stringify(state.dictionaries));
    },
    getDocumentsTableFiltersQuery(state) {
        return JSON.parse(JSON.stringify(state.documentsTable.query.filters));
    },
    getSelectedDocument(state) {
        return JSON.parse(JSON.stringify(state.paginationData.selectedDocument));
    },
    activeDocumentIndex(state) {
        return JSON.parse(JSON.stringify(state.paginationData.activeDocumentIndex));
    },
    nextDocumentIndex(state) {
        return state.paginationData.nextDocumentIndex;
    },
    prevDocumentIndex(state) {
        return state.paginationData.prevDocumentIndex;
    },
    prevDocument: (state) => {
        return state.documentsTable.items[state.paginationData.prevDocumentIndex - state.documentsTable.query.offset];
    },
    nextDocument: (state) => {
        return state.documentsTable.items[state.paginationData.nextDocumentIndex - state.documentsTable.query.offset];
    },
    paginationDataItems: (state) => {
        return JSON.parse(JSON.stringify(state.paginationData.items));
    },
    getBankAccounts: (state) => {
        return JSON.parse(JSON.stringify(state.bankAccounts));
    }
}

export const mutations: MutationTree<AccountingState> = {
    setContextData(state, payload: ContextData) {
        state.contextData = payload;
    },
    setDocumentsTableItems(state, payload: {items: Array<DocumentItem>, totalCount: number}): void {
        state.documentsTable = { ...state.documentsTable, items: payload.items, totalCount: payload.totalCount};
    },
    setPaginationDataItems(state, payload: {items: Array<DocumentItem>}): void {
        state.paginationData.items = payload.items;
    },
    setDocumentsTableQuery(state, payload: TableQuery): void {
        state.documentsTable.query = payload;
    },
    setDocumentsTableBusy(state,payload: boolean): void {
        state.documentsTableIsBusy = payload;
    },
    setDocumentsTablePerPage(state, payload: number): void {
        state.documentsTable.perPage = payload;
    },
    setDocumentsUploadProgress(state, payload: number): void {
        state.documentsUploadProgress = {
            progress: payload
        };
    },
    setAttachmentsUploadProgress(state, payload: number): void {
        state.attachmentsUploadProgress = {
            progress: payload
        };
    },
    setDocumentsTableSortingQuery(state, payload: Sorting): void {
        state.documentsTable.query.sorting = payload;
        state.documentsTable.query.offset = 0;
    },
    setDictionaries(state, payload: any): void {
        state.dictionaries = payload;
    },
    clearDictionaries(state) {
        state.dictionaries = null;
    },
    setDocumentsTableFiltersQuery(state, payload: string) {
        state.documentsTable.query.filters = payload;
    },
    setSelectedDocument(state, payload) {
        state.paginationData.selectedDocument = (`${BACKEND_BASE_URL}${payload.pdfFilePath}` == state.paginationData.selectedDocument) ? '' : `${BACKEND_BASE_URL}${payload.pdfFilePath}`
        localStorage.setItem('accountingPaginationDataSelectedDocument', state.paginationData.selectedDocument);
    },
    clearSelectedDocument(state) {
        state.paginationData.selectedDocument = '';
        localStorage.removeItem('accountingPaginationDataSelectedDocument');
    },
    setDocumentIndexes(state, index) {
        state.paginationData.activeDocumentIndex = index;
        localStorage.setItem('accountingPaginationDataActiveIndex', index);

        state.paginationData.prevDocumentIndex = (state.paginationData.activeDocumentIndex == undefined) ? undefined : (Number(state.paginationData.activeDocumentIndex) == 0) ? Number(state.documentsTable.totalCount) - 1 : Number(state.paginationData.activeDocumentIndex) - 1;

        state.paginationData.nextDocumentIndex = (state.paginationData.activeDocumentIndex == undefined) ? undefined : (Number(state.documentsTable.totalCount) <= Number(state.paginationData.activeDocumentIndex) + 1) ? 0 : Number(state.paginationData.activeDocumentIndex) + 1;
    },
    clearDocumentIndexes(state) {
        state.paginationData.activeDocumentIndex = undefined;
        localStorage.removeItem('accountingPaginationDataActiveIndex');
        state.paginationData.nextDocumentIndex = undefined;
        state.paginationData.prevDocumentIndex = undefined;
    },
    setBankAccounts(state, payload) {
        state.bankAccounts = payload;
    }
}

export const actions: ActionTree<AccountingState, RootState> = {
    async getDocuments({state,commit}, filtersQuery?: string): Promise<Array<DocumentItem>> {
        const queryString = state.documentsTable.query.getStringQuery();
        filtersQuery = filtersQuery ? filtersQuery : '';
        const queryData = await axios.get(`${BACKEND_API_URL}/document${queryString}${filtersQuery}`, { headers: { 'x-total-count': true} });

        const payload: GetDocumentsResponse = { items: queryData.data.items, totalCount: queryData.headers['x-total-count'] }
        commit('setDocumentsTableItems', {
            items: payload.items,
            totalCount: payload.totalCount,
        });

        return payload.items;
    },
    async getAllDocuments({state,commit}): Promise<Array<DocumentItem>> {
        const response = await axios.get(`${BACKEND_API_URL}/document?offset=0&limit=99999`, { headers: { 'x-total-count': true} });

        commit('setPaginationDataItems', {
            items: response.data.items
        });

        return response.data.items;
    },
    async getDocument({state,commit}, id: string): Promise<DocumentItem> {
        const response = await axios.get(`${BACKEND_API_URL}/document/${id}`, { headers: { 'x-total-count': true} });

        return response.data;
    },
    async bulkGetDocuments({state,commit}, ids: string[] | string): Promise<any> {
        const promises: Array<any> = [];
        if(typeof ids === 'string') {
            ids = [ids];
        }

        ids.forEach((id: string) => {
            promises.push(axios.get(`${BACKEND_API_URL}/document/${id}`));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async getDocumentByOffset({state,commit}, payload: { offset: number, filtersQuery: string }): Promise<DocumentItem> {
        const queryString = state.documentsTable.query.getStringQuery(false);
        const response = await axios.get(`${BACKEND_API_URL}/document?offset=${payload.offset}&limit=1&${queryString}${payload.filtersQuery}`, { headers: { 'x-total-count': true} });

        return response.data.items[0];
    },
    async updateDocument({state,commit}, payload: any): Promise<any> {
        if(payload.bankAccount && ibanSupportedCountries.includes(payload.bankAccountCountry)) {
            const containsCode = (/^[A-Za-z]+$/).test(payload.bankAccount.substring(0, 1)) || (/^[A-Za-z]+$/).test(payload.bankAccount.substring(1, 2));
            payload.bankAccount = (!containsCode) && payload.bankAccountCountry ? `${payload.bankAccountCountry}${payload.bankAccount}` : payload.bankAccount;
        }

        const response = await axios.put(`${BACKEND_API_URL}/document/data/${payload.id}`, payload);

        return response.data;
    },
    async sendDocumentToApproval({state,commit,dispatch}, document: any): Promise<Array<any>> {
        if(document.bankAccount) {
            const containsCode = (/^[A-Za-z]+$/).test(document.bankAccount.substring(0, 1)) || (/^[A-Za-z]+$/).test(document.bankAccount.substring(1, 2));
            if(!containsCode && ibanSupportedCountries.includes(document.bankAccountCountry)) {
                await dispatch('updateDocument', document);
            }
        }

        const response = await axios.post(`${BACKEND_API_URL}/document/send-to-approval/${document.id}`);

        return response.data;
    },
    async bulkSendDocumentToApproval({state, commit}, ids: Array<any>): Promise<any> {
        const promises: Array<any> = [];
        if(typeof ids === 'string') {
            ids = [ids];
        }

        ids.forEach((id: string) => {
            promises.push(axios.post(`${BACKEND_API_URL}/document/send-to-approval/${id}`));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async uploadDocuments({state,commit}, payload: { files: any[], type: 'product-items' | 'vat-positions' }): Promise<any> {
        // eslint-disable-next-line prefer-const
        let requests: Array<Promise<any>> = [];
        payload.files.forEach((file: File) => {
            const formData = new FormData();
            formData.append('file', file);
            requests[file.name as any] = axios.post(`${BACKEND_API_URL}/document/upload/${payload.type}`, formData);
        });

        const results = await allProgress(requests, (p: any) => {
            commit('setDocumentsUploadProgress', p);
        });

        return results;
    },
    async getBankAccounts({state, commit}): Promise<void> {
        const { data } = await axios.get(`${BACKEND_API_URL}/crm/legal-entity/bank-accounts/accounting`);
        commit('setBankAccounts', data);
        return data;
    },
    async generatePackage({state,commit}, payload: { ids: Array<string>, bankAccount: string, bankAccountType: string, paymentDate: string, packageName: string, isSepa: boolean}): Promise<any> {
        const containsCode = (/^[A-Za-z]+$/).test(payload.bankAccount.substring(0, 1)) || (/^[A-Za-z]+$/).test(payload.bankAccount.substring(1, 2));

        payload.bankAccount = (!containsCode) ? `PL${payload.bankAccount.replace(/\s/g, "")}` : payload.bankAccount.replace(/\s/g, "");

        const { data } = await axios.patch(`${BACKEND_API_URL}/document/generate-payment-package`, {
            bankAccount: payload.bankAccount,
            bankAccountType: payload.bankAccountType,
            documentIds: payload.ids,
            paymentDate: payload.paymentDate,
            name: payload.packageName,
            isSepa: payload.isSepa,
        });

        if (data) {
            if (data?.filePaths?.length > 1) {
                const packageData = data?.filePaths?.map((path: string, index: number) => {
                    return { fileName: `${data?.name}-${index}.${data?.fileExtension || 'xml'}`, path }
                })
    
                Utils.downloadZippedFilesByUrls(packageData, payload.packageName)
            } else {
                const extension: string = data?.fileExtension ? data.fileExtension : 'xml'
                Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${data.filePaths[0]}`, `${data.name}.${extension}`);
            }
        }
    },
    async updatePaymentStatus({state,commit}, payload: { documentId: string, status: string }): Promise<any> {
        const response = await axios.patch(`${BACKEND_API_URL}/document/${payload.documentId}/payment-status/${payload.status ? payload.status : ''}`);

        return response.data;
    },
    async bulkUpdatePaymentStatuses({state,commit}, payload: { documentIds: Array<string>, status: string }): Promise<any> {
        const requests: Array<any> = [];

        payload.documentIds.forEach((id: string) => {
            requests.push(axios.patch(`${BACKEND_API_URL}/document/${id}/payment-status/${payload.status}`));
        });
        
        const response = await Promise.allSettled(requests);

        return response;
    },
    async deleteDocuments({state, commit}, ids: Array<any>): Promise<any> {
        const promises: Array<any> = [];
        if(typeof ids === 'string') {
            ids = [ids];
        }

        ids.forEach((id: string) => {
            promises.push(axios.delete(`${BACKEND_API_URL}/document/${id}`));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async setApproval({state,commit}, ids: Array<any>): Promise<any> {
        const promises: Array<any> = [];
        if(typeof ids === 'string') {
            ids = [ids];
        }

        ids.forEach((id: string) => {
            promises.push(axios.post(`${BACKEND_API_URL}/document/approve/${id}`));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async setReject({state,commit}, payload: { ids: Array<any>, note: string }): Promise<any> {
        const promises: Array<any> = [];
        if(typeof payload.ids === 'string') {
            payload.ids = [payload.ids];
        }

        payload.ids.forEach((id: string) => {
            promises.push(axios.post(`${BACKEND_API_URL}/document/reject/${id}`, {
                note: payload.note,
            }));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async bookDocument({state,commit}, documentId: string): Promise<any> {
        const { data } = await axios.post(`${BACKEND_API_URL}/document/book/${documentId}`);

        return data;
    },
    async bulkBookDocuments({state, commit}, ids: Array<any>): Promise<any> {
        const promises: Array<any> = [];
        if(typeof ids === 'string') {
            ids = [ids];
        }

        ids.forEach((id: string) => {
            promises.push(axios.post(`${BACKEND_API_URL}/document/book/${id}`));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async changeCashBankReportCreatedFlag({state,commit}, documentId: string) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/document/${documentId}/cash_bank_report_created/1`);

        return data;
    },
    async cancelDocument({state,commit}, documentId: string): Promise<any> {
        const { data } = await axios.post(`${BACKEND_API_URL}/document/cancel/${documentId}`);

        return data;
    },
    async synchronizeCounterparty({state,commit}, documentId: string): Promise<any> {
        const { data } = await axios.post(`${BACKEND_API_URL}/document/synchronize-counterparty/${documentId}`);

        return data;
    },
    async getDocumentAttachments({state, commit}, id: string): Promise<Array<any>> {
        const { data } = await axios.get(`${BACKEND_API_URL}/document/${id}/attachment`);

        return data;
    },
    async uploadAttachments({state,commit}, payload: { files: any, id: string }): Promise<any> {
        // eslint-disable-next-line prefer-const
        let requests: Array<Promise<any>> = [];

        payload.files.files.forEach((file: File) => {
            const formData = new FormData();
            formData.append('file', file);
            requests[file.name as any] = axios.post(`${BACKEND_API_URL}/document/${payload.id}/attachment`, formData);
        });

        const results = await allProgress(requests, (p: any) => {
            commit('setAttachmentsUploadProgress', p);
        });

        return results;
    },
    async deleteAttachment({state,commit}, payload: { documentId: string, attachmentId: string }): Promise<any> {
        const response = await axios.delete(`${BACKEND_API_URL}/document/${payload.documentId}/attachment/${payload.attachmentId}`);

        return response.data;
    },
    async getDictionaries({state, commit}): Promise<Array<any>> {
        if(state.dictionaries === null) {
            const response = await axios.get(`${BACKEND_API_URL}/document/dictionaries`);
            commit('setDictionaries', response.data);
            
            return response.data;
        }
    },
    async getCounterPartyNames({state}, value) {
        const response = await axios.get(`${BACKEND_API_URL}/document/counter-party-name?limit=100&filters[counterPartyName][25][value]=${value}&filters[counterPartyName][25][operator]=like`);

        return _.uniq(response.data.items);
    },
    
    async getDocumentNumbers({state}, value) {
        const response = await axios.get(`${BACKEND_API_URL}/document/document-number?limit=100&filters[documentNumber][5][value]=${value}&filters[documentNumber][5][operator]=like`);

        return _.uniq(response.data.items);
    },
    
    async getCounterParties({state}, search: string) {
        const response = await axios.get(`${BACKEND_API_URL}/document/counter-party/autocomplete/${search}`);

        return _.uniq(response.data.items);
    },

    async postCounterParty({state}, counterParty: CounterParty) {
        try {
            const response = await axios.post(`${BACKEND_API_URL}/document/counter-party`, counterParty);
    
            return response;
        } catch(err) {
            return;
        }
    },
    async changeDescription({state}, payload: { documentId: string, description: string | null }) {
        try {
            const response = await axios.patch(`${BACKEND_API_URL}/document/${payload.documentId}/description`, {
                description: payload.description
            });

            return response
        } catch(err) {
            return;
        }
    },
    async getDocumentRegisterMapper({state}) {
        try {
            const response = await axios.get(`${BACKEND_API_URL}/document-register-mapper`);

            return response;
        } catch(err) {
            return;
        }
    },
    async bulkUpdateAccountingDate({state,commit}, payload: { documentIds: Array<string>, accountingDate: string }): Promise<any> {
        const requests: Array<any> = [];

        payload.documentIds.forEach((id: string) => {
            requests.push(axios.patch(`${BACKEND_API_URL}/document/${id}/document-receipt-date`, {
                documentReceiptDate: payload.accountingDate,
            }));
        });
        
        const response = await Promise.allSettled(requests);

        return response;
    },
    async validateSendToApproval({state}, documentId: string) {
        const { data } = await axios.get(`${BACKEND_API_URL}/document/${documentId}/validate/send-to-approval`);

        return data;
    },
    async exportCsvByIds({state}, payload: { ids: string[] }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/document/export/csv`, payload);

        return data;
    },
    async exportCsvByIdsToJSON({state}, payload: { ids: string[] }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/document/export/by-ids`, payload);

        return data;
    },
    async exportCsvByFilters({state}, filtersQuery: string | null) {
        const queryString = filtersQuery ? `?${filtersQuery}` : '';
        const { data } = await axios.post(`${BACKEND_API_URL}/document/export-by-filters/csv${queryString}`);

        return data;
    },
    async getAccountingPeriods({state}) {
        const { data } = await axios.get(`${BACKEND_API_URL}/document/accounting-period`);

        return data;
    },
    async openAccountingPeriod({state}, payload: { year: any, month: number }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/document/accounting-period/${payload.year}/${payload.month}/open`)

        return data
    },
    async closeAccountingPeriod({state}, payload: { year: any, month: number }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/document/accounting-period/${payload.year}/${payload.month}/close`)

        return data
    },
    async requestGlChange({state}, payload: ReportMessageDto) {
        const { data } = await axios.post(`${BACKEND_API_URL}/document/report-mapping/${payload?.documentId}`, payload?.messageInfo)

        return data
    },
    async reportDocument({state}, payload: ReportMessageDto) {
        const { data } = await axios.post(`${BACKEND_API_URL}/document/report/${payload.documentId}`, payload.messageInfo)

        return data
    },
    async checkBankAccount({state}, payload: { nip: string, bankAccount: string }) {
        const bankAccount = payload?.bankAccount?.replace(/\D/g, '')
        const { data } = await axios.get(`https://wl-api.mf.gov.pl/api/check/nip/${payload.nip}/bank-account/${bankAccount}`)

        return data
    }
}

async function allProgress(proms: Array<any>, progress_cb: Function) {
    const results: Array<any> = [];
    let d = 0;
    progress_cb(0);
    Object.values(proms).forEach(p => {
        p.then(()=> {
            d++;
            progress_cb( (d * 100) / Object.keys(proms).length );
        });
    });
    let i = 0;
    await Promise.allSettled(Object.values(proms)).then((resp) => resp.forEach(result => {
        const key: any = Object.keys(proms)[i];
        results[key] = { status: result.status, data: result.status == 'rejected' ? result.reason : result.value };
        i++;
    }));

    return results;
}

export const accounting: Module<AccountingState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}