var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.fundKPIs ? _c('div', {
    staticClass: "fund-kpis"
  }, [_c('h2', {
    staticClass: "fund-kpis__headline"
  }, [_vm._v("Key KPI’s")]), _c('div', {
    staticClass: "fund-kpis__content"
  }, [_vm._l(_vm.fundKPIs, function (item, key) {
    return _c('sygni-card', {
      key: key,
      staticClass: "fund-kpis__card"
    }, [_c('p', {
      staticClass: "fund-kpis__title"
    }, [_vm._v(_vm._s(_vm.withLabels ? _vm.getName(item) : _vm.labels[key]))]), _c('p', {
      staticClass: "fund-kpis__value fund-kpis__value--currency"
    }, [_vm._v(" " + _vm._s(_vm.withLabels ? _vm.getValue(item.value) : _vm.getValue(item)) + " "), _c('span', [_vm._v(_vm._s(_vm.withLabels ? item.value.currency : _vm.getCurrency(item, key)))])])]);
  }), _vm._l(_vm.additionalKPIs, function (item, key) {
    var _item$value;

    return _c('sygni-card', {
      key: "additional-".concat(key),
      staticClass: "fund-kpis__card"
    }, [_c('p', {
      staticClass: "fund-kpis__title"
    }, [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.name) || ''))]), _c('p', {
      staticClass: "fund-kpis__value fund-kpis__value--currency"
    }, [_vm._v(" " + _vm._s(_vm.getValue(item === null || item === void 0 ? void 0 : item.value)) + " "), _c('span', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$value = item.value) === null || _item$value === void 0 ? void 0 : _item$value.currency))])])]);
  })], 2)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }