













import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop} from "vue-property-decorator";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import UserPresentation from "@/components/UserPresentation.vue";
// import {BACKEND_BASE_URL} from "@/shared/consts";
import {MTMember} from "@/modules/genprox/models/User";

@Component({
  components: {UserPresentation, SygniContainerTitle, SygniCard}
})
export default class ManagementTeamCard extends Vue {
  @Prop() team!: MTMember[];

  onNameClick(id: string): void {
    this.$router.push({ name: 'profile-dashboard-guest', params: { id: id } });
  }
}
