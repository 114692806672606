























import Vue from 'vue'
import Component, {mixins} from 'vue-class-component'
import {Emit, Prop} from "vue-property-decorator";
import DatePicker from 'vue2-datepicker';
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {SygniValidation} from "@/shared/mixins/ValidationMixin";
import Utils from '@/modules/shared/utils/utils';

@Component({
  components: {SygniInputError, DatePicker }
})
export default class SygniDatePicker extends mixins(Vue, SygniValidation) {
  @Prop({default: false}) plain: boolean;
  @Prop() label!: String;
  @Prop() value: String | null;
  @Prop({default: null}) inlineBlock: String | null;
  @Prop({default: 'YYYY-MM-DD'}) format!: String;
  @Prop() disabledDate: Function | null;
  @Prop({ default: 'YYYY-MM-DD' }) placeholder: string | null;
  @Prop({ default: null }) testId: string
  @Prop({ default: false }) useCustomError: boolean

  time: String = '';

  parseDate(string: any): string {
    if(!/^(\d){8}$/.test(string)) {
      return '';
    }
    
    const y = string.substr(0,4);
    const m = string.substr(4,2);
    const d = string.substr(6,2);

    return `${y}-${Utils.pad(m, 2)}-${Utils.pad(d, 2)}`;
  }

  changeDate(date: any) {
    if((!this.$attrs.type || this.$attrs.type == 'date') && !this.$attrs.range) {
      this.$emit('input', this.parseDate(date));
    }
  }

  @Emit('input')
  emitTime(value: string){
    this.$emit('input', value);
  }

  mounted() {
    this.time = this.value;
  }
}

export interface SygniDatePickerConfig {
}

