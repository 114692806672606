var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-input-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modelValue,
      expression: "modelValue"
    }],
    staticClass: "sygni-input-group__input",
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.modelValue
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.modelValue = $event.target.value;
      }, _vm.handleInput],
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.$emit('click');
      }
    }
  }), _c('sygni-rect-button', {
    class: ['sygni-input-group__btn', _vm.btnClasses],
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v(_vm._s(_vm.btnText))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }