var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "portfolio-modal"
  }, [_c('div', {
    staticClass: "portfolio-modal__bg",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "portfolio-modal__box"
  }, [_c('div', {
    class: ['portfolio-modal__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "portfolio-modal__actions"
  }, [_vm.showNavigation ? _c('button', {
    staticClass: "portfolio-modal__action portfolio-modal__action--arrow-left",
    on: {
      "click": _vm.prevFn
    }
  }, [_c('div', {
    staticClass: "portfolio-modal__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Prev"
    }
  })])]) : _vm._e(), _vm.showNavigation ? _c('button', {
    staticClass: "portfolio-modal__action portfolio-modal__action--arrow-right",
    on: {
      "click": _vm.nextFn
    }
  }, [_c('div', {
    staticClass: "portfolio-modal__action-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Next"
    }
  })])]) : _vm._e(), _c('button', {
    staticClass: "portfolio-modal__action portfolio-modal__action--close",
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "portfolio-modal__inner"
  }, [_c('div', {
    staticClass: "portfolio-modal__head"
  }, [_c('div', {
    staticClass: "portfolio-modal__container"
  }, [_c('div', {
    staticClass: "portfolio-modal__column"
  }, [_c('sygni-container-title', [_vm._v("Transaction data")]), _c('sygni-details', {
    attrs: {
      "items": _vm.details
    }
  })], 1), _c('div', {
    staticClass: "portfolio-modal__column"
  }, [_c('sygni-container-title', [_vm._v("Files")]), _c('div', {
    staticClass: "portfolio-modal__file-boxes"
  }, _vm._l(_vm.files, function (file, index) {
    return _c('sygni-file-box', {
      key: index,
      attrs: {
        "icon": "DOWNLOAD"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(file);
        }
      }
    }, [_vm._v(_vm._s(file.name))]);
  }), 1)], 1)])]), _c('div', {
    staticClass: "portfolio-modal__body"
  }, [_c('portfolio-transactions-table', {
    attrs: {
      "items": _vm.items
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }