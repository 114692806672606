
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL} from "@/shared/consts";
import {AIFMState} from "@/modules/genprox/modules/AIFM/store/types";
import {RootState} from "@/store/types";
import {CreateFundDTO} from "@/modules/genprox/modules/fund/store/types";

export const state: AIFMState = {
}

export const getters: GetterTree<AIFMState, RootState> = {

}

export const mutations: MutationTree<AIFMState> = {
}

export const actions: ActionTree<AIFMState, RootState> = {
    async createFundManager({commit}, payload: CreateFundDTO): Promise<string> {
        const { data: fundId } = await axios.post(`${BACKEND_API_URL}/crm/legal-entity/fund-manager`, payload);
        return fundId;
    }
}




export const AIFM: Module<AIFMState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

