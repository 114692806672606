var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "dashboard__content"
  }, [_c('div', {
    staticClass: "dashboard__left"
  }, [_c('fund-header', {
    attrs: {
      "filters": _vm.filters
    },
    on: {
      "setDataDashboard": _vm.setDataDashboard,
      "reloadDashboard": _vm.reloadDashboard
    }
  }), _vm.showFundKPIs ? _c('FundKPIs', {
    staticClass: "dashboard__kpis",
    attrs: {
      "fundKPIs": _vm.fundKPIs
    }
  }) : _vm._e(), _vm.showFundFinancials || _vm.showCumulatedDeals ? _c('div', {
    staticClass: "dashboard__data"
  }, [_vm.showFundFinancials ? _c('div', {
    staticClass: "dashboard__card dashboard__card--financials"
  }, [_c('FundFinancials', {
    attrs: {
      "fundFinancials": _vm.fundFinancials.data
    }
  })], 1) : _vm._e(), _vm.showCumulatedDeals ? _c('div', {
    staticClass: "dashboard__card dashboard__card--chart"
  }, [_c('FundChart', {
    attrs: {
      "data": _vm.cumulatedDeals,
      "label": "Cumulated deals",
      "title": "Cumulated deals"
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm.showTransactions ? _c('div', {
    staticClass: "dashboard__transactions"
  }, [_c('FundTransactions', {
    attrs: {
      "transactions": _vm.transactions
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "dashboard__right"
  }, [_c('sygni-container-title', [_vm._v(" Management Team")]), _c('management-team', {
    attrs: {
      "team": _vm.managementTeam
    }
  }), _c('sygni-container-title', [_vm._v(" Information")]), _c('fund-information-card', {
    attrs: {
      "legalEntity": _vm.fundManagerData
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }