var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-arrow-button",
    class: _vm.setClass
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }