var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: {
        interactive: false,
        delay: 0,
        animation: false,
        disabled: false
      },
      expression: "{ interactive: false, delay: 0, animation: false, disabled: false }",
      modifiers: {
        "hover": true
      }
    }],
    class: ['sygni-select', _vm.disabled ? 'input-disabled' : '', _vm.preserveSearch ? 'preserved' : '', _vm.placeholderAsValue ? 'placeholder-as-value' : '', _vm.showLegalEntityName ? 'multiline' : '', _vm.emptyClass, _vm.direction === 'top' ? 'top' : ''],
    attrs: {
      "id": _vm.testId,
      "title": _vm.nativeTooltip
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label) + " "), _vm._t("labelAddon")], 2), _c('multiselect', _vm._g(_vm._b({
    ref: "multiSelect",
    attrs: {
      "options": _vm.formattedOptions,
      "value": _vm.optionValue,
      "label": _vm.optionsLabel,
      "track-by": "label",
      "maxHeight": 205,
      "showLabels": false,
      "openDirection": _vm.direction,
      "preselectFirst": _vm.preselectFirst,
      "preserveSearch": _vm.preserveSearch,
      "clearOnSelect": _vm.clearOnSelect,
      "internalSearch": _vm.internalSearch,
      "optionsLimit": _vm.limit,
      "trackBy": _vm.trackBy
    },
    on: {
      "input": _vm.handleInput,
      "search-change": _vm.searchChangeAction,
      "blur": function blur($event) {
        return _vm.validation.$touch();
      }
    },
    scopedSlots: _vm._u([{
      key: "noResult",
      fn: function fn() {
        return [_vm._v(" No results ")];
      },
      proxy: true
    }, {
      key: "noOptions",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.emptyText) + " ")];
      },
      proxy: true
    }, _vm.addOptionBtn ? {
      key: "beforeList",
      fn: function fn() {
        return [_c('button', {
          staticClass: "sygni-select__add-option",
          on: {
            "click": _vm.addOption
          }
        }, [_vm._v(_vm._s(_vm.addOptionBtnText) + " +")])];
      },
      proxy: true
    } : null, {
      key: "option",
      fn: function fn(props) {
        var _props$option, _props$option2;

        return !_vm.showLegalEntityName && !(_vm.addOptionBtn || _vm.searchable) ? [_c('span', {
          class: [props !== null && props !== void 0 && (_props$option = props.option) !== null && _props$option !== void 0 && _props$option.disabled ? 'disabled' : '']
        }, [_vm._v(_vm._s((_props$option2 = props.option) === null || _props$option2 === void 0 ? void 0 : _props$option2.label))])] : undefined;
      }
    }], null, true)
  }, 'multiselect', Object.assign({}, _vm.removeKey(Object.assign({}, _vm.$props), 'value'), _vm.removeKey(Object.assign({}, _vm.$attrs), 'value')), false), _vm.removeKey(Object.assign({}, _vm.$listeners), 'input'))), _c('sygni-input-error', {
    attrs: {
      "validation": _vm.validation
    }
  }, [_vm._t("error")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }