import {RouteConfig} from "vue-router";
import {BACKEND_BASE_URL} from "@/shared/consts";
import store from "@/store/rootStore";
import investorDashboard from "@/modules/genprox/modules/investor/containers/investorDashboard.vue";

const portfolioModule = () => import('./containers/portfolioModule.vue');
const portfolioBlindModule = () => import('@/modules/genprox/modules/fund/modules/portfolio/containers/portfolioBlindModule.vue');

export const investorRouting: Array<RouteConfig>  = [
    {
        path: 'investor',
        name: 'investor-dashboard',
        component: investorDashboard,
    },
    {
        path: 'investor/dashboard',
        component: portfolioModule,
        beforeEnter: (to, from, next) => {
            window.location.href = `${BACKEND_BASE_URL}/investor`
        },
    },
    {
        path: 'investor/portfolio/by-fund',
        name: 'investor-portfolio-by-fund',
        component: portfolioModule,
        async beforeEnter(to, from, next) {
            await store.dispatch('genprox/getAccessModules');
            const accessModules = store.getters['genprox/accessModules'];
            if(accessModules.portfolio) {
                next();
            } else {
                next(`${to.path}/access-denied`);
            }
        },
    },
    {
        path: 'investor/portfolio/by-company',
        name: 'investor-portfolio-by-company',
        component: portfolioModule,
        async beforeEnter(to, from, next) {
            await store.dispatch('genprox/getAccessModules');
            const accessModules = store.getters['genprox/accessModules'];
            if(accessModules.portfolio) {
                next();
            } else {
                next(`${to.path}/access-denied`);
            }
        },
    },
    {
        path: 'investor/portfolio/by-company/access-denied',
        name: 'investor-portfolio-by-company-access-denied',
        component: portfolioBlindModule
    },
    {
        path: 'investor/portfolio/by-fund/access-denied',
        name: 'investor-portfolio-by-company-access-denied',
        component: portfolioBlindModule
    },
];
