var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-date-picker', _vm.plain ? 'plain' : '', _vm.inlineBlock ? 'inline' : ''],
    attrs: {
      "id": _vm.testId
    }
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('date-picker', _vm._g(_vm._b({
    staticClass: "date-picker",
    attrs: {
      "value": _vm.value,
      "format": _vm.format,
      "disabled-date": _vm.disabledDate,
      "valueType": "format",
      "placeholder": _vm.placeholder
    },
    on: {
      "input": _vm.emitTime,
      "blur": function blur($event) {
        var _vm$validation;

        (_vm$validation = _vm.validation) === null || _vm$validation === void 0 ? void 0 : _vm$validation.$touch();
      },
      "input-error": _vm.changeDate
    }
  }, 'date-picker', Object.assign({}, _vm.$attrs, _vm.$props), false), _vm.$listeners)), _c('sygni-input-error', {
    attrs: {
      "validation": _vm.validation,
      "useCustomError": _vm.useCustomError
    }
  }, [_vm._t("error")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }