import {RouteConfig} from "vue-router";
import store from "@/store/rootStore";

const advisorsModule = () => import('./containers/advisorsModule.vue');
const advisorFormModule = () => import('./containers/advisorFormModule.vue');

export const advisorsRouting: Array<RouteConfig>  = [
    {
        path: 'advisors',
        name: 'advisors',
        component: advisorsModule,
        children: [],
        async beforeEnter(to, from, next) {
            await store.dispatch('genprox/getActiveUserData');
            const context = store.getters['genprox/activeUserData'];
            
            if(context?.role?.marketplace === 'admin' || context?.role?.marketplace === 'accountant') {
                try {
                    await store.dispatch('advisors/getAdvisorForm');
                    next();
                } catch(e) {
                    if(from.path.includes('company')) {
                      next('/company/fundraising/advisor-form-settings');
                    } else {
                      next('/fund/fundraising/advisor-form-settings');
                    }
                }
            } else {
                next({ name: 'investors' });
            }
        },
    },
    {
        path: 'fundraising/advisor-form-settings',
        name: 'advisorFormSettings',
        component: advisorFormModule,
        async beforeEnter(to, from, next) {
            await store.dispatch('advisors/getAdvisorFormParams');
            next();
        }
    },
];
