import {RouteConfig} from "vue-router";
import {BACKEND_BASE_URL} from "@/shared/consts";

const dashboardModule = () => import('./containers/dashboardModule.vue');

export const dashboardRouting: Array<RouteConfig>  = [
    {
        path: '',  /* /fund */
        // beforeEnter: (to, from, next) => {
        //     window.location.href = `${BACKEND_BASE_URL}/fund`
        // },
        name: 'dashboard',
        component: dashboardModule,
        children: [],
        redirect: {
            name: 'fund-reg-dashboard'
        }
    },
];
