






























































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Statues} from "@/shared/interfaces/Statues";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniInnerTable from "@/components/table/SygniInnerTable.vue";
import { Product} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';

@Component({
  components: {SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, SygniContainerTitle }
})
export default class PortfolioTransactionsTable extends SygniInnerTable<Product> {

  innerTableFields = [
    { key: 'transactionPartyName', sortable: true, class: 'company text-left', label: 'Transaction party'},
    { key: 'transactionType', sortable: true, label: 'Transaction' },
    { key: 'instrumentType', sortable: true, label: 'Instrument Type' },
    { key: 'instrumentClass', sortable: true, label: 'Instrument class' },
    { key: 'seriesOfSharesName', sortable: true, label: 'Series of shares' },
    { key: 'instrumentCount', sortable: true, class: 'text-right', label: 'Instrument count' },
    { key: 'counterFrom', sortable: true, class: 'text-right', label: 'Counter from' },
    { key: 'counterTo', sortable: true, class: 'text-right', label: 'Counter to' },
    { key: 'nominalValue', sortable: true, class: 'text-right', label: 'Nominal value' },
    { key: 'contributionType', sortable: true, label: 'Contribution Type' },
    { key: 'contribution', sortable: true, class: 'text-right', label: 'Contribution' },
  ]

  statusClass(status: Statues) {
    switch(status) {
      case(Statues.active): {
        return 'primary';
      }
      case(Statues.invited): {
        return 'success';
      }
      case(Statues.awaiting): {
        return 'success';
      }
      case(Statues.pending): {
        return 'black';
      }
      case(Statues.rejected): {
        return 'danger';
      }
      case(Statues.declined): {
        return 'disabled';
      }
    }
    return '';
  }

  statusText(status: Statues): string {
    switch(status) {
      case(Statues.active): {
        return 'Active';
      }
      case(Statues.invited): {
        return 'Invited';
      }
      case(Statues.awaiting): {
        return 'Awaiting';
      }
      case(Statues.pending): {
        return 'Pending';
      }
      case(Statues.rejected): {
        return 'Rejected';
      }
      case(Statues.declined): {
        return 'Declined';
      }
    }
    return '';
  }

  getTooltipMessage(message: string) {
    return message?.length <= 35 ? '' : message
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted() {
    // this.onMounted();
  }
}

