

import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";
import { TransactionsState } from "./types";
import { _axios as axios } from "@/plugins/axios";
import { BACKEND_API_URL } from "@/shared/consts";

export const state: TransactionsState = {
    transactionsTableIsBusy: false,
    transactionsTable: {
        items: [],
        totalCount: 0,
        perPage: 10,
        query: new TableQuery(10),
    },
}

export const getters: GetterTree<TransactionsState, RootState> = {
    getTransactionsTableItems(state) {
        return JSON.parse(JSON.stringify(state.transactionsTable.items));
    },
    getTransactionsTableQuery(state) {
        return JSON.parse(JSON.stringify(state.transactionsTable.query));
    },
    getTransactionsTableFiltersQuery(state) {
        return JSON.parse(JSON.stringify(state.transactionsTable.query.filters));
    },
    getTransactionsTablePerPage(state) {
        return JSON.parse(JSON.stringify(state.transactionsTable.perPage));
    },
}

export const mutations: MutationTree<TransactionsState> = {
    setTransactionsTableItems(state, payload: {items: Array<any>, totalCount: number}): void {
        state.transactionsTable = { ...state.transactionsTable, items: payload.items, totalCount: payload.totalCount};
    },
    setTransactionsTableQuery(state, payload: TableQuery): void {
        state.transactionsTable.query = payload;
    },
    setTransactionsTableBusy(state,payload: boolean): void {
        state.transactionsTableIsBusy = payload;
    },
    setTransactionsTablePerPage(state, payload: number): void {
        state.transactionsTable.perPage = payload;
    },
    setTransactionsTableFiltersQuery(state, payload: string) {
        state.transactionsTable.query.filters = payload;
    },
    setTransactionsTableSortingQuery(state, payload: Sorting): void {
        state.transactionsTable.query.sorting = payload;
        state.transactionsTable.query.offset = 0;
    },
}

export const actions: ActionTree<TransactionsState, RootState> = {
    async getTransactions({state,commit}, payload: { filtersQuery?: string, viewedContext?: string }): Promise<Array<any>> {
        const queryString = state.transactionsTable.query.getStringQuery();
        payload.filtersQuery = payload.filtersQuery ? payload.filtersQuery : '';

        const headers: any = { 'x-total-count': true }

        if (payload?.viewedContext) {
            headers['Viewed-Context'] = payload?.viewedContext
        }

        const response = await axios.get(`${BACKEND_API_URL}/transaction${queryString}${payload.filtersQuery}`, { headers });

        const data: any = { items: response.data.results, totalCount: response.data.totalCount }

        commit('setTransactionsTableItems', {
            items: data.items,
            totalCount: data.totalCount,
        })

        return response.data;
    },
    async getTransactionById({state,commit}, payload: { id: string, viewedContext?: string }): Promise<Array<any>> {
        const headers: any = {}

        if (payload?.viewedContext) {
            headers['Viewed-Context'] = payload?.viewedContext
        }

        const response = await axios.get(`${BACKEND_API_URL}/transaction/${payload.id}`, { headers });

        return response.data;
    },
}

export const transactions: Module<TransactionsState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
