var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fund-financials"
  }, [_vm._m(0), _c('sygni-card', {
    staticClass: "fund-financials__content"
  }, _vm._l(_vm.fundFinancials, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "fund-financials__col"
    }, _vm._l(item, function (el, index) {
      return _c('div', {
        key: index,
        staticClass: "fund-financials__item",
        class: {
          'fund-financials__item--total': el.level === 0
        }
      }, [_c('p', {
        staticClass: "fund-financials__label"
      }, [_vm._v(_vm._s(el.name))]), _c('p', {
        staticClass: "fund-financials__value"
      }, [_vm._v(" " + _vm._s(_vm.setCurrency(el)) + " "), _c('span', [_vm._v(_vm._s(el.value != null ? el.value.currency : ''))])])]);
    }), 0);
  }), 0)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fund-financials__header"
  }, [_c('p', {
    staticClass: "fund-financials__title"
  }, [_vm._v("Key Financials")])]);
}]

export { render, staticRenderFns }