import Vue from 'vue'
import VueRouter, {NavigationGuardNext, Route} from 'vue-router'
import { genproxRouting } from "@/modules/genprox/genprox.routing";
import {authRouting} from "@/modules/auth/auth-routing";
import store from "@/store/rootStore";
import axios from 'axios';
import {Address} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import {BACKEND_API_URL} from "@/shared/consts";
import vuexStore from "@/store/rootStore";
import {InvestmentClientRegisterAuth} from "@/modules/auth/store/types";
import rootStore from '@/store/rootStore';

Vue.use(VueRouter)
const routes = [
    ...genproxRouting,
    ...authRouting,
    { path: '*', redirect: '/'},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
    const activeUserData = rootStore.getters['genprox/activeUserData'];
    const twoFactorRequired = rootStore.getters['auth/requireTwoFactor'];

    if(twoFactorRequired && to.name !== 'profile-edit-security') {
        if(activeUserData && !activeUserData?.user?.twoFactorAuthEnabled) {
            next({ name: 'profile-edit-security' });
        }
    }

    if (await registerFromMail(to, from, next)){
        return;
    }
    if (to.query?.error === '401') {
        store.commit('auth/removeApiKey');
        next({path: 'auth/login'});
    } else {
        next();
    }
});

router.afterEach((to: Route, from: Route) => {
    setTimeout(() => {
        router?.app?.$el?.querySelector('.app-content')?.classList?.remove('blocked');
    }, 1200)
})

const registerFromMail = async (to: Route, from: Route, next: NavigationGuardNext): Promise<boolean> => {
    let registrationToken: string = localStorage.getItem('registrationToken');
    if(to.query.registrationToken) {
        registrationToken = to.query.registrationToken as string;
        localStorage.setItem('registrationToken', registrationToken);
    } else {
        localStorage.removeItem('registrationToken');
    }
    if(!to.path.includes('/welcome/') || !registrationToken) return false;

    const myAxios = axios.create();
    let response;
    if(to.params.id){
        localStorage.setItem('registerProductId', to.params.id);
    }
    try {
        await myAxios.get<Address & {name: string}>(
            `${BACKEND_API_URL}/marketplace/investor/product/${to.params.id}`);
        return false;
    } catch (error) {
        response = (error as any).response;
    }
    if (to.query.error === '401'){
        let data: InvestmentClientRegisterAuth;
        try {
            data = (await vuexStore.dispatch('auth/getUserByToken', registrationToken)).data;
        } catch (e){
            next({name: 'login'});
            return true;
        }

        if(!data.userExists){
            localStorage.removeItem('registrationToken');
            next({name: 'register', params: {userData: (data as any), registrationToken: registrationToken}});
            return true;
        } else {
            next({name: 'login'});
            return true;
        }
    }
    if (response.status === 200){
        to.params.productData = response.data as any;
    } else if(response.status === 401){
        store.commit('auth/removeApiKey');
    } else if (response.status === 403) {
        router.push({name: 'dashboard'});
    }
    return true;
}


export default router
