var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "team"
  }, _vm._l(_vm.team, function (member) {
    return _c('sygni-card', {
      key: member.name,
      staticClass: "team-member"
    }, [_c('user-presentation', {
      staticClass: "team-member__presentation",
      attrs: {
        "image": member.logo,
        "name": member.name,
        "description": member.position,
        "onNameClick": function onNameClick() {
          return _vm.onNameClick(member.naturalPersonId);
        }
      }
    }), _c('div', {
      staticClass: "team-member__data"
    }, [_c('a', {
      staticClass: "data-row data-row--clickable",
      attrs: {
        "href": "mailto:".concat(member.email)
      }
    }, [_vm._v(_vm._s(member.email) + " ")]), _c('div', {
      staticClass: "data-row"
    }, [_vm._v(_vm._s(member.phone) + " ")])])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }