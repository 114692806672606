"use strict";

import Vue from 'vue';
import axios, {AxiosStatic} from "axios";
import router from "@/router";
import {REDIRECTED_PATH} from "@/modules/genprox/genprox.routing";
import rootStore from '@/store/rootStore';
import { BACKEND_BASE_URL, BACKEND_API_URL } from '@/shared/consts';


// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

const config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

export const _axios = axios.create(config);

_axios.interceptors.request.use(
    async function (config) {
        // check if endpoint is restricted
        if(config.url.includes(BACKEND_API_URL)) {
            if(!localStorage.getItem('apiKey')) {
                router.push({ name: 'login' });
                return null;
            }
        } else {
            if(!config.url.includes(BACKEND_API_URL)) {
                config.headers.common['authorization'] = undefined;
                delete config.headers.common['authorization'];
            }
        }

        const jwtTokenData = rootStore.getters['auth/jwtTokenData'];
        
        const date = new Date(0);
        date.setUTCSeconds(jwtTokenData?.exp - (60 * 3));
        
        // check if token expires in less than 5 minutes
        if(new Date() >= date) {
            const refreshToken = rootStore.getters['auth/refreshToken'];

            const refreshTokenData = rootStore.getters['auth/refreshTokenData'];
            const refreshTokenDate = new Date(0);
            refreshTokenDate.setUTCSeconds(refreshTokenData?.exp);
            
            if(refreshToken && new Date() < refreshTokenDate) {
                if(!localStorage.getItem('interceptor')) {
                    localStorage.setItem('interceptor', '1');
                    const response = await _axios.post(`${BACKEND_BASE_URL}/auth/refresh-token`, {
                        refreshToken: refreshToken
                    });

                    config.headers.authorization = `Bearer ${response.data.accessToken}`;
    
                    if(response?.data?.accessToken) {
                        rootStore.commit('auth/setApiKey', response.data.accessToken);
                    }
    
                    if(response?.data?.refreshToken) {
                        rootStore.commit('auth/setRefreshToken', response.data.refreshToken);
                    }
                }
            } else {
                rootStore.commit('auth/removeApiKey');
                router.push({ name: 'login' });
            }
        } else {
            localStorage.removeItem('interceptor');
        }
        
        return config;
    },
    function (error: any) {
        return Promise.reject(error);
    },
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        if (error.response?.status === 401) {
            if(!localStorage.getItem(REDIRECTED_PATH)) {
                localStorage.setItem(REDIRECTED_PATH, window.location.pathname);
            }

            try {
                const refreshToken = rootStore.getters['auth/refreshToken'];
                const refreshTokenData = rootStore.getters['auth/refreshTokenData'];
                const refreshTokenDate = new Date(0);
                refreshTokenDate.setUTCSeconds(refreshTokenData?.exp);
                
                if(refreshToken && new Date() < refreshTokenDate) {
                    localStorage.setItem('interceptor', '1');
                    const response = await _axios.post(`${BACKEND_BASE_URL}/auth/refresh-token`, {
                        refreshToken: refreshToken
                    });

                    if(response?.data?.accessToken) {
                        rootStore.commit('auth/setApiKey', response.data.accessToken);
                    }

                    if(response?.data?.refreshToken) {
                        rootStore.commit('auth/setRefreshToken', response.data.refreshToken);
                    }
                } else {
                    rootStore.commit('auth/removeApiKey');
                    router.push({ name: 'login' });
                }
            } catch(err) {
                rootStore.commit('auth/removeApiKey');
                router.push({ name: 'login' });
            }
        } else if (error.response?.status === 302) {
            rootStore.commit('auth/setRequireTwoFactor', true);
            router.push({name: 'profile-edit-security'});
        }
        return Promise.reject(error);
    }
);

declare global {
    interface Window {
        axios: any;
        store: any;
    }
}

if(localStorage.getItem('apiKey')) {
    _axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('apiKey');
}

window.axios = _axios;

// @ts-ignore
Plugin.install = function (Vue: any) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.prototype.$axios = _axios;
declare module 'vue/types/vue' {
    interface Vue {
        $axios: AxiosStatic;
    }
}

// @ts-ignore
Vue.use(Plugin)

export default Plugin;
