var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-square-button",
    on: {
      "click": _vm.click
    }
  }, [_c('svg', {
    attrs: {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64"
    }
  }, [_c('g', {
    attrs: {
      "id": "background"
    }
  }, [_c('rect', {
    staticClass: "cls-4",
    attrs: {
      "width": "64",
      "height": "64",
      "rx": "6"
    }
  }), _c('rect', {
    staticClass: "cls-5",
    attrs: {
      "x": ".5",
      "y": ".5",
      "width": "63",
      "height": "63",
      "rx": "5.5"
    }
  })]), _c('g', {
    attrs: {
      "transform": "translate(18.753 19.055)"
    }
  }, [_c('g', {
    attrs: {
      "id": "icon-1",
      "transform": "translate(3.247 4.945)"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "10",
      "cy": "10",
      "r": "10"
    }
  }), _c('circle', {
    attrs: {
      "cx": "10",
      "cy": "10",
      "r": "8.5"
    }
  })]), _c('g', {
    attrs: {
      "id": "icon-2",
      "transform": "translate(12.247 .945)"
    }
  }, [_c('rect', {
    staticClass: "cls-4",
    attrs: {
      "width": "3",
      "height": "12",
      "rx": "1.5"
    }
  }), _c('rect', {
    staticClass: "cls-5",
    attrs: {
      "x": "-1.5",
      "y": "-1.5",
      "width": "6",
      "height": "15",
      "rx": "3"
    }
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }