

























import Vue from "vue";
import Component from "vue-class-component";
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import {Prop} from "vue-property-decorator";
import {SelectOption} from "@/store/types";
import {REDIRECTED_PATH} from "@/modules/genprox/genprox.routing";

@Component({
  components: {SygniCard, SygniSelect, SygniRadio, SygniDatePicker},
})

export default class FundHeader extends Vue {
  @Prop() filters: any;
  @Prop({default: false}) selectAvailable: boolean;
  @Prop({default: true}) showSelect: boolean;

  get companyOptions(): Array<SelectOption<string>> {
    const companies = this.$store.getters['auth/getInvestedCompanies'];

    if (!companies) return [];

    return companies.map((el: any) => {
      return {
        label: el.name,
        value: el.id
      }
    })
  }

  get fundOptions(): Array<SelectOption<string>> {
    const funds = this.$store.getters['auth/getInvestedFunds'];

    if (!funds) return [];

    return funds.map((el: any) => {
      return {
        label: el.name,
        value: el.id
      }
    })
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('/company') ? true : false;
  }

  get selectOptions() {
    return this.isPortfolioCompany ? this.companyOptions : this.fundOptions;
  }

  get viewedId() {
    return this.isPortfolioCompany ? this.$store.getters['auth/getViewedCompanyId'] : this.$store.getters['auth/getViewedFundId'];
  }

  setViewedId(id: string): void {
    if (id) {
      this.checkCapitalAvailability(id);
    }
  }

  async checkCapitalAvailability(id: string) {
    localStorage.setItem(REDIRECTED_PATH, window.location.pathname)
    if(this.isPortfolioCompany) {
      await this.$store.dispatch('auth/changeViewedCompany', id);
      this.$emit('reloadDashboard');
    } else {
      await this.$store.dispatch('auth/changeViewedFund', id);
      this.$emit('reloadDashboard');
    }
  }

  setDataDashboard(data: string) {
    this.$emit('setDataDashboard', data);
  }
}
