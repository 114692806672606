var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gn-menu"
  }, _vm._l(_vm.formattedMenus, function (menu) {
    var _menu$children2;

    return _c('div', {
      key: menu.label,
      staticClass: "gn-menu__item"
    }, [_c('a', {
      class: ['gn-menu-btn', menu !== null && menu !== void 0 && menu.isActive ? 'gn-menu-btn--active' : '', _vm.openedMenu == (menu === null || menu === void 0 ? void 0 : menu.label) ? 'gn-menu-btn--opened' : ''],
      attrs: {
        "href": menu === null || menu === void 0 ? void 0 : menu.route
      },
      on: {
        "click": function click($event) {
          var _menu$children;

          $event.preventDefault();
          (menu === null || menu === void 0 ? void 0 : (_menu$children = menu.children) === null || _menu$children === void 0 ? void 0 : _menu$children.length) === 0 ? _vm.$router.push(menu === null || menu === void 0 ? void 0 : menu.route) : _vm.toggleActiveMenu(menu === null || menu === void 0 ? void 0 : menu.label);
        }
      }
    }, [_c('span', {
      staticClass: "gn-menu-btn__icon"
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")(menu === null || menu === void 0 ? void 0 : menu.label),
        "alt": menu === null || menu === void 0 ? void 0 : menu.label
      }
    })]), _c('span', {
      staticClass: "gn-menu-btn__label"
    }, [_vm._v(_vm._s(menu === null || menu === void 0 ? void 0 : menu.label))]), (menu === null || menu === void 0 ? void 0 : (_menu$children2 = menu.children) === null || _menu$children2 === void 0 ? void 0 : _menu$children2.length) > 0 ? _c('span', {
      staticClass: "gn-menu-btn__arrow"
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")('CARET DOWN'),
        "alt": "Toggle items"
      }
    })]) : _vm._e()]), _c('div', {
      staticClass: "gn-menu__dropdown",
      style: "max-height: ".concat(_vm.getMenuHeight(menu))
    }, [_c('div', {
      staticClass: "gn-menu__dropdown-inner"
    }, _vm._l((menu === null || menu === void 0 ? void 0 : menu.children) || [], function (item) {
      return _c('a', {
        key: "".concat(menu === null || menu === void 0 ? void 0 : menu.label, "-").concat(item === null || item === void 0 ? void 0 : item.label),
        class: ['gn-menu-btn gn-menu-btn--child', item !== null && item !== void 0 && item.isActive ? 'gn-menu-btn--active' : ''],
        attrs: {
          "href": item === null || item === void 0 ? void 0 : item.route
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.$router.push(item === null || item === void 0 ? void 0 : item.route);
          }
        }
      }, [_c('span', {
        staticClass: "gn-menu-btn__label"
      }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.label))])]);
    }), 0)])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }