















import Vue from "vue";
import Component from "vue-class-component";
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop} from "vue-property-decorator";
import moment from 'moment';

// echart imports
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { LineChart } from 'echarts/charts'
import {
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent
} from 'echarts/components'
import { SVGRenderer } from 'echarts/renderers'

use([
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  LineChart,
  SVGRenderer
])

// @ts-ignore
@Component({
  components: { SygniCard, VChart },
})
export default class FundChart extends Vue {
  @Prop() title: string;
  @Prop() data: any;
  @Prop() label: string;

  displayData: [] = [];
  chartData: any = {
    datasets: [{
      fill: 'start',
      label: false,
      borderColor: '#b0b0b0',
      backgroundColor: '#ebebeb',
      data: [],
    }],
  }
  chartOptions = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        },
        beginAtZero: true,
      }
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      }
    }
  }
  chartStyles = {
    height: '100%',
    width: '100%'
  }

  optionsData: any = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      top: '0%'
    },
    grid: {
      left: '8%',
      right: '8%',
      bottom: '0%',
      top: '5%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: []
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: '',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: []
      },
    ]
  }

  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  get options() {
    const optionsData = this.optionsData

    if (optionsData?.series[0]) {
      if (this?.data?.length) {
        optionsData.series[0].data = this.data?.map((el: { x: string, y: number }) => {
          const year: string = moment(el.x).format('YYYY-M-DD').split('-')[0];
          const monthNumber: any = moment(el.x).format('YYYY-M-DD').split('-')[1];
  
  
          return { value: el?.y || 0, name: `${this.monthNames[monthNumber - 1]}, ${year}` }
        })
      } else {
        optionsData.series[0].data = [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0]?.map((month: number) => {
          const year: string = moment().add(month, 'months').format('YYYY-M-DD')?.split('-')[0]
          const monthNumber: any = moment().add(month, 'months').format('YYYY-M-DD')?.split('-')[1]

          return { value: 0, name: `${this.monthNames[monthNumber - 1]}, ${year}` }
        })
      }
    }

    if (optionsData?.xAxis[0]) {
      if (this?.data?.length) {
        optionsData.xAxis[0].data = this.data?.map((el: { x: string, y: number}) => {
          const year: string = moment(el.x).format('YYYY-M-DD').split('-')[0];
          const monthNumber: any = moment(el.x).format('YYYY-M-DD').split('-')[1];
  
          return `${this.monthNames[monthNumber - 1]}, ${year}`
        })
      } else {
        optionsData.xAxis[0].data = [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0]?.map((month: number) => {
          const year: string = moment().add(month, 'months').format('YYYY-M-DD')?.split('-')[0]
          const monthNumber: any = moment().add(month, 'months').format('YYYY-M-DD')?.split('-')[1]

          return `${this.monthNames[monthNumber - 1]}, ${year}`
        })
      }
    }

    return optionsData
  }
}
