import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop, Watch} from "vue-property-decorator";

@Component
export class SygniValidation extends Vue {
  @Prop() validation: any;

  get errors(){
    return this.validation?.$error;
  }

  @Watch('errors') onError(){
    if(this.validation?.$error){
      this.$el.classList.add('error');
    } else {
      this.$el.classList.remove('error');
    }
  }

  mounted() {
    if(this.validation?.$error){
      this.$el.classList.add('error');
    } else {
      this.$el.classList.remove('error');
    }
  }
}
