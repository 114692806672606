export default {
  "NATURAL_PERSON": "Natural person",
  "FUNDEQUATE": "Fundequate",
  "MARKETPLACE": "Marketplace",
  "_30_360": "30/360",
  "ACTUAL_365": "ACTUAL/365",
  "ACTUAL_360": "ACTUAL/360",
  "SEMI_ANNUALLY":"Semi-annually",
  "AT_MATURITY": "At Maturity",
  "GO_TO_FUND": "Go to fund",

  //dashboard:
  "taxNumber": "TAX NUMBER",
  "registerNumber": "REGISTER NUMBER",
  "statisticalNumber": "STATISTICAL NUMBER",
  "establishmentDate": "ESTABLISHMENT DATE",

  //general informations
  "email": "EMAIL",
  "city": "CITY",
  "country": "COUNTRY",
  "flatNumber": "FLAT NUMBER",
  "houseNumber": "HOUSE NUMBER",
  "street": "STREET",
  "zipCode": "ZIP CODE",

  "label.domain_exception": "An error occurred",

  "AWAITING_FOR_LEGAL_ENTITY": "Pending",
  'ACTIVE': 'Active',
  'LEAD': 'Lead',
  'REJECTED': 'Rejected',
  'INVITED': 'Invited',
  'DECLINED': 'Declined',
  'AWAITING': 'Awaiting',
  'PENDING': 'Pending',
  'LOAN': 'Loan',

  'marketplace.debtor_account_name': 'NAME',
  'marketplace.debtor_account_currency': 'CURRENCY'
}
