import store from '../../store/rootStore';
import {NavigationGuardNext, Route} from 'vue-router';

const welcomeComponent = () => import('./components/Welcome.vue');
const testGraphs = () => import('./components/TestGraphs.vue');
const pivotTable = () => import('./components/pivot-table/PivotTable.vue');
const pivotTable1 = () => import('./components/PivotTable1.vue');
const pivotTable2 = () => import('./components/PivotTable2.vue');

import { fundRouting } from "@/modules/genprox/modules/fund/fund.routing";
import { walletRouting } from "@/modules/genprox/modules/wallet/wallet.routing";
import { profileRouting } from "@/modules/genprox/modules/profile/profile.routing";
import { insightsRouting } from "@/modules/genprox/modules/insights/insights.routing";
import { companyRouting } from "@/modules/genprox/modules/company/company.routing";
import {investorRouting} from "@/modules/genprox/modules/investor/investor.routing";
import {AIFMRouting} from "@/modules/genprox/modules/AIFM/AIFM.routing";
import { whistleblower } from './modules/whistleblower/store';

export const REDIRECTED_PATH = 'redirected_path';

const authGuard = async (to: any, from: any, next: any) => {
    const authToken: string = to.query?.token;
    const apiKey: string = store.getters['auth/apiKey'];
    if (to.query?.token && !apiKey) { // use authToken after redirect
        store.commit('auth/setAuthToken', authToken);
        await store.dispatch('auth/getApiKey');
    } else if (!apiKey) {
        if(!localStorage.getItem(REDIRECTED_PATH)) {
            localStorage.setItem(REDIRECTED_PATH, to.path);
        }
    }
    if(to.query?.token){
        next({path: to.path}); //clear token
    } else {
        next();
    }
}

const genproxModule = () => import(/* webpackChunkName: "GenproxModule" */ "./containers/GenproxModule.vue")

export const genproxRouting = [
    {
        path: '/',
        component: genproxModule,
        beforeEnter: authGuard,
        children: [
            {path: '/', redirect: 'welcome'},
            {
                path: '/test-table',
                name: 'test-table',
                component: pivotTable,
            },
            {
                path: '/test-table-1',
                name: 'test-table-1',
                component: pivotTable1,
            },
            {
                path: '/test-table-2',
                name: 'test-table-2',
                component: pivotTable2,
            },
            {
                path: '/welcome',
                name: 'welcome',
                component: welcomeComponent,
            },
            {
                path: '/welcome/:id',
                component: welcomeComponent,
            },
            {
                path: '/test-graphs',
                component: testGraphs,
            },
            {
                path: '/redirect',
                name: 'redirect',
                beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
                    if(to.params.route !== undefined) {
                        window.location.href = to.params.route;
                    } else {
                        next('/welcome');
                    }
                }
            },
            ...fundRouting,
            ...walletRouting,
            ...profileRouting,
            ...insightsRouting,
            ...companyRouting,
            ...investorRouting,
            ...AIFMRouting,
        ]
    },
]
