import {RouteConfig} from "vue-router";
import {AddRegRepType} from "@/modules/genprox/modules/fund/modules/reg-reporting/store/types";
import {accountingRouting} from "@/modules/accounting/accounting.routing";
import {reportingRouting} from "@/modules/genprox/modules/reporting/reporting.routing";
import store from "@/store/rootStore";
import { statementsRouting } from "@/modules/statements/statements.routing";

const AIFMModule = () => import('./containers/AIFMModule.vue');
const dashboard = () => import('./modules/dashboard/containers/Dashboard.vue');
const regReportingModule = () => import('../fund/modules/reg-reporting/containers/RegReportingModule.vue');
const regReportingBlindModule = () => import('../fund/modules/reg-reporting/containers/RegReportingBlindModule.vue');
const addReport = () => import('./modules/reg-reporting/containers/AddNewReport.vue');
const pivotContainer = () => import('@/modules/genprox/containers/PivotContainer.vue');

export const AIFMRouting: Array<RouteConfig>  = [
    {
        path: 'fund-manager',
        name: 'AIFM',
        component: AIFMModule,
        children: [
            ...accountingRouting,
            ...statementsRouting,
            ...reportingRouting,
            {
                path: 'dashboard',
                name: 'AIFM-dashboard',
                component: dashboard,
            },
            {
                path: 'regulatory-reporting',
                name: 'AIFM-regulatory-reporting',
                props: true,
                component: regReportingModule,
                async beforeEnter(to, from, next) {
                    await store.dispatch('genprox/getAccessModules');
                    const accessModules = store.getters['genprox/accessModules'];
                    if(accessModules.regulatoryReporting) {
                        next();
                    } else {
                        next(`/fund-manager/access-denied`);
                    }
                },
            },
            {
                path: 'reg-reporting/add-report',
                component: addReport,
                props: ({ pageType: AddRegRepType.AIFM}),
                name: 'AIFM-add-reg-reporting',
                async beforeEnter(to, from, next) {
                    await store.dispatch('genprox/getAccessModules');
                    const accessModules = store.getters['genprox/accessModules'];
                    if(accessModules.regulatoryReporting) {
                        next();
                    } else {
                        next(`/fund-manager/access-denied`);
                    }
                },
            },
            {
                path: 'analytics',
                name: 'AIFM-analytics',
                component: pivotContainer,
            }
        ],
    },
    {
        path: 'fund-manager/access-denied',
        name: 'access-denied',
        component: regReportingBlindModule,
    }
];
