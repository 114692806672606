import {NavigationGuardNext, Route} from "vue-router";
import Vue from "vue";
import store from "@/store/rootStore";
import {MenuItem} from "@/modules/genprox/store/types";


export function outsideEnterGuard(redirectTo: string) {
    return (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
        if (from.path === '/') {
            next({name: redirectTo});
        } else {
            next();
        }
    };
}

export function menuGuard(possiblePath?: string) {
    return (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
        const legalRouting: Array<string> = store.state.genprox.legalRouting;
        if (legalRouting.includes(possiblePath ?? to.path)) {
            next();
        } else {
            next(`/welcome?redirect=${to.path}`);
            // welcome is checking in beforeMount if there's redirect from menuGuard
        }
    };
}
