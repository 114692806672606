import {ActionTree} from "vuex";
import {DictionaryState} from "@/modules/shared/dictionary-store/types";
import {RootState, SelectOption} from "@/store/types";
import {_axios as axios} from "@/plugins/axios";
import {BACKEND_API_URL} from "@/shared/consts";



const actions: ActionTree<DictionaryState, RootState> = {

    async getInvestmentKnowledge({state, commit}){
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/investment-knowledge`);
        commit('setInvestmentKnowledgeSelectOptions', data);
    },
    async getDocumentTypes({state, commit}){
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/document-types`);
        commit('setDocumentTypeSelectOptions', data);
    },
    async getInvestmentPotentials({state, commit}){
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/investment-potentials`);
        commit('setInvestmentPotentialsSelectOptions', data);
    },
    async getIndustries({state, commit}){
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/industries`);
        commit('setIndustries', data);
    },
    async getSourceNames({state, commit}){
        const { data } = await axios.get<Array<{id: string, name: string}>>(`${BACKEND_API_URL}/marketplace/investment-client/source-names`);
        commit('setSourceNames', data.map( record => ({label: record.name, value: record})));
    },
    /* ^ Investors selects ^ */

    async getProductTypes({state, commit}) {
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/product/types`);
        commit('setProductTypeSelectOptions', data);
    },
    async getCommissionTypes({state, commit}) {
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/product/loan/commission-types`);
        commit('setCommisionsPayment', data);
        commit('setMarginPayment', data);
    },
    async getDurationTypes({state, commit}) {
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/product/loan/duration-types`)
        commit('setDurationSelectOptions', data);
    },
    async getInterestCalculationMethods({state, commit}) {
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/product/loan/interest-calculation-methods`)
        commit('setInterestCalculationMethod', data);
    },
    async getInterestPayment({state, commit}) {
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/product/loan/interest-payments`);
        commit('setInterestPayment', data);
    },
    async getInterestType({state, commit}) {
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/product/loan/interest-types`)
        commit('setInterestType', data);
    },
    async getProductSourceName({state, commit}) {
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/product/source-names`)
        commit('setProductSourceName', data.map( data => ({label: data.name, value: data})));
    },
    async getProductClasses({state, commit}) {
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/product/classes`)
        commit('setProductClass', data);
    },
    async getCountries({state, commit}) {
        const {data} = await axios.get<Array<SelectOption>>(`${BACKEND_API_URL}/crm/countries`);
        commit('setCountries', data);
    },
    async getAIFMSelects({state, commit}) {
        const {data} = await axios.get<Array<SelectOption>>(`${BACKEND_API_URL}/regulatory-reporting/categories`)
        commit('setAIFMCategories', data);
    },
    async getLegalEntitySelects({state, commit}) {
        if(state.legalEntitySelects.legalForm.length === 0){
            const {data: {legalForm, programme, endOfFinancialPeriod}} = await axios.get(`${BACKEND_API_URL}/crm/legal-entity/dictionaries`);
            commit('setLegalForm', legalForm);
            commit('setProgramme', programme);
            commit('setEndOfFinancialPeriod', endOfFinancialPeriod);
        }
    },
    async getBrokerPermissions({state, commit}){
        const { data } = await axios.get<Array<SelectOption<string>>>(`${BACKEND_API_URL}/marketplace/broker/permissions`);
        commit('setBrokerPermissionsSelectOptions', data);
    },
}

export default actions;
