














import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";

@Component
export default class ExpandCollapse extends Vue {

  @Prop() readonly beforeExpand!: Function;
  @Prop() readonly expand!: Function;
  @Prop() readonly collapse!: Function;
  @Prop() readonly afterExpand!: Function;
  @Prop() readonly height!: number;
  @Prop({default: '0.4'}) readonly time!: string;

  beforeExpandAnimation(el: HTMLDivElement): void {
    el.style.transitionDuration = `${this.time}s`;
    el.style.overflowY = 'hidden';
    el.style.maxHeight = '0';
    if(this.beforeExpand) this.beforeExpand(el);
  }
  expandAnimation(el: HTMLDivElement){
    setTimeout( () => {
      el.style.maxHeight = 700 + 'px';
      if(this.expand) this.expand(el);
    }, 1);

  }
  afterEnter(el: HTMLDivElement) {
    el.style.overflow = 'auto';
    if(this.afterExpand){
      this.afterExpand(el);
    }
  }

  collapseAnimation(el: HTMLDivElement){
    el.style.overflowY = 'hidden';
    el.style.maxHeight = '0px';
    if(this.collapse) this.collapse(el);
  }
}
