var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-menu"
  }, [_c('div', {
    staticClass: "header",
    attrs: {
      "id": _vm.generateTestId(_vm.menu.label)
    },
    on: {
      "click": function click($event) {
        return _vm.toggleMenu();
      }
    }
  }, [_c('div', {
    staticClass: "header__group"
  }, [_c('div', {
    staticClass: "header__icon ",
    class: _vm.isActiveMenu ? 'header__icon--active' : ''
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")(_vm.menu.label)
    }
  })]), _c('div', {
    staticClass: "header__text"
  }, [_vm._v(" " + _vm._s(_vm.menu.label) + " ")])]), !(_vm.activeMenu === _vm.menu) ? _c('div', {
    staticClass: "header__arrow"
  }, [_c('sygni-arrow-button', {
    attrs: {
      "rotate-on-click": _vm.openable,
      "open": _vm.open
    }
  })], 1) : _vm._e(), _vm.activeMenu === _vm.menu ? _c('div', {
    staticClass: "header__red-mark"
  }) : _vm._e()]), _c('ExpandCollapse', {
    attrs: {
      "height": _vm.menuHeight
    }
  }, [_vm.open ? _c('div', {
    staticClass: "list"
  }, [_vm._l(_vm.menu.children, function (menuItem) {
    return [_c('div', {
      key: menuItem.label,
      class: _vm.activeMenu === menuItem ? 'list__item--active list__item' : 'list__item',
      attrs: {
        "id": _vm.generateTestId("".concat(_vm.menu.label, " ").concat(menuItem.label))
      },
      on: {
        "click": function click($event) {
          return _vm.setActive(menuItem);
        }
      }
    }, [_vm._v(" " + _vm._s(menuItem.label) + " "), _vm.activeMenu === menuItem ? _c('div', {
      staticClass: "list__item__red-mark"
    }) : _vm._e()])];
  })], 2) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }