




























import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit, Prop} from "vue-property-decorator";
import SygniDropdownItem from "@/components/sygni-dropdown/SygniDropdownItem.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import UserPresentation from "@/components/UserPresentation.vue";
@Component({
  components: {SygniArrowButton, UserPresentation, ExpandCollapse, SygniDropdownItem}
})
export default class SygniDropdown extends Vue {

  @Prop() readonly items!: DropdownImageItem[];
  @Emit('activeItem')
  emitActiveItem(item: DropdownImageItem): DropdownImageItem {
    return item;
  }
  @Prop() activeItem: DropdownImageItem;
  open: boolean = false;

  get listHeight() {
    return this.items.length * 42;
  }

  get nameSymbol(): String{
    return this.activeItem.text?.slice(0,1);
  }


  toggleDropdown(): void {
    this.open = !this.open;
    if(this.open) {
      setTimeout( () => {
        document.addEventListener('click', this.onDocumentClick);
      },1)
    }
 }

 onDocumentClick(): void {
    this.open = false
    document.removeEventListener('click', this.onDocumentClick);
 }

  setActiveItem(item: DropdownImageItem<any>): void {
    this.emitActiveItem(item);
    this.$forceUpdate();
  }

  beforeExpandAnimation(el: HTMLDivElement): void {
    el.style.borderBottomLeftRadius = '5px';
    el.style.borderBottomRightRadius = '5px';
  }
}

export interface DropdownImageItem<T = any> {
  text: string,
  subtext?: string,
  image: string,
  value: T
}
