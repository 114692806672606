var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-file-box"
  }, [_c('div', {
    staticClass: "sygni-file-box__inner"
  }, [_vm._t("default")], 2), _vm._t("additionalActions"), _vm.showPreview ? _c('div', {
    staticClass: "sygni-file-box__action"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('Fund'),
      "alt": "Preview"
    }
  })]) : _vm._e(), _vm.icon && !_vm.readOnlyMode ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.actionTooltip,
      expression: "actionTooltip",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "sygni-file-box__action",
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")(_vm.icon),
      "alt": "Action"
    }
  })]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }