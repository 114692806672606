import {RouteConfig} from "vue-router";
import {BACKEND_BASE_URL} from "@/shared/consts";
import store from "@/store/rootStore";
import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing';

const accountingModule = () => import('./containers/accountingModule.vue');
const accountingBlindModule = () => import('./containers/accountingBlindModule.vue');
const accountingDocumentModule = () => import('./containers/accountingDocumentModule.vue');
const accountingPivotTable = () => import('./containers/accountingPivotTable.vue');

export const accountingRouting : Array<RouteConfig> = [
    {
        path: 'accounting',  /* /company || /fund */
        name: 'accounting',
        component: accountingModule,
        children: [],
        async beforeEnter(to, from, next) {
            const contextData = store.getters['accounting/getContextData'];
            if(!contextData?.id) {
                const resp = await store.dispatch('genprox/getContextData');
                store.commit('accounting/setContextData', resp);
            }

            await store.dispatch('genprox/getAccessModules');
            const accessModules = store.getters['genprox/accessModules'];
            if(accessModules.accounting) {
                next();
            } else {
                next(`${to.path}/access-denied`);
            }
        },
    },
    {
        path: 'data-analyze',  /* /portfolio-company || /fund */
        name: 'data-analyze',
        component: accountingPivotTable,
    },
    {
        path: 'accounting/access-denied',
        name: 'access-denied',
        component: accountingBlindModule,
        children: [],
    },
    {
        path: 'accounting/:id',
        name: 'accounting-by-id',
        component: accountingDocumentModule,
        children: [],
        async beforeEnter(to, from, next) {
            const activeUserData = store.getters['genprox/activeUserData'];
            let pathName = window.location.href;
            pathName = pathName?.replace(window.location.origin, '')

            if (!activeUserData && pathName != '/auth/login') {
                localStorage.setItem(REDIRECTED_PATH, pathName)
            }

            const contextData = store.getters['accounting/getContextData'];

            if(to.query?.contextOwnerId) {
                await store.dispatch('genprox/setUserContext', { id: to.query.contextOwnerId });
            }

            if(!contextData?.id) {
                const resp = await store.dispatch('genprox/getContextData');
                store.commit('accounting/setContextData', resp);
            }

            
            await store.dispatch('genprox/getAccessModules');
            const accessModules = store.getters['genprox/accessModules'];
            if(accessModules.accounting) {
                next();
            } else {
                next(`${to.path.substring(0, to.path.lastIndexOf('/'))}/access-denied`);
            }
        },
    },
];
