

































































import Vue from "vue";
import Component from "vue-class-component";
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop, Watch} from "vue-property-decorator";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import PortfolioModal from '@/modules/genprox/modules/fund/modules/portfolio/components/PortfolioModal.vue';
import SygniLinkButton from "@/components/buttons/SygniLinkButton.vue";
import {BSpinner, BTable} from "bootstrap-vue";
import moment from 'moment';
import {LastTransactionsInterface} from "@/modules/genprox/modules/profile/models/lastTransactions.interface";

@Component({
  components: {SygniCard, SygniRoundedButton, BSpinner, BTable, PortfolioModal, SygniLinkButton},
})
export default class FundTransactions extends Vue {
  @Prop() transactions: LastTransactionsInterface[] | boolean;

  tableFields = [
    {key: 'code', sortable: false, label: 'Code'},
    {key: 'companyName', sortable: false, label: 'Company name'},
    {key: 'type', sortable: false, label: 'Type'},
    {key: 'date', sortable: false, label: 'Agreement date'},
    {key: 'status', sortable: false, label: 'Status'},
    {key: 'value', sortable: false, label: 'Value'},
  ];

  tableItems: LastTransactionsInterface[] | [] | boolean = [];
  selectedDetails: any = [];
  activeIndex?: number = null;
  prevIndex: number = 0;
  nextIndex: number = 0;
  transactionId: string = '';
  isSingleTransactionModalLoading: boolean = false;
  showSingleTransactionModal: boolean = false;

  get isFund() {
    return !!(this.$route?.path?.includes('/fund') && !this.$route?.path?.includes('/fund-manager'))
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  goToTransactions() {
    if (this.isFund) {
      this.$router.push({ path: '/fund/portfolio/by-company' })
    } else {
      this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/transactions` })
    }
  }

  formatK(n: number) {
    if (n < 1e3) return n;
    if (n >= 1e3) {
      let number: string | number = +(n / 1e3).toFixed(0);
      number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      return number + " K"
    }
  }

  setCurrency(el: any) {
    return this.formatK(el.value) + ` ${el.currency}`;
  }

  formatDate(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  detailsModalLoaded() {
    this.isSingleTransactionModalLoading = false;
  }

  setIndexes(index: number) {
    if (this.activeIndex == index) return;

    this.activeIndex = index;
    this.prevIndex = (this.activeIndex == undefined) ? undefined : (Number(this.activeIndex) == 0) ? this.selectedDetails.length - 1 : Number(this.activeIndex) - 1;
    this.nextIndex = (this.activeIndex == undefined) ? undefined : (this.selectedDetails.length <= Number(this.activeIndex) + 1) ? 0 : Number(this.activeIndex) + 1;
    this.isSingleTransactionModalLoading = true;
  }

  onRowClicked(row: any) {
    // @ts-ignore
    const currentIndex = this.tableItems.findIndex(el => el.transactionId == row.transactionId);
    this.selectedDetails = this.tableItems;
    this.setIndexes(currentIndex);
    this.showSingleTransactionModal = true;
  }

  closeTransactionModal() {
    this.showSingleTransactionModal = false;
    this.selectedDetails = [];
    this.activeIndex = null;
    this.transactionId = '';
  }

  @Watch('transactions') onDateChange() {
    this.tableItems = this.transactions ? this.transactions : [];
  }

  @Watch('activeIndex') onActiveIndexChange() {
    const activeTransaction = this.selectedDetails[this.activeIndex];

    if (activeTransaction) {
      this.transactionId = activeTransaction.transactionId;
    }
  }
}
