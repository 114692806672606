








import Vue from 'vue'
import Component from 'vue-class-component'
import { BSpinner } from 'bootstrap-vue'
import { Prop } from 'vue-property-decorator';

@Component({
  components: { BSpinner }
})
export default class SygniLoader extends Vue {
  @Prop({ default: false }) isLoading: boolean;
}
