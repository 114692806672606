var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sygni-card', {
    staticClass: "header"
  }, [_vm.selectAvailable && _vm.showSelect ? _c('sygni-select', {
    attrs: {
      "label": _vm.isPortfolioCompany ? 'COMPANY' : 'FUND',
      "options": _vm.selectOptions,
      "searchable": true,
      "value": _vm.viewedId
    },
    on: {
      "input": _vm.setViewedId
    }
  }) : _vm._e(), _c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": 'Date',
      "placeholder": _vm.filters.date.placeholder,
      "type": "month"
    },
    on: {
      "change": _vm.setDataDashboard
    },
    model: {
      value: _vm.filters.date.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.date, "value", $$v);
      },
      expression: "filters.date.value"
    }
  }), _c('div', {
    staticClass: "header__types"
  }, [_c('p', {
    staticClass: "header__label"
  }, [_vm._v("Type")]), _c('div', {
    staticClass: "header__types-content"
  }, [_c('sygni-radio', {
    staticClass: "secondary",
    attrs: {
      "group": 'investor-type',
      "label": 'All',
      "radioValue": 'all'
    },
    model: {
      value: _vm.filters.type,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "type", $$v);
      },
      expression: "filters.type"
    }
  }), _c('sygni-radio', {
    staticClass: "secondary",
    attrs: {
      "group": 'investor-type',
      "label": 'Registered',
      "radioValue": 'registered'
    },
    model: {
      value: _vm.filters.type,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "type", $$v);
      },
      expression: "filters.type"
    }
  }), _c('sygni-radio', {
    staticClass: "secondary",
    attrs: {
      "group": 'investor-type',
      "label": 'Unregistered',
      "radioValue": 'unregistered'
    },
    model: {
      value: _vm.filters.type,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "type", $$v);
      },
      expression: "filters.type"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }