

import { Module } from "vuex";
import { RootState } from "@/store/types";
import { DictionaryState } from "@/modules/shared/dictionary-store/types";
import {mutations} from "@/modules/shared/dictionary-store/mutations";
import actions from "@/modules/shared/dictionary-store/actions";
import getters from "@/modules/shared/dictionary-store/getters";

export const dictionary: Module<DictionaryState, RootState> = {
    namespaced: true,
    state: new DictionaryState(),
    getters,
    actions,
    mutations
}
