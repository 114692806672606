




















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from "vue-property-decorator";
import SygniDroparea from "@/components/inputs/SygniDroparea.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import Utils, {UploadProgress} from "@/modules/shared/utils/utils";
import {BProgress} from "bootstrap-vue";

@Component({
  components: {SygniRoundedButton, SygniDroparea, BProgress}
})
export default class FileUploader extends Vue {
  @Prop({default: {progress: -1}}) uploadProgress!: UploadProgress;
  @Prop({default: false}) multiple!: boolean;
  @Watch('uploadProgress', {deep: true}) onUploadProgressReset(){
    if(this.uploadProgress.progress === -1){
      this.isUploading = false;
    }
  }
  @Prop({ default: '' }) tooltipText: string;
  @Prop({ default: '' }) icon: string;
  @Prop({default: ['']}) supportedFileFormats!: Array<string>;
  @Prop({default: 5000000}) maxFileSize!: number;
  @Prop({default: 'Drag and drop your files'}) dropAreaText!: string;
  @Prop({default: 'File format: pdf, word, excel / max 5mb'}) infoText!: string;
  @Prop({default: 'Upload from computer'}) btnText!: string;
  @Prop({default: 'normal'}) type!: string;
  @Prop({default: false}) inverted: boolean;
  @Prop({default: false}) isDisabled: boolean;

  isUploading: boolean = false;

  private isSupportedFileFormat(file: File): boolean {
    if (!this.supportedFileFormats || !this.supportedFileFormats?.length) {
      return true
    }

    return this.supportedFileFormats.includes(Utils.getFileExtensions(file.name).toLocaleLowerCase());
  }

  openFileSelector(): void {
    (this.$refs[`fileInput`] as HTMLInputElement).click();
  }

  handleFileInput(e: any) {
    const files = (this.multiple) ? e.target.files : [e.target.files[0]];
    this.addFiles(Array.from(files));
  }

  addFiles(files: Array<File>): void {
    files = Array.from(files);

    (this.$refs.fileInput as HTMLInputElement).value = null;
    let sizeExceeded = false;
    let notSupported = false;
    this.isUploading = true;

    files.forEach((file: File) => {
      if(!this.isSupportedFileFormat(file)) {
        notSupported = true;
        return;
      }
      if(file.size > this.maxFileSize) {
        sizeExceeded = true;
        return;
      }
    });

    if(notSupported) {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Wrong file format',
        text: `At least one file contains wrong file format. Supported formats are: ${this.supportedFileFormats.join(', ')}`
      });
    }

    if(!notSupported && sizeExceeded) {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Exceeded file size',
        text: `At least one file exceed max file size which is: ${ (this.maxFileSize / 1000000).toFixed(1) } MB`
      });
    }

    if(!this.multiple) {
      this.isUploading = false;
    }

    if(!notSupported && !sizeExceeded) {
      this.$emit('input', files);
    } else {
      this.isUploading = false;
    }
  }
}
