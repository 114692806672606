







import Vue from 'vue';
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';

@Component({
  components: { SygniRectButton },
})
export default class SygniInputGroup extends Vue {
  @Prop() placeholder: string;
  @Prop({ default: 'filled primary' }) btnClasses: string;
  @Prop({ default: 'Edit' }) btnText: string;
  @Prop({default: ''}) value!: String;
  modelValue: String = '';

  @Watch('value', {immediate: true}) onValueChange(){
    this.modelValue = this.value;
  }

  @Emit('input')
  handleInput(): String {
    return this.modelValue;
  }
}
