











































import Component from 'vue-class-component'
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import Multiselect from 'vue-multiselect'
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniSelect from './SygniSelect.vue';
import UserPresentation from '@/components/UserPresentation.vue';
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { mapState } from 'vuex';
import { UserContext } from '@/modules/genprox/models/User';

@Component({
  components: { SygniCheckbox, SygniInputError, Multiselect, SygniRoundedButton, SygniRectButton, UserPresentation },
  computed: {
    ...mapState('genprox', {
      userContexts: (state: any) => state.userContexts,
      activeUserContextId: (state: any) => state.activeUserData.context?.id,
      isVerifiedProfile: (state: any) => state.activeUserData?.verified,
      user: (state: any) => state.activeUserData?.user,
    }),
  }
})
export default class SygniContextSelect extends SygniSelect {
  @Prop({default: false}) hasAfterlist: boolean;

  userContexts!: UserContext[];
  activeUserContextId!: string;
  isVerifiedProfile!: boolean;
  user: any;

  get contextOptions() {
    const options = _.cloneDeep(this.formattedOptions).sort((a: any, b: any) => {
      if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
        return -1;
      }
      if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const personalAccount = options.find((el: any) => el?.label === 'Personal Account');

    if (personalAccount) {
      personalAccount.label = this.userName
  
      options.unshift(options.splice(options.indexOf(personalAccount), 1)[0]);
    }

    return options;
  }

  get userName() {
    return this.user?.firstName || this.user?.surname ? `${this.user?.firstName} ${this.user?.surname}` : 'Personal Account'
  }

  get userDescription() {
    return !this.isVerifiedProfile && !this.user?.professionalHeader ? 'Update your profile' : this.user.professionalHeader
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get personalAccount() {
    const contexts = this.$store.getters['genprox/userContexts']

    return contexts?.find((el: any) => el?.name?.toLowerCase() === 'personal account')
  }

  get isPersonalAccount() {
    return !!(this.activeUserData?.context?.name?.toLowerCase() === 'personal account')
  }

  descriptionString(option: any): string {
    let description = option.type.toUpperCase();

    if(option?.address?.city) {
      description += `, ${option.address.city}`;
    }

    if(option?.address?.country) {
      description += `, ${option.address.country}`;
    }

    return description;
  }

  highlightedText(option: string, data: any) {
    if (option && (this.$refs.multiSelect as any)?.search) {
      const index = option.toLowerCase().indexOf((this.$refs.multiSelect as any)?.search?.toLowerCase());
      if (index >= 0) {
        option = option.substring(0, index) + "<span class='highlighted-text'>" + option.substring(index, index + (this.$refs.multiSelect as any)?.search?.length) + "</span>" + option.substring(index + (this.$refs.multiSelect as any)?.search?.length);
      }
    }

    if (data) {
      let additionalString = ` ${data.tin ? `| ${data.tin}` : ''}`;
      const index = additionalString.toLowerCase().indexOf((this.$refs.multiSelect as any)?.search?.toLowerCase());
      if (index >= 0) {
        additionalString = additionalString.substring(0, index) + "<span class='highlighted-text'>" + additionalString.substring(index, index + (this.$refs.multiSelect as any)?.search?.length) + "</span>" + additionalString.substring(index + (this.$refs.multiSelect as any)?.search?.length);
      }
      option += `${additionalString}`;
    }

    return option;
  }

  handleInputEvent(option: any) {
    this.$root.$emit('clearTablesData')
    this.handleInput(option)
  }

  addNewFundManagerHandle() {
    if (this.isPersonalAccount) {
      this.$emit('addNewFund')
    } else {
      if (this.personalAccount) {
        this.$root.$emit('setUserContext', this.personalAccount, '/fund/select-fund');
        (this.$refs.multiSelect as any).isOpen = false
      } else {
        this.$emit('addNewFund')
      }
    }
  }
}

