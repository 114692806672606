






























import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit, Prop, Watch} from "vue-property-decorator";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {MenuItem} from "@/modules/genprox/store/types";

@Component({
  components: {SygniArrowButton, ExpandCollapse}
})
export default class SygniMenu extends Vue {

  @Prop() menu!: MenuItem;
  @Prop() activeMenu!: MenuItem;
  @Emit('activeMenuChange') emitActiveMenu(activeMenu: MenuItem) {
    return activeMenu;
  }

  @Watch('activeMenu', {immediate: true}) onActiveMenuChange() {
    if(this.menu.children?.includes(this.activeMenu)){
      this.open = true;
    }
  }

  open: boolean = false;

  generateTestId(id: string) {
    return `fe_smenu_${this.$options.filters.slugify(id).replaceAll('-', '_') }`
  } 

  get openable(): boolean {
    return this.menu.children?.length !== 0;
  }

  get isActiveMenu(): boolean {
    return this.menu === this.activeMenu ||
        this.menu.children.includes(this.activeMenu);
  }

  mounted(): void {
  }

  get menuHeight(): number {
    // @ts-ignore: Object is possibly 'null'.
    return this.menu.children?.length * 32+30;
  }

  setActive(item: MenuItem): void {
    this.emitActiveMenu(item);
  }

  toggleMenu() {
    if(!this.openable) {
      this.setActive(this.menu);
    } else {
      this.open = !this.open;
    }
  }
}

