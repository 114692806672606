import {Query} from "@/shared/interfaces/Common";
import {AxiosRequestConfig} from "axios";
import {MenuItem} from "@/modules/genprox/store/types";
const JSZip = require('jszip');
const JSZipUtils = require('jszip-utils');
import { saveAs } from "file-saver";
import { BACKEND_BASE_URL } from "@/shared/consts";
export default class Utils {

    static getObjectKeys<T>(object: T): Array<String> {
        return Object.keys(object);
    }

    // expires in epoch time (unix timestamp)
    static setCookie(name: string, value: string, expires: number) {
        const d = new Date();
        d.setTime(d.getTime() + expires);

        const expiresString: string = "expires="+ d.toUTCString();
        document.cookie = name + "=" + value + ";" + expiresString + ";path=/";
    }

    static getCookie(cname: string) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static parseJwt (token: string) {
        if(!token) return;
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    static camelCaseToKebabCase(str: string): string {
        return str.split('').map((letter, idx) => {
            return letter.toUpperCase() === letter
                ? `${idx !== 0 ? '_' : ''}${letter.toLowerCase()}`
                : letter;
        }).join('');
    }

    
    static flattenObject(data: any, counterpartyType = "", counterpartyName = "", reportNumber = ""): any[] {
        const flattenedData: any[] = [];

        if (typeof data === "object") {
            if (!Array.isArray(data)) {
            for (const key in data) {
                const newCounterpartyType = counterpartyType || key;
                const newCounterpartyName = counterpartyName || (typeof data[key] === "object" ? key : null);
                const newReportNumber = reportNumber || (typeof data[key] === "object" ? key : null);

                const entry: any = {
                counterpartyType: newCounterpartyType,
                counterpartyName: newCounterpartyName,
                reportNumber: newReportNumber,
                value: null,
                };
                flattenedData.push(entry);

                if (typeof data[key] === "object") {
                flattenedData.push(
                    ...this.flattenObject(data[key], newCounterpartyType, newCounterpartyName, newReportNumber)
                );
                }
            }
            } else {
            for (const item of data) {
                const entry: any = {
                counterpartyType,
                counterpartyName,
                reportNumber,
                value: item,
                };
                flattenedData.push(entry);
            }
            }
        }

        return flattenedData;
    }


    static parseGetItemsQuery(query: Query): string {
        let queryString: string = '?';
        if(query.filters && query.filters !== ''){
            queryString += '&' + query.filters;
        }
        if(query.sorting && query.sorting !== ''){
            queryString += '&' + query.sorting;
        }
        if(query.limit && query.limit !== ''){
            queryString += '&' + query.limit;
        }
        if(queryString.length > 0) {
            queryString = queryString.slice(0,1) + queryString.slice(2, queryString.length);
        }
        return queryString;
    }

    static getNumbersFromString(string: String): string {
        return string.replace(/\D/g,'');
    }

    static getFileExtensions(filename: string) {
        const parts = filename.split('.');
        return parts[parts.length - 1];
    }

    static downloadFileByUrl(url: string, name: string){
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const localUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = localUrl;
                link.download = name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    }

    static getActiveModulesFromMenu(menu: MenuItem[]): Array<string> {
        let activeModules: Array<string> = [];
        menu.forEach((menuItem: MenuItem) => {
            if (menuItem.children?.length > 0) {
                activeModules = activeModules.concat(this.getActiveModulesFromMenu(menuItem.children));
            } else {
                activeModules.push(menuItem.route)
            }
        })
        return activeModules;
    }

    static createFileUrlFromContent(fileContent: string, fileType: string = 'text/xml') {
        const bb = new Blob([fileContent], { type: fileType });

        return URL.createObjectURL(bb);
    } 

    /**
    * Fetch the content and return the associated promise.
    * @param {String} url the url of the content to fetch.
    * @return {Promise} the promise containing the data.
    */
    static urlToPromise(url: string) {
        return new Promise(function(resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
                if(err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    static async getInternalFilesBinaryContent(path: string) {
        return await this.urlToPromise(path);
    }

    static downloadInternalZippedFilesByUrls(docs: Array<{fileName: string, path: string}>) {
        const zip = new JSZip();

        docs.forEach(doc => {
            zip.file(doc.fileName, this.urlToPromise(doc.path), { binary: true });
        });

        zip.generateAsync({ type: 'blob' })
        .then((blob: Blob) => {
            let currentDate: Date | string = new Date();
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            currentDate = `${currentDate.getFullYear()}-${this.pad(currentDate.getMonth() + 1, 2)}-${this.pad(currentDate.getDate(), 2)}`;
            saveAs(blob, `xmls-${currentDate}.zip`);
        })
        .catch((e: Error) => {
            console.log('cannot generate this file: ', e);
        });
    }

    static shouldSend(document: any, contextData: any, activeUserData: any) { // false - approve, true - send for approval
        if (document?.registerType === 'revenues' || !contextData?.hasApprovers) {
            return false // approve
        }

        // if user is approver and has cost center assigned to selected document
        return !(activeUserData?.role?.workflow?.includes('approver') && activeUserData.costCenters.includes(document?.costCenter))
    }

    static async generateZippedFilesByUrls(docs: Array<{fileName: string, path: string}>, fileName: string | null = null) {
        return new Promise((resolve, reject) => {
            const zip = new JSZip();

            docs.forEach(doc => {
                const fileName = doc.fileName.replaceAll('/', '_')
                zip.file(fileName, this.urlToPromise(`${BACKEND_BASE_URL}${doc.path}`), { binary: true, createFolders: false });
            });

            zip.generateAsync({ type: 'blob' })
            .then((blob: Blob) => {
                let currentDate: Date | string = new Date();
                currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                currentDate = `${currentDate.getFullYear()}-${this.pad(currentDate.getMonth() + 1, 2)}-${this.pad(currentDate.getDate(), 2)}`;
                if(fileName) {
                    resolve({ path: URL.createObjectURL(blob), fileName: `${fileName}.zip` })
                } else {
                    resolve({ path: URL.createObjectURL(blob), fileName: `documents-${currentDate}.zip` })
                }
            })
            .catch((e: Error): any => {
                console.log('cannot generate this file: ', e);
                reject(e)
            });
        })
    }

    static downloadZippedFilesByUrls(docs: Array<{fileName: string, path: string}>, fileName: string | null = null) {
        const zip = new JSZip();

        docs.forEach(doc => {
            const fileName = doc.fileName.replaceAll('/', '_')
            const filePath = doc.path?.includes('blob:') ? doc?.path : `${BACKEND_BASE_URL}${doc.path}`
            zip.file(fileName, this.urlToPromise(filePath), { binary: true, createFolders: false });
        });

        zip.generateAsync({ type: 'blob' })
        .then((blob: Blob) => {
            let currentDate: Date | string = new Date();
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            currentDate = `${currentDate.getFullYear()}-${this.pad(currentDate.getMonth() + 1, 2)}-${this.pad(currentDate.getDate(), 2)}`;
            if(fileName) {
                saveAs(blob, `${fileName}.zip`);
            } else {
                saveAs(blob, `documents-${currentDate}.zip`);
            }
        })
        .catch((e: Error) => {
            console.log('cannot generate this file: ', e);
        });
    }

    static pad(num: number, size: number): string {
        let numString = num.toString();
        while (numString.length < size) numString = "0" + numString;
        return numString;
    }

    static getUploadFileConfig(progressVariable?: UploadProgress): AxiosRequestConfig {
        return {
            headers: {'content-type': 'multipart/form-data'},
            onUploadProgress: (progressEvent: ProgressEvent) => {
                if(progressVariable){
                    progressVariable.progress =  Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                }
            }
        }
    }

    static formatSubAssetType(detail: any) {
        if (detail.isiiCode) {
            detail.subAssetType = 'SEC_LEQ_OTHR'
        }

        // legacy
        switch (detail?.market) {
            case 'Weksle':
                detail.subAssetType = 'SEC_CSH_COMP';
                break;
            case 'Obligacje korporacyjne':
                detail.subAssetType = 'SEC_CPN_INVG';
                break;
            case 'Obligacje korporacyjne niewyemitowane przez instytucje finansowe - nieopatrzone ratingiem na poziomie inwestycyjnym':
                detail.subAssetType = 'SEC_CPN_NIVG';
                break;
            case 'Obligacje korporacyjne wyemitowane przez instytucje finansowe - opatrzone ratingiem na poziomie inwestycyjnym':
                detail.subAssetType = 'SEC_CPI_INVG';
                break;
            case 'Obligacje korporacyjne wyemitowane przez instytucje finansowe - nieopatrzone ratingiem na poziomie inwestycyjnym':
                detail.subAssetType = 'SEC_CPI_NIVG';
                break;
            case 'CIU_OAM_MMFC':
            case 'CIU_OAM_AETF':
            case 'CIU_OAM_OTHR':
            case 'CIU_NAM_MMFC':
            case 'CIU_NAM_AETF':
            case 'CIU_NAM_OTHR':
            case 'DER_CTY_PMGD':
            case 'DER_EQD_FINI':
            case 'DER_EQD_OTHD':
            case 'DER_CDS_SNFI':
            case 'DER_CDS_SNSO':
            case 'DER_CDS_SNOT':
            case 'DER_CDS_INDX':
            case 'DER_CDS_EXOT':
            case 'DER_CDS_OTHR':
            case 'DER_FEX_INVT':
            case 'DER_FEX_HEDG':
                detail.subAssetType = detail?.market
                break;
            case 'Obligacje skarbowe':
                detail.subAssetType = 'SEC_SBD_EUBY';
                break;
            case 'Inne':
                detail.subAssetType = 'SEC_UEQ_UEQY';
                break;
            default:
                break;
        }
        
        switch (detail?.type) {
            case 'Weksle':
                detail.subAssetType = 'SEC_CSH_COMP';
                break;
            case 'Obligacje korporacyjne':
                detail.subAssetType = 'SEC_CPN_INVG';
                break;
            case 'Obligacje korporacyjne niewyemitowane przez instytucje finansowe - nieopatrzone ratingiem na poziomie inwestycyjnym':
                detail.subAssetType = 'SEC_CPN_NIVG';
                break;
            case 'Obligacje korporacyjne wyemitowane przez instytucje finansowe - opatrzone ratingiem na poziomie inwestycyjnym':
                detail.subAssetType = 'SEC_CPI_INVG';
                break;
            case 'Obligacje korporacyjne wyemitowane przez instytucje finansowe - nieopatrzone ratingiem na poziomie inwestycyjnym':
                detail.subAssetType = 'SEC_CPI_NIVG';
                break;
            case 'CIU_OAM_MMFC':
            case 'CIU_OAM_AETF':
            case 'CIU_OAM_OTHR':
            case 'CIU_NAM_MMFC':
            case 'CIU_NAM_AETF':
            case 'CIU_NAM_OTHR':
            case 'DER_CTY_PMGD':
            case 'DER_EQD_FINI':
            case 'DER_EQD_OTHD':
            case 'DER_CDS_SNFI':
            case 'DER_CDS_SNSO':
            case 'DER_CDS_SNOT':
            case 'DER_CDS_INDX':
            case 'DER_CDS_EXOT':
            case 'DER_CDS_OTHR':
            case 'DER_FEX_INVT':
            case 'DER_FEX_HEDG':
                detail.subAssetType = detail?.type
                break;
            case 'Obligacje skarbowe':
                detail.subAssetType = 'SEC_SBD_EUBY';
                break;
            case 'Inne':
                detail.subAssetType = 'SEC_UEQ_UEQY';
                break;
            default:
                break;
        }

        return detail
    }
    
    // xml utils
    static complexFind(array: Array<any>, name: string): any {
        for (const item of array) {
            const result = item.name === name ? item : this.complexFind(item.children, name);
            if (result) return result;
        }

        return undefined;
    }

    static complexMultipleFind(array: Array<any>, names: Array<string>) {
        for (const name of names) {
            const result = this.complexFind(array, name);
            if (result) return result;
        }

        return undefined;
    }
}

export interface UploadProgress {
    progress: number
}
