

































































import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";

@Component
export default class SygniInput extends Vue {
  @Prop({ default: false }) useInvalidProperty: boolean;
  @Prop({ default: false }) useCustomError: boolean;
  @Prop() validation: any;

  get useInvalid() {
    if (this.useInvalidProperty) {
      return this.validation?.$invalid
    } else {
      return false
    }
  }
}
