import {NavigationGuardNext, Route, RouteConfig} from "vue-router";
import store from "@/store/rootStore";

const profileModule = () => import('./containers/profileModule.vue');
const editGeneralData = () => import('./components/edit-profile/EditGeneralData.vue');
const editDetailedData = () => import('./components/edit-profile/EditDetailedData.vue');
const editAddressData = () => import('./components/edit-profile/EditAddressData.vue');
const editSecurity = () => import('./components/edit-profile/EditSecurity.vue');
const profileDashboardContainer = () => import('./containers/ProfileDashboardContainer.vue');

export const profileRouting: Array<RouteConfig>  = [
    {
        path: 'profile',
        name: 'profile',
        component: profileModule,
        redirect: {
            name: 'profile-dashboard',
        },
        beforeEnter: async (to, from, next) => {
            await store.dispatch('profile/getProfileData');
            next();
        },
        children: [
            {
                path: 'edit/general',
                name: 'profile-edit-general',
                component: editGeneralData,
                beforeEnter: async (to, from, next) => {
                    await store.dispatch('profile/getProfileData');
                    next();
                },
            },
            {
                path: 'edit/detailed',
                name: 'profile-edit-detailed',
                component: editDetailedData,
                beforeEnter: async (to, from, next) => {
                    await store.dispatch('profile/getProfileData');
                    next();
                }
            },
            {
                path: 'edit/address',
                name: 'profile-edit-address',
                component: editAddressData,
                beforeEnter: async (to, from, next) => {
                    await store.dispatch('profile/getProfileData');
                    next();
                }
            },
            {
                path: 'edit/security',
                name: 'profile-edit-security',
                component: editSecurity,
                beforeEnter: async (to, from, next) => {
                    await store.dispatch('profile/getProfileData');
                    next();
                },
            },
            {
                path: 'dashboard',
                name: 'profile-dashboard',
                props: true,
                beforeEnter: async (to, form, next) => {
                    to.params.viewType = 'owner'
                    next();
                },
                component: profileDashboardContainer,
            }, // profile-investor-dashboard-guest
            {
                path: '/investor/:id',
                name: 'profile-dashboard-guest-investor',
                props: true,
                beforeEnter: async(to: Route, from: Route, next: NavigationGuardNext) => {
                    try {
                        const investorProfile = await store.dispatch('investors/getInvestorProfile', to.params.id);
                        const [products, keyKPIs, transactionsHistory, businessLinks] = await Promise.all([
                            store.dispatch('investors/getInvestorProductsNEW', to.params.id),
                            store.dispatch('investors/getInvestorKeyKPIs', to.params.id),
                            store.dispatch('investors/getInvestorTransactionHistory', to.params.id),
                            investorProfile.clientId ? store.dispatch('profile/getUserBusinessLinks', investorProfile.clientId).catch( () => {
                                return [];
                            }) : undefined,
                        ]);
                        store.commit('profile/setProductsTableItems', products);
                        store.commit('profile/setTransactionsTableItems', transactionsHistory);
                        to.params.keyKPIsByGuest = keyKPIs;
                        to.params.investorProfile = investorProfile;
                        to.params.businesssLinksByGuest = businessLinks;
                        to.params.viewType = 'investor'
                    } catch(e) {
                        next({ name: 'welcome' })
                    }

                    next();
                },
                component: profileDashboardContainer
            },
            {
                path: ':id',
                name: 'profile-dashboard-guest',
                props: true,
                beforeEnter: async(to: Route, from: Route, next: NavigationGuardNext) => {
                    try {
                        const [profile, businessLinks] = await Promise.all([
                            store.dispatch('profile/userProfile', to.params.id),
                            to.params.id ? store.dispatch('profile/getUserBusinessLinks', to.params.id).catch( () => {
                                return [];
                            }) : undefined,
                        ]);
                        to.params.profileByGuest = profile;
                        to.params.businesssLinksByGuest = businessLinks;
                        to.params.viewType = 'guest'
                    } catch(e) {
                        next({ name: 'welcome' });
                    }
                    
                    next();
                },
                component: profileDashboardContainer
            }
        ]
    },
];
