import {RouteConfig} from "vue-router";


const walletModule = () => import('./containers/WalletModule.vue');

export const walletRouting: Array<RouteConfig> = [
    {
        path: 'wallet',  /* /fund */
        name: 'myWallet',
        component: walletModule,
        children: [
            {
                path: 'product/:id',
            }
        ]
    },
]
