var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-dropdown-item",
    on: {
      "click": function click($event) {
        return _vm.emitItem();
      }
    }
  }, [_c('user-presentation', {
    attrs: {
      "name": _vm.item.text,
      "description": _vm.item.subtext,
      "image": _vm.item.image
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }