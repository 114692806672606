






import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";

@Component
export default class SygniArrowButton extends Vue {
  @Prop({default: false}) open!: Boolean;
  @Prop({default: false}) rotateOnClick!: boolean;

  get setClass() {
    if(!this.rotateOnClick) return '';
    return this.open ? 'sygni-arrow-button--open' : 'sygni-arrow-button--close';
  }
}
