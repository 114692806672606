







import Vue from 'vue'
import Component from 'vue-class-component'
import AppHeader from "@/modules/genprox/components/AppHeader.vue";
import AppSideBar from "@/modules/genprox/components/AppSideBar.vue";
import Notification from "@/components/Notification.vue";

@Component({
  components: {Notification, AppSideBar, AppHeader}
})
export default class App extends Vue {
  configuration: boolean = true;
  games: boolean = false;

  mounted(){
    this.checkApiKey();
    this.initAuthInterceptors();
  }

  checkApiKey() {
    // const apiKey = localStorage.getItem('apiKey');
    // if (apiKey) {
    //   this.$store.commit('root/setApiKey', apiKey);
    // }
  }

  initAuthInterceptors() {
    // const _that = this;
    // axios.interceptors.response.use(function (response) {
    //   return response
    // }, function (error) {
    //   if (error.response.status === 401 && _that.$route.name !== 'login') {
    //     _that.$store.dispatch('root/logout').then(() => {
    //       _that.$router.push({name: 'login'});
    //     })
    //   }
    //   return Promise.reject(error)
    // })
  }
}
