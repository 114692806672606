








import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit, Prop} from "vue-property-decorator";
import {DropdownImageItem} from "@/components/sygni-dropdown/SygniDropdown.vue";
import UserPresentation from "@/components/UserPresentation.vue";
@Component({
  components: {UserPresentation}
})
export default class SygniDropdownItem extends Vue {

  @Prop() readonly item!: DropdownImageItem<any>;

  open: boolean = false;

  @Emit('click')
  emitItem(): DropdownImageItem<any> {
    return this.item;
  }
}
