var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-presentation"
  }, [_c('div', {
    staticClass: "image"
  }, [_vm.image ? _c('img', {
    attrs: {
      "src": _vm.image
    }
  }) : _c('div', {
    staticClass: "char"
  }, [_vm._v(" " + _vm._s(_vm.nameSymbol) + " ")])]), _c('div', {
    staticClass: "group"
  }, [_vm._t("option", function () {
    return [_c('div', {
      class: "text ".concat(_vm.onNameClick ? 'text--clickable' : ''),
      on: {
        "click": function click($event) {
          var _vm$onNameClick, _vm2;

          $event.stopPropagation();
          (_vm$onNameClick = (_vm2 = _vm).onNameClick) === null || _vm$onNameClick === void 0 ? void 0 : _vm$onNameClick.call(_vm2);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _vm.description ? _c('div', {
      staticClass: "subtext"
    }, [_vm._v(" " + _vm._s(_vm.description) + " ")]) : _vm._e(), _vm._t("additionalBtn")];
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }