
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL} from "@/shared/consts";
import { AdvisorsState, Broker } from "./types";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";

export const state: AdvisorsState = {
  advisorsTable: {
    items: [],
    totalCount: 0,
    perPage: 10,
    query: new TableQuery(10),
  },
  advisorForm: {
    fields: [],
    data: null,
  },
  advisorsTableIsBusy: false,
}

export const getters: GetterTree<AdvisorsState, RootState> = {
  advisorFormFields(state) {
    return JSON.parse(JSON.stringify(state.advisorForm.fields));
  },
  advisorFormData(state) {
    return JSON.parse(JSON.stringify(state.advisorForm.data));
  },
  getAdvisorsTableItems(state) {
    return JSON.parse(JSON.stringify(state.advisorsTable.items));
  },
  getAdvisorsTableQuery(state) {
    return JSON.parse(JSON.stringify(state.advisorsTable.query));
  },
  getAdvisorsTablePerPage(state) {
    return JSON.parse(JSON.stringify(state.advisorsTable.perPage));
  },
}

export const mutations: MutationTree<AdvisorsState> = {
  setAdvisorFormFields(state, payload) {
    state.advisorForm.fields = payload;
  },
  setAdvisorFormData(state, payload) {
    state.advisorForm.data = payload;
  },
  setAdvisorsTableItems(state, payload: {items: Array<any>, totalCount: number}): void {
    state.advisorsTable = { ...state.advisorsTable, items: payload.items, totalCount: payload.totalCount};
  },
  setAdvisorsTableQuery(state, payload: TableQuery): void {
    state.advisorsTable.query = payload;
  },
  setAdvisorsTableSortingQuery(state, payload: Sorting): void { 
    state.advisorsTable.query.sorting = payload;
    state.advisorsTable.query.offset = 0;
  },
  setAdvisorsTableBusy(state,payload: boolean): void {
    state.advisorsTableIsBusy = payload;
  },
  setAdvisorsTablePerPage(state, payload: number): void {
    state.advisorsTable.perPage = payload;
  },
  setAdvisorsTableFiltersQuery(state, payload: string) {
    state.advisorsTable.query.filters = payload;
  },
}

export const actions: ActionTree<AdvisorsState, RootState> = {
  async getBrokers({state,commit}, filtersQuery?: string): Promise<Array<any>> {
    const queryString = state.advisorsTable.query.getStringQuery();
    filtersQuery = filtersQuery ? filtersQuery : '';
    const queryData = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/brokers${queryString}${filtersQuery}`, { headers: { 'x-total-count': true} });

    const payload: any = { items: queryData.data, totalCount: queryData.headers['x-total-count'] }

    commit('setAdvisorsTableItems', {
      items: payload.items,
      totalCount: payload.totalCount,
    });

    return payload.items;
  },
  async getAllBrokers({state,commit}): Promise<Array<any>> {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/brokers?limit=99999&offset=0`);

    return data
  },
  async getAllOwners({state,commit}): Promise<Array<any>> {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/owners`)

    return data
  },
  async getBroker({state, commit}, brokerId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/broker/${brokerId}`);

    return data;
  },
  async postBroker({state, commit}, payload: Broker) {
    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/broker`, payload);

    return data;
  },
  async editBroker({state, commit}, payload: { brokerId: string, data: Broker }) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/broker/${payload.brokerId}`, payload.data);

    return data;
  },
  async deleteBroker({state, commit}, brokerId: string) {
    const { data } = await axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/broker/${brokerId}`);

    return data;
  },
  async activateBroker({state, commit}, brokerId: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/broker/${brokerId}/activate`);

    return data;
  },
  async addAdvisorToNetwork({state}, payload: { networkId: string, id: string }) {
    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/broker-network/${payload.networkId}/broker`, { brokerId: payload.id, coordinator: false });

    return data;
  },
  async deleteAdvisorFromNetwork({state}, payload: { networkId: string, id: string }) {
    const { data } = await axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/broker-network/${payload.networkId}/broker/${payload.id}`);

    return data;
  },
  async updateAdvisorInNetwork({commit}, payload: { id: string, brokerId: string, coordinator: boolean }) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/broker-network/${payload.id}/broker/${payload.brokerId}`, { coordinator: payload.coordinator })

    return data
  },
  async getAdvisorForm({state, commit}) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/advisor-form`);
    commit('setAdvisorFormData', data);

    return data;
  },
  async getAdvisorFormParams({state, commit}) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/advisor-form-parameters`);
    commit('setAdvisorFormFields', data);

    return data;
  },
  async postAdvisorForm({state}, payload: { name: string, required: boolean }[]) {
    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/advisor-form`, {
      parameters: payload
    });

    return data;
  },
  async resendActivationEmail({state}, id: string) {
    const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/broker/${id}/reactivate`)

    return data
  }
}

export const advisors: Module<AdvisorsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

