import {GetterTree} from "vuex";
import {AIFMSelects, DictionaryState, GlobalSelects, InvestorSelects} from "@/modules/shared/dictionary-store/types";
import {RootState, SelectOption} from "@/store/types";
import vuexStore from "@/store/rootStore";

const getters: GetterTree<DictionaryState, RootState> = {
    investorsSelects(state): InvestorSelects {
        downloadIfEmpty([
            { value: state.investorSelects.investmentKnowledge, dispatchName: 'getInvestmentKnowledge' },
            { value: state.investorSelects.documentType, dispatchName: 'getDocumentTypes' },
            { value: state.investorSelects.investmentPotentials, dispatchName: 'getInvestmentPotentials' },
            { value: state.investorSelects.sources, dispatchName: 'getSourceNames' },
            { value: state.investorSelects.industries, dispatchName: 'getIndustries'}
        ]);
        return state.investorSelects;
    },
    addProductsSelects(state) {
        downloadIfEmpty([
            {value: state.addProductsSelects.productType, dispatchName: 'getProductTypes'},
            {value: state.addProductsSelects.class, dispatchName: 'getProductClasses'},
            {value: state.addProductsSelects.commisionPayment, dispatchName: 'getCommissionTypes'},
            {value: state.addProductsSelects.duration, dispatchName: 'getDurationTypes'},
            {value: state.addProductsSelects.source, dispatchName: 'getProductSourceName'},
            {value: state.addProductsSelects.interestCalculationMethod, dispatchName: 'getInterestCalculationMethods'},
            {value: state.addProductsSelects.interestType, dispatchName: 'getInterestType'},
            {value: state.addProductsSelects.interestPayment, dispatchName: 'getInterestPayment'},
            {value: state.addProductsSelects.marginPayment, dispatchName: 'getCommissionTypes'},
        ]);
        return state.addProductsSelects;
    },
    globalSelects(state): GlobalSelects {
        downloadIfEmpty([
            {value: state.globalSelects.countries, dispatchName: 'getCountries'}
        ]);
        return state.globalSelects;
    },
    AIFMSelects(state: DictionaryState): AIFMSelects {
        downloadIfEmpty([
            {value: state.AIFMSelects.categories, dispatchName: 'getAIFMSelects'}
        ]);
        return state.AIFMSelects;
    }

}

export default getters;

const dispatchedActions: Array<string> = [];

function downloadIfEmpty(data: Array<{ value: Array<SelectOption> | Array<string>, dispatchName: string}>){
    data.forEach( (record) => {
        if(!(record.value?.length > 0) && !(dispatchedActions.includes(record.dispatchName))){
            dispatchedActions.push(record.dispatchName);
            vuexStore.dispatch('dictionary/' + record.dispatchName);
        }
    });
}

