






















import Vue from "vue";
import Component from "vue-class-component";
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop} from "vue-property-decorator";
import {KPIs} from "@/modules/genprox/modules/profile/models/KPIs.interface";
import {Price} from "@/modules/genprox/models/Shared";

@Component({
  components: {SygniCard},
})
export default class FundKPIs extends Vue {
  @Prop() fundKPIs: KPIs;
  @Prop({ default: null }) fundAdditionalKPIs: any;
  @Prop({default: false}) withLabels: boolean;

  emptyPlaceholder: string = '---';
  labels: any = {
    invested: 'INVESTED FUNDS',
    portfolioValuation: 'PORTFOLIO VALUATIONS',
    companyCount: 'COMPANY COUNT',
    dealCount: 'DEAL COUNT'
  }

  notes: any = {
    companyCount: 'Companies',
    dealCount: 'Deals open'
  }

  get additionalKPIs() {
    const kpis: any[] = []

    if (this.fundAdditionalKPIs?.data?.length) {
      this.fundAdditionalKPIs?.data?.forEach((row: any) => {
        row?.forEach((item: any) => {
          kpis.push(item)
        })
      })
    }

    return kpis
  }

  getName(item: any) {
    return item.name
  }

  formatK(n: number) {
    const numString = n.toString();
    const isNegative = numString.charAt(0) === '-';
    const beforeChar = isNegative ? '-' : '';
    if (isNegative) n = n * -1;

    if (n < 1e3) return `${beforeChar} ${n}`;
    if (n >= 1e3) {
      let number: string | number = +(n / 1e3).toFixed(0);
      number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      return `${beforeChar} ${number} K`;
    }
  }

  getValue(item: number | Price | null) {
    if (item != null) {
      return typeof item === 'number' ? item : this.setCurrency(item)
    } else {
      return this.emptyPlaceholder
    }
  }

  getCurrency(item: number | Price | null, key: string) {
    if (item != null) {
      return typeof item === 'number' ? (this.notes[key] ? this.notes[key] : '') : item.currency
    }
  }

  setCurrency(el: Price) {
    if (el.value == null) {
      return '---';
    } else {
      return this.formatK(el.value);
    }
  }

  mounted() {
  }
}
