













import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit, Prop, Watch} from "vue-property-decorator";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
@Component({
  components: {SygniInputError}
})
export default class SygniRadio extends Vue {

  @Prop() label!: String;
  @Prop() value!: boolean;
  @Prop({default: false}) disabled: boolean;
  @Emit('input')
  handleInput(radioValue: any) {
    this.modelValue = radioValue;
    return this.modelValue;
  }
  @Watch('value', {immediate: true}) onValueChange(){
    if(this.value !== this.modelValue){
      this.modelValue = this.value;
    }
  }
  @Prop() radioValue!: boolean;
  @Prop() group!: string;
  modelValue: any = '';

  mounted(){
    this.modelValue = this.value;
  }

  clickCheckbox(){
    // @ts-ignore: Object is possibly 'null'.
    this.$el.querySelector('input').click();
  }
}
