var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "sygni-loader__spinner"
  }, [_c('b-spinner')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }