var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-collapse"
  }, [_c('transition', {
    on: {
      "before-enter": _vm.beforeExpandAnimation,
      "enter": _vm.expandAnimation,
      "after-enter": _vm.afterEnter,
      "leave": _vm.collapseAnimation
    }
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }