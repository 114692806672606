



















import Utils from '@/modules/shared/utils/utils';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SygniDroparea extends Vue {
  droparea: HTMLDivElement = {} as any;
  dropareaInput: HTMLInputElement = {} as any;
  @Prop({default: 'Drag and drop your files'}) dropAreaText!: string;
  @Prop({default: false}) isLoading: boolean;
  @Prop({default: false}) multiple: boolean;
  @Prop({default: null}) supportedFileFormats: string[] | null;
  @Prop({default: false}) preview: boolean;
  @Prop({default: ''}) previewUrl: string;
  @Prop({default: false}) disabled: boolean;

  private isSupportedFileFormat(file: File): boolean {
    return this.supportedFileFormats.includes(Utils.getFileExtensions(file.name).toLocaleLowerCase());
  }

  removePreview() {
    (this.$refs['dropareaInput'] as HTMLInputElement).value = '';
    this.$emit('previewRemoved');
  }

  mounted(){
    this.droparea = this.$refs.droparea as HTMLDivElement;
    this.dropareaInput = this.$refs.dropareaInput as HTMLInputElement;
  }

  handleClick () {
    if (!this.disabled) {
      this.dropareaInput.click();
    }
  }

  handleInputChange(event: any){
    if (!this.disabled) {
      event.preventDefault();
      const files: FileList = event.target.files;
      this.droparea.classList.remove('sygni-droparea--active');
  
      if (!this.formatsValidated(files)) {
        return;
      }
  
      for(let i = 0; i<files.length; i++){
        setTimeout(()=>{
          this.$emit('input', files[i]);
        },i*10);
      }
      this.$emit('change', files);
    }
  }

  handleDropFile(event: DragEvent){
    if (!this.disabled) {
      event.preventDefault();
      const files: FileList = event.dataTransfer.files;
      this.droparea.classList.remove('sygni-droparea--active');

      if (!this.formatsValidated(files)) {
        return;
      }

      for (let i = 0; i < files.length; i++) {
        setTimeout(() => {
          this.$emit('input', files[i]);
        }, i * 10);
      }
      this.$emit('change', files);
    }
  }

  preventDragOver(event: DragEvent){
    this.droparea.classList.add('sygni-droparea--active');
    event.preventDefault();
  }

  onDragLeave() {
    this.droparea.classList.remove('sygni-droparea--active');
  }

  formatsValidated(files: FileList) {
    if (this.supportedFileFormats && this.supportedFileFormats.length) {
      let notSupported = false;

      Array.from(files).forEach((file: File) => {
        if (!this.isSupportedFileFormat(file)) {
          notSupported = true;
          return;
        }
      });

      if (notSupported) {
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Wrong file format',
          text: `At least one file contains wrong file format. Supported formats are: ${this.supportedFileFormats.join(', ')}`
        });
      }

      return !notSupported;
    }

    return true;
  }
}
