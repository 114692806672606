




import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import {Prop} from "vue-property-decorator";
import {BTable, BvTableField} from "bootstrap-vue";

@Component({
  components: {ExpandCollapse}
})
export default class SygniInnerTable<T> extends BTable {

  @Prop({})
  items!: Array<T & Record<string, any>>;

  innerTableFields: (BvTableField & { key: string })[] = [];

  onMounted(): void {
    this.addSortingIcons();
  }

  addSortingIcons(): void {
    const ths = this.$el.querySelectorAll('th');
    const iconsHTML: string = `<div class="th__arrows">
        <img class="arrow-up" src="${require('@/assets/icons/arrow.svg')}">
        <img class="arrow-down" src="${require('@/assets/icons/arrow.svg')}"></div>`;
    this.innerTableFields.forEach( (field: any, index: number) => {
      if(field.sortable) {
        (ths[index].firstChild as HTMLElement).innerHTML += iconsHTML;
      }
    });
  }
}
