var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fund-transactions"
  }, [_c('div', {
    staticClass: "fund-transactions__header"
  }, [_c('p', {
    staticClass: "fund-transactions__title"
  }, [_vm._v(_vm._s(_vm.isFund ? 'Portfolio Investments' : 'Transaction history'))])]), _c('div', {
    staticClass: "fund-transactions__table-wrapper"
  }, [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "items": _vm.tableItems,
      "no-local-sorting": true,
      "show-empty": ""
    },
    on: {
      "row-clicked": _vm.onRowClicked
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c('h4', [_vm._v("You have no transaction")])];
      },
      proxy: true
    }, {
      key: "emptyfiltered",
      fn: function fn() {
        return [_c('h4', [_vm._v("You have no transaction")])];
      },
      proxy: true
    }, {
      key: "cell(code)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "td-text"
        }, [_vm._v(_vm._s(data.item.code))])];
      }
    }, {
      key: "cell(companyName)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "td-text"
        }, [_vm._v(_vm._s(data.item.companyName))])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "td-text"
        }, [_vm._v(_vm._s(data.item.type))])];
      }
    }, {
      key: "cell(date)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "td-text"
        }, [_vm._v(_vm._s(_vm.formatDate(data.item.date)))])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "td-text",
          staticStyle: {
            "text-transform": "capitalize"
          }
        }, [_vm._v(_vm._s(data.item.status))])];
      }
    }, {
      key: "cell(value)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "td-text"
        }, [_vm._v(_vm._s(_vm.setCurrency(data.item.contribution)))])];
      }
    }, {
      key: "custom-foot",
      fn: function fn(data) {
        return [_c('tr', [_c('td', {
          staticClass: "text-right",
          attrs: {
            "colspan": data.columns
          }
        }, [_c('sygni-link-button', {
          staticClass: "p-0 gn-secondary",
          attrs: {
            "arrowSide": "right"
          },
          on: {
            "click": _vm.goToTransactions
          }
        }, [_vm._v("Show more")])], 1)])];
      }
    }])
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1), _vm.tableItems.length > 0 ? _c('portfolio-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showSingleTransactionModal,
      expression: "showSingleTransactionModal"
    }],
    attrs: {
      "isLoading": _vm.isSingleTransactionModalLoading,
      "showNavigation": _vm.selectedDetails.length > 1,
      "transactionId": _vm.transactionId,
      "viewedFundId": _vm.viewedFundId
    },
    on: {
      "close": _vm.closeTransactionModal,
      "loaded": _vm.detailsModalLoaded,
      "next": function next($event) {
        return _vm.setIndexes(_vm.nextIndex);
      },
      "prev": function prev($event) {
        return _vm.setIndexes(_vm.prevIndex);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }