import {RouteConfig} from "vue-router";
// import {BACKEND_BASE_URL} from "@/shared/consts";
import store from "@/store/rootStore";
// import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing';

const statementModule = () => import('./containers/statementModule.vue');
const statementModuleNew = () => import('./containers/statementModuleNew.vue');
const statementItemModule = () => import('./containers/statementItemModule.vue');
const statementBlindModule = () => import('./containers/statementBlindModule.vue');

export const statementsRouting : Array<RouteConfig> = [
    {
        path: 'bank-statement',  /* /company || /fund */
        name: 'statementsList',
        component: statementModule,
        children: [],
        async beforeEnter(to, from, next) {
            let activeUserData = store.getters['genprox/activeUserData']
            if(!activeUserData) {
                activeUserData = await store.dispatch('genprox/getActiveUserData')
            }

            if (activeUserData?.role?.bankStatementRole) {
                next()
            } else {
                next(`${to.path}/access-denied`);
            }
        },
    },
    {
        path: 'bank-statement-new',  /* /company || /fund */
        name: 'statementsListNew',
        component: statementModuleNew,
        children: [],
        async beforeEnter(to, from, next) {
            let activeUserData = store.getters['genprox/activeUserData']
            if(!activeUserData) {
                activeUserData = await store.dispatch('genprox/getActiveUserData')
            }

            if (activeUserData?.role?.bankStatementRole) {
                next()
            } else {
                next(`${to.path}/access-denied`);
            }
        },
    },
    {
        path: 'bank-statement/access-denied',
        name: 'statement-access-denied',
        component: statementBlindModule,
    },
    {
        path: 'bank-statement/:id',
        name: 'statement-by-id',
        component: statementItemModule,
        children: [],
        async beforeEnter(to, from, next) {
            let activeUserData = store.getters['genprox/activeUserData']
            if(!activeUserData) {
                activeUserData = await store.dispatch('genprox/getActiveUserData')
            }

            if (activeUserData?.role?.bankStatementRole) {
                next()
            } else {
                next(`${to.path.substring(0, to.path.lastIndexOf('/'))}/access-denied`);
            }
        },
    },
];
