


























import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class UserPresentation extends Vue {
  get position() {
    return 'top right';
  }
}
