

import { ActionTree, GetterTree, Module, MutationTree} from "vuex";
import { RootState } from "@/store/types";
import { _axios as axios } from "@/plugins/axios";
import { BACKEND_API_URL } from "@/shared/consts";

import {CreateFundDTO, FundState} from "@/modules/genprox/modules/fund/store/types";

export const state: FundState = new FundState()


export const getters: GetterTree<FundState, RootState> = {

}

export const mutations: MutationTree<FundState> = {

}

export const actions: ActionTree<FundState, RootState> = {
    async createFund({commit}, payload: CreateFundDTO): Promise<string> {
        const { data: fundId } = await axios.post(`${BACKEND_API_URL}/crm/legal-entity/fund`, payload);
        return fundId;
    },
    async getKNFReportAgreement({commit}, packageId: string): Promise<string> {
        const { data } = await axios.get(`${BACKEND_API_URL}/package/agreement/${packageId}`);
        return data;
    }
}

export const fund: Module<FundState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
