
export const BACKEND_BASE_URL = process.env["VUE_APP_BACKEND"];
export const BACKEND_API_URL = process.env["VUE_APP_BACKEND_API"];

export enum UserTypes {
    privateInvestor = 'Private investor',
    privateFundManager = 'Private Fund Manager',
    startupFounder = 'Startup founder',
    fundManager = 'Fund manager',
}

export const ibanSupportedCountries = ["AD","AE","AT","AZ","BA","BE","BG","BH","BR","CH","CR","CY","CZ","DE","DK","DO","EE","ES","FI","FO","FR","GB","GI","GL","GR","GT","HR","HU","IE","IL","IS","IT","JO","KW","KZ","LB","LI","LT","LU","LV","MC","MD","ME","MK","MR","MT","MU","NL","NO","PK","PL","PS","PT","QA","RO","RS","SA","SE","SI","SK","SM","TN","TR","AL","BY","EG","GE","IQ","LC","SC","ST","SV","TL","UA","VA","VG","XK"];

export const globalCurrencies = ["EUR","GBP","USD","CHF","SEK","NOK","CAD","AUD","NZD","HRK","BGN","BYN","CZK","DKK","EEK","HUF","JPY","LTL","SCP","EGP","AED","AFN","ALL","AMD","ANG","AON","ARS","AWG","AZM","BAM","BBD","BDT","BHD","BIF","BND","BOB","BRL","BSD","BTN","BWP","BZD","CDF","CLP","CNY","COP","CRC","CUP","CVE","DJF","DOP","DZD","ERN","ETB","FJD","GEL","GHC","GIP","GMD","GNF","GTQ","GYD","HKD","HNL","HTG","IDR","ILS","INR","IQD","IRR","ISK","JMD","JOD","KES","KGS","KHR","KMF","KRW","KWD","KZT","LAK","LBP","LKR","LRD","LSL","LVL","LYD","MAD","MDL","MGA","MKD","MMK","MNT","MOP","MRO","MUR","MVR","MWK","MXN","MYR","MZN","NAD","NGN","NIO","NPR","OMR","PAB","PEN","PGK","PHP","PKR","PLN","PYG","QAR","RON","RSD","RUB","RWF","SAR","SBD","SCR","SDD","SGD","SLL","SOS","SRG","STD","SVC","SYP","SZL","THB","TJS","TMM","TND","TOP","TRY","TTD","TWD","TZS","UAH","UGX","UYU","UZS","VEB","VND","VUV","WN","WST","XAF","XCD","XOF","XPF","YER","ZAR","ZMK","ZWD"];

export const usedCurrencies = [
    { label: 'PLN', value: 'PLN' },
    { label: 'EUR', value: 'EUR' },
    { label: 'USD', value: 'USD' },
    { label: 'GBP', value: 'GBP' },
];

export const periodTypes = [
    { label: 'Month', value: 'month' },
    { label: 'Quarter', value: 'quarter' },
    { label: 'Semi-Annual', value: 'semiannual' },
    { label: 'Year', value: 'year' },
    { label: 'Day', value: 'day' },
]

export const periodTypeOptions: any = {
    month: [
        { label: '01', value: 'XXXX-01-01 00:00:00' },
        { label: '02', value: 'XXXX-02-01 00:00:00' },
        { label: '03', value: 'XXXX-03-01 00:00:00' },
        { label: '04', value: 'XXXX-04-01 00:00:00' },
        { label: '05', value: 'XXXX-05-01 00:00:00' },
        { label: '06', value: 'XXXX-06-01 00:00:00' },
        { label: '07', value: 'XXXX-07-01 00:00:00' },
        { label: '08', value: 'XXXX-08-01 00:00:00' },
        { label: '09', value: 'XXXX-09-01 00:00:00' },
        { label: '10', value: 'XXXX-10-01 00:00:00' },
        { label: '11', value: 'XXXX-11-01 00:00:00' },
        { label: '12', value: 'XXXX-12-01 00:00:00' },
    ],
    quarter: [
        { label: '01', value: 'XXXX-01-01 00:00:00' },
        { label: '02', value: 'XXXX-04-01 00:00:00' },
        { label: '03', value: 'XXXX-07-01 00:00:00' },
        { label: '04', value: 'XXXX-10-01 00:00:00' },
    ],
    semiannual: [
        { label: '01', value: 'XXXX-01-01 00:00:00' },
        { label: '02', value: 'XXXX-07-01 00:00:00' },
    ],
    year: [],
    day: [],
}
