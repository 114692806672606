var render = function () {
  var _vm$$slots, _vm$$slots2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "droparea",
    class: ['sygni-droparea', _vm.disabled ? 'sygni-droparea--disabled' : ''],
    on: {
      "click": _vm.handleClick,
      "dragover": _vm.preventDragOver,
      "dragleave": _vm.onDragLeave,
      "drop": _vm.handleDropFile
    }
  }, [!_vm.multiple && _vm.preview && _vm.previewUrl ? _c('div', {
    staticClass: "sygni-droparea__preview"
  }, [_c('img', {
    attrs: {
      "src": _vm.previewUrl
    }
  }), !_vm.disabled ? _c('div', {
    staticClass: "sygni-droparea__close",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.removePreview.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('X'),
      "alt": "Close"
    }
  })]) : _vm._e()]) : _vm._e(), _c('input', {
    ref: "dropareaInput",
    attrs: {
      "type": "file",
      "hidden": "",
      "multiple": _vm.multiple
    },
    on: {
      "change": _vm.handleInputChange
    }
  }), !_vm.previewUrl ? _c('div', {
    staticClass: "sygni-droparea__content"
  }, [!((_vm$$slots = _vm.$slots) !== null && _vm$$slots !== void 0 && _vm$$slots.default) ? _c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/icons/docs.svg")
    }
  }) : _vm._e(), !_vm.isLoading ? _c('span', {
    class: ['text', (_vm$$slots2 = _vm.$slots) !== null && _vm$$slots2 !== void 0 && _vm$$slots2.default ? 'centered' : '']
  }, [_vm._t("default", function () {
    return [_vm._v(" " + _vm._s(_vm.dropAreaText) + " ")];
  })], 2) : _c('span', {
    staticClass: "text"
  }, [_vm._v("Loading ...")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }