export enum Statues {
    new = 'NEW',
    active = 'ACTIVE',
    lead = 'LEAD',
    rejected = 'REJECTED',
    invited = 'INVITED',
    declined = 'DECLINED',
    awaiting = 'AWAITING',
    pending = 'PENDING',
    closed = 'CLOSED'
}
