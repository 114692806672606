var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('notifications', {
    class: ['notification-container', _vm.$route.name === 'accounting-by-id' ? 'move-top' : ''],
    attrs: {
      "position": _vm.position
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(props) {
        return [_c('div', {
          class: 'notification ' + props.item.type
        }, [props.item.type !== 'warn' ? _c('div', {
          staticClass: "icon"
        }, [props.item.type === 'success' ? _c('img', {
          attrs: {
            "src": require("@/assets/images/icon-ok.png")
          }
        }) : _vm._e()]) : _vm._e(), _c('div', {
          staticClass: "text"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(props.item.title) + " ")]), _c('div', {
          staticClass: "close",
          on: {
            "click": props.close
          }
        }, [_c('i', {
          staticClass: "fa fa-fw fa-close"
        })]), _c('div', {
          staticClass: "description",
          domProps: {
            "innerHTML": _vm._s(props.item.text)
          }
        })]), _c('div', {
          staticClass: "bottom-line"
        })])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }