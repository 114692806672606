export class TableQuery {
  limit: number;
  offset: number;

  sorting?: Sorting;

  filters: string;

  constructor(limit?: number, offset?: number, sorting?: Sorting, filters?: string) {
    this.limit = limit ?? 10;
    this.offset = offset ?? 0;
    this.sorting = sorting;
    this.filters = filters ?? '';
  }

  setQuery(tableQuery: TableQuery): TableQuery{
    this.limit = tableQuery.limit;
    this.filters = tableQuery.filters;
    this.sorting = tableQuery.sorting;
    this.offset = tableQuery.offset;
    return this;
  }

  getStringQuery(offsetQuery: boolean = true){
    let stringQuery = offsetQuery ? '?' : '';
    if(offsetQuery) stringQuery += this.createLimitAndOffsetQuery();
    stringQuery += this.createSortingStringQuery();
    stringQuery += this.createFilterStringQuery();
    if(stringQuery[stringQuery.length - 1] === '&'){
      stringQuery = stringQuery.slice(0, stringQuery.length - 1);
    }

    return stringQuery;
  }

  private createLimitAndOffsetQuery(): string {
    let stringQuery = '';
    if(this.limit !== null && this.offset !== null) {
      stringQuery +=  `limit=${this.limit}&offset=${this.offset}`;
      stringQuery += '&';
    }
    return stringQuery;
  }

  private createSortingStringQuery(): string {
    let stringQuery = '';
    if(!this.sorting) return stringQuery;
    if(this.sorting.order !== null && this.sorting.name !== null){
      stringQuery += `sort[]=${this.sorting.order}${this.sorting.name}`;
      stringQuery += '&'
    }
    return stringQuery;
  }

  private createFilterStringQuery(): string {
    // TODO: Implement this ASAP

    // let stringQuery = '';
    // this.filters.forEach( (filter) => {
    //   stringQuery += filter;
    // })
    // return stringQuery;

    return this.filters;
  }
}

export interface Sorting {
  name: string;
  order: '-' | '';
}
