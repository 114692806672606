
import {SelectOption} from "@/store/types";

export class DictionaryState {
    investorSelects: InvestorSelects;
    addProductsSelects: AddProductSelects;
    globalSelects: GlobalSelects;
    AIFMSelects: AIFMSelects;
    legalEntitySelects: LegaLEntitySelects;

    constructor() {
        this.investorSelects = {
            investmentPotentials:[],
            documentType: [],
            investmentKnowledge: [],
            country: [],
            industries: [],
            sources: [],
            title: [{label:'Mr.', value:'Mr.'}, {label: 'Mrs.', value:'Mrs.'}]
        };
        this.addProductsSelects = {
            productType: [],
            duration: [],
            interestType: [],
            interestPayment: [],
            commisionPayment: [],
            marginPayment: [],
            interestCalculationMethod: [],
            class: [],
            source: [],
        };
        this.globalSelects = {
            countries: [],
            brokerPermissions: [],
        };
        this.AIFMSelects = {
            categories: [],
        }
        this.legalEntitySelects = {
            legalForm: [],
            endOfFinancialPeriod: [],
            programme: []
        }
    }
}

export interface AIFMSelects {
    categories: Array<SelectOption<string>>,
}

export interface InvestorSelects {
    investmentPotentials?: Array<SelectOption<string>>,
    investmentKnowledge?:  Array<SelectOption<string>>,
    title?:  Array<SelectOption<string>>,
    documentType?:  Array<SelectOption<string>>,
    country?:  Array<SelectOption<string>>,
    industries?: Array<SelectOption<string>>,
    sources?: Array<SelectOption<string>>,
}

export interface AddProductSelects {
    productType?: Array<SelectOption<string>>,
    duration?: Array<SelectOption<string>>,
    interestType?: Array<SelectOption<string>>,
    interestPayment?: Array<SelectOption<string>>,
    commisionPayment?: Array<SelectOption<string>>,
    marginPayment?: Array<SelectOption<string>>,
    interestCalculationMethod?: Array<SelectOption<string>>,
    class?: Array<SelectOption<string>>,
    source?: Array<SelectOption<string>>,
}

export interface GlobalSelects {
    countries: Array<SelectOption<string>>;
    brokerPermissions: Array<SelectOption<string>>;
}

export interface LegaLEntitySelects {
    legalForm: Array<SelectOption<string>>;
    programme: Array<SelectOption<string>>;
    endOfFinancialPeriod: Array<SelectOption<string>>;
}
