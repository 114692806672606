var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dropdown",
    on: {
      "click": function click($event) {
        return _vm.toggleDropdown();
      }
    }
  }, [_c('div', {
    staticClass: "inner-content",
    class: _vm.open ? 'inner-content--active' : ''
  }, [_c('user-presentation', {
    attrs: {
      "name": _vm.activeItem.text,
      "description": _vm.activeItem.subtext,
      "image": _vm.activeItem.image
    }
  }), _c('div', {
    staticClass: "inner-content__arrow"
  }, [_c('sygni-arrow-button', {
    attrs: {
      "open": _vm.open,
      "rotate-on-click": true
    }
  })], 1)], 1), _c('ExpandCollapse', {
    attrs: {
      "height": _vm.listHeight,
      "before-expand": _vm.beforeExpandAnimation
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open,
      expression: "open"
    }],
    staticClass: "drop-content-container"
  }, [_vm._l(_vm.items, function (item, index) {
    return [_vm.activeItem !== item ? _c('sygni-dropdown-item', {
      key: index,
      attrs: {
        "item": item,
        "arrow": false
      },
      on: {
        "click": function click($event) {
          return _vm.setActiveItem(item);
        }
      }
    }) : _vm._e()];
  }), _vm._t("footer")], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }