





















import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit} from "vue-property-decorator";

@Component
export default class SygniSquareButton extends Vue {
  @Emit('click') click(){
    return true;
  }
}
