

import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { BACKEND_API_URL } from "@/shared/consts";
import { _axios as axios } from "@/plugins/axios";

export interface ReportingState {
    reportCommon: any,
    report: any,
}

export const state: ReportingState = {
    reportCommon: null,
    report: null,
}

const buildAdditionalQuery = (payload: any) => {
    let additionalQuery = '?';

    if(payload.periodType || payload.rangeType) {
        if(payload.periodType) {
            additionalQuery += `periodType=${payload.periodType}&`;
        }

        if(payload.rangeType) {
            additionalQuery += `rangeType=${payload.rangeType}`;
        } else {
            additionalQuery = additionalQuery.slice(0, -1);
        }
    } else {
        additionalQuery = '';
    }

    return additionalQuery;
}

export const getters: GetterTree<ReportingState, RootState> = {
    reportCommon(state) {
        return state.reportCommon;
    },
    report(state) {
        return state.report;
    }
}

export const mutations: MutationTree<ReportingState> = {
    setReportCommon(state, payload) {
        state.reportCommon = payload;
    },
    setReport(state, payload) {
        state.report = payload;
    },
    setReportScenario(state, scenario: string) {
        state.report.scenario = scenario;
    },
    setReportPeriod(state, period: string) {
        state.report.periodType = period;
    },
    setReportRange(state, range: string) {
        state.report.rangeType = range;
    },
    setReportingTableRecordValue(state, payload: { id: string, value: any }) {
        let record: any = null;
        
        for (const section of state.report.sections.filter((section: any) => section.type === 'TABLE')) {
            if(record) break;
            
            for (const item of section.items) {
                const found = item.values.find((item: any) => item?.id === payload?.id);
                
                if(found) {
                    record = found;
                    break;
                }
            }
        }

        if(record) {
            record.value = payload.value;
        }
    },
    setReportingTextRecordValue(state, payload: { id: string, value: any }) {
        let record: any = null;
        
        for (const section of state.report.sections.filter((section: any) => section.type === 'ROW')) {
            if(record) break;
            
            for (const item of section.items.filter((item: any) => item.type === 'TEXT' || item.type === 'TEXTAREA')) {
                const found = item.values.find((item: any) => item?.id === payload?.id);
                
                if(found) {
                    record = found;
                    break;
                }
            }
        }

        if(record) {
            record.value = payload.value;
        }
    },
    setReportingRadioRecordValue(state, payload: { id: string, value: any }) {
        let record: any = null;
        
        for (const section of state.report.sections.filter((section: any) => section.type === 'ROW')) {
            if(record) break;
            
            for (const item of section.items.filter((item: any) => item.type === 'YESNO')) {
                const found = item.values.find((item: any) => item?.id === payload?.id);
                
                if(found) {
                    record = found;
                    break;
                }
            }
        }

        if(record) {
            record.value = payload.value;
        }
    },
    addReportingFiles(state, payload: { id: string, uploadedFiles: any[] }) {
        let record: any = null;

        for (const section of state.report.sections.filter((section: any) => section.type === 'ROW')) {
            if(record) break;

            for (const item of section.items.filter((item: any) => item.type === 'FILES')) {
                const found = item.values.find((item: any) => item.id === payload.id);

                if(found) {
                    record = found;
                    break;
                }
            }
        }

        if(record) {
            payload.uploadedFiles.forEach((file: any) => {
                if (record.value?.files) {
                    record.value?.files.push({
                        category: '',
                        ...file,
                    });
                } else {
                    record.value = {
                         files: [{
                            category: '',
                            ...file,
                        }]
                    }
                }
            });
        }
    },
    addPrevReport(state, payload) {
        const lastColIdx = state.report.globalHeaders.length - 1;

        state.report.globalHeaders.splice(lastColIdx, 1)
        state.report.globalHeaders.unshift(payload.globalHeaders[0]);
        state.report.sections.forEach((s: any, idx: any) => {
          // eslint-disable-next-line no-prototype-builtins
          if (state.report.sections[idx].hasOwnProperty('headers')) {
            state.report.sections[idx].headers.splice(lastColIdx, 1);
            state.report.sections[idx].headers.unshift(payload.sections[idx].headers[0]);
          }

          state.report.sections[idx].items.forEach((i: any, idxx: any) => {
            state.report.sections[idx].items[idxx].values.splice(lastColIdx, 1);
            state.report.sections[idx].items[idxx].values.unshift(payload.sections[idx].items[idxx].values[0]);
          });
        });

        state.report.dateFrom = payload.globalHeaders[0].date;
        state.report.dateTo = state.report.globalHeaders[lastColIdx].date;
        state.report.date = state.report.globalHeaders[lastColIdx].date;
    },
    addNextReport(state, payload) {
        state.report.globalHeaders.splice(0,1)
        state.report.globalHeaders.push(payload.globalHeaders[0]);
        state.report.sections.forEach((s: any, idx: any) => {

          // eslint-disable-next-line no-prototype-builtins
          if (state.report.sections[idx].hasOwnProperty('headers')) {
            state.report.sections[idx].headers.splice(0, 1);
            state.report.sections[idx].headers.push(payload.sections[idx].headers[0]);
          }

          state.report.sections[idx].items.forEach((i: any, idxx: any) => {
            state.report.sections[idx].items[idxx].values.splice(0, 1);
            state.report.sections[idx].items[idxx].values.push(payload.sections[idx].items[idxx].values[0]);
          })
        })

        state.report.dateFrom = state.report.globalHeaders[0].date;
        state.report.dateTo = payload.globalHeaders[0].date;
        state.report.date = payload.globalHeaders[0].date;
    }
}

export const actions: ActionTree<ReportingState, RootState> = {
    async getReportCommon({state,commit}, viewedContext: string): Promise<any> {
        // const queryData = await axios.get(`${BACKEND_API_URL}/capital/${payload.filtersQuery}`, { headers: {
        //     'x-total-count': true,
        //     'Viewed-Context': payload.viewedContext
        // } });

        const headers: any = {}

        if (viewedContext) {
            headers['Viewed-Context'] = viewedContext
        }

        const { data } = await axios.get(`${BACKEND_API_URL}/reporting/report`, { headers });

        commit('setReportCommon', data);

        return data;
    },
    async getStatutoryReports({state,commit}, viewedContext: string): Promise<any> {
        const headers: any = {}

        if (viewedContext) {
            headers['Viewed-Context'] = viewedContext
        }

        const { data } = await axios.get(`${BACKEND_API_URL}/reporting/statutory-report`, { headers })

        return data
    },
    async getStatutoryReport({state,commit}, payload: { id: string, viewedContext: string }) {
        const headers: any = {}

        if (payload?.viewedContext) {
            headers['Viewed-Context'] = payload?.viewedContext
        }

        const { data } = await axios.get(`${BACKEND_API_URL}/reporting/statutory-report/${payload.id}`, { headers })

        return data
    },
    async getReport({state, commit}, payload) {
        const additionalQuery = buildAdditionalQuery(payload);
        const editMode = payload?.editMode ? 'edit' : 'show';

        const headers: any = {}

        if (payload?.viewedContext) {
            headers['Viewed-Context'] = payload?.viewedContext
        }

        const { data } = await axios.get(`${BACKEND_API_URL}/reporting/${editMode}/${payload.reportingId}/${payload.dateFrom}/${payload.dateTo}/${payload.scenario}${additionalQuery}`, { headers });

        commit('setReport', data);

        return data;
    },
    async getOneReport({state, commit}, payload) {
        const additionalQuery = buildAdditionalQuery(payload);
        const editMode = payload?.editMode ? 'edit' : 'show';

        const headers: any = {}

        if (payload?.viewedContext) {
            headers['Viewed-Context'] = payload?.viewedContext
        }

        const { data } = await axios.get(`${BACKEND_API_URL}/reporting/${editMode}/${payload.reportingId}/${payload.date}/${payload.date}/${payload.scenario}${additionalQuery}`, { headers })
        
        return data;
    },
    async getNextReport({commit, dispatch}, payload) {
        const response = await dispatch('getOneReport', payload);
        commit('addNextReport', response);
    },
    async getPrevReport({commit, dispatch}, payload) {
        const response = await dispatch('getOneReport', payload);
        commit('addPrevReport', response);
    },
    async changePeriodType({state,commit}, payload) {
        const { data } = await axios.put(`${BACKEND_API_URL}/reporting/report/period-type/${payload.reportingId}/${payload.scenario}`, {
            periodType: payload.periodType
        });

        return data;
    },
    async changeRangeType({state,commit}, payload) {
        const { data } = await axios.put(`${BACKEND_API_URL}/reporting/report/range-type/${payload.reportingId}/${payload.scenario}`, {
            rangeType: payload.rangeType
        });

        return data;
    },
    async postSaveReport({state,commit}, payload) {
        const { data } = await axios.post(`${BACKEND_API_URL}/reporting/report/save/${payload.reportingId}/${payload.scenario}`, payload.data);

        return data;
    },
    async publishReport({state,commit}, payload) {
        const { data } = await axios.put(`${BACKEND_API_URL}/reporting/report/publish/${payload.reportingId}/${payload.dates}/${payload.scenario}`);

        return data;
    }
}

export const reporting: Module<ReportingState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
