


import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { BACKEND_API_URL } from "@/shared/consts";
import { _axios as axios } from "@/plugins/axios";
import { TableQuery } from "@/modules/shared/utils/TableQuery";
import { InsightItem, InsightsState } from "./types";


export const state: InsightsState = {
    insightsTable: {
        items: [],
        totalCount: 0,
        perPage: 5,
        query: new TableQuery(5),
    },
}

export const getters: GetterTree<any, RootState> = {
    getInsights(state) {
        return JSON.parse(JSON.stringify(state.insightsTable.items));
    },
    getInsightsTableFiltersQuery(state) {
        return JSON.parse(JSON.stringify(state.insightsTable.query.filters));
    },
    getInsightTableTotalCount(state) {
        return JSON.parse(JSON.stringify(state.insightsTable.totalCount));
    },
    getInsightsTablePerPage(state) {
        return JSON.parse(JSON.stringify(state.insightsTable.perPage));
    }
}

export const mutations: MutationTree<any> = {
    setInsights(state, payload: { items: Array<InsightItem>, totalCount: number | string }) {
        state.insightsTable.totalCount = payload.totalCount;
        state.insightsTable.items = payload.items;
    },
    setInsightsTableFiltersQuery(state, payload: string) {
        state.insightsTable.query.filters = payload;
    },
    setInsightTableOffsetQuery(state, offset: number) {
        state.insightsTable.query.offset = offset;
    }
}

export const actions: ActionTree<any, RootState> = {
    async getInsight({commit}, slug: string): Promise<any> {
        const response = await axios.get(`${BACKEND_API_URL}/insight/${slug}`);

        return response.data;
    },
    async getInsights({commit}, filtersQuery?: string): Promise<any> {
        const queryString = state.insightsTable.query.getStringQuery();
        filtersQuery = filtersQuery ? filtersQuery : '';
        const response = await axios.get(`${BACKEND_API_URL}/insight${queryString}${filtersQuery}`);

        const payload: { items: Array<InsightItem>, totalCount: number | string } = { items: response.data.items, totalCount: parseInt(response.headers['x-total-count']) }

        commit('setInsights', payload)
        
        return payload.items;
    },
    async getInsightNews({commit}): Promise<any> {
        // todo: add limit query and filter query
        const { data } = await axios.get(`${BACKEND_API_URL}/insight/news?limit=10&offset=0`);
        
        return data;
    },
    async getInsightRecentNews({commit}, payload: any): Promise<any> {
        // todo: add limit query and filter query
        const { data } = await axios.get(`${BACKEND_API_URL}/insight/resent-news?limit=10&offset=0&filters[authorId][0][value]=e3a1bacf-4cb4-4e1a-9b4b-aa0f83285497`);
        
        return data;
    },
    async getInsightRecentPosts({commit}, payload: any): Promise<any> {
        // todo: add limit query and filter query
        const { data } = await axios.get(`${BACKEND_API_URL}/insight/resent-posts?limit=10&offset=0`);
        
        return data;
    },
}

export const insights: Module<any, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
