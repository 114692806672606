
import Vue from "vue";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState, SelectOption} from "@/store/types";
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL} from "@/shared/consts";
import {
    Address, EditInvestmentClientPayload,
    emptyInvestmentClient, emptyLoanProduct, GetProduct,
    InvestmentClient,
    Investor,
    InvestorsState, NewProduct, Product
} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import {Query} from "@/shared/interfaces/Common";
import Utils from "@/modules/shared/utils/utils";
import {KPIs} from "@/modules/genprox/modules/profile/models/KPIs.interface";
import {TableQuery} from "@/modules/shared/utils/TableQuery";
import {GetInvestorProductResponse} from "@/modules/genprox/modules/fund/modules/fundraising/models/GetInvestorProductResponse.interface";
import {ProfileDataState, TableData} from "@/modules/genprox/modules/profile/store/types";
import {Transaction} from "@/modules/genprox/modules/profile/models/transaction.interface";
import _ from "lodash";

function buildInvestmentClientPayloadData(payload: InvestmentClient) {
    if (Array.isArray(payload?.consents)) {
        const consents: any = {}
    
        const privacy = payload.consents?.find((el: any) => el.type === 'privacyPolicyAndTermsAndConditions')
        const consent1 = payload.consents?.find((el: any) => el.type === 'consentToTheProcessingOfDataForMarketingPurposes')
        const consent2 = payload.consents?.find((el: any) => el.type === 'consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems')
    
        if (privacy) {
          consents.privacyPolicyAndTermsAndConditions = privacy?.value
        }
    
        if (consent1) {
          consents.consentToTheProcessingOfDataForMarketingPurposes = consent1?.value
        }
    
        if (consent2) {
          consents.consentToTheProcessingOfDataUsingTelecommunicationsTerminalEquipmentAndAutomaticCallingSystems = consent2?.value
        }
    
        payload.consents = consents
    }

    if (!payload?.details?.documentExpiryDate?.expiryDate && !payload?.details?.documentExpiryDate?.indefinitely) {
        payload.details.documentExpiryDate = null
    }

    
    return payload
}

export const state: InvestorsState = {
    investors: null,
    investorsTableBusy: false,
    investorsTablePerPage: 10,
    investorsLength: 0,
    investorsQuery: { limit: 'limit=10&offset=0' },
    productsQuery: '',
    productsQueryNEW: new TableQuery(10,0),
    transactionsHistoryQuery: new TableQuery(10, 0),
    newInvestmentClient: emptyInvestmentClient,
    editInvestmentClientData: null,
    activeProduct: emptyLoanProduct,
    annexingProducts: [],
    taxOfficeCodes: [],
    propagate: false,
    autentiStatusData: null,
}

export const getters: GetterTree<InvestorsState, RootState> = {
    investors(state): Array<Investor> | null {
        return state.investors
    },
    newInvestmentClient(state): InvestmentClient {
        return state.newInvestmentClient
    },
    newProduct(state): Product {
        return JSON.parse(JSON.stringify(state.activeProduct));
    },
    annexingProducts(state) {
        return state.annexingProducts
    },
    taxOfficeCodes(state) {
        return state.taxOfficeCodes
    },
    propagate(state) {
        return state.propagate
    },
    getInvestorsQuery() {
        return state.investorsQuery
    },
    getAutentiStatusData() {
        return state.autentiStatusData
    }
}

export const mutations: MutationTree<InvestorsState> = {
    setPropagate(state, value: boolean) {
        state.propagate = value
    },
    setInvestors(state, payload: Array<Investor>) : void {
        state.investors = payload;
    },
    setNewInvestmentClient(state, payload: InvestmentClient): void {
        state.newInvestmentClient = {...state.newInvestmentClient,  ...payload};
    },
    setNewInvestor(state, payload): void {
        state.newInvestmentClient = payload;
    },
    setNewProduct(state, payload: NewProduct): void {
        const investmentClientId = state.activeProduct.investmentClientId;
        const investorLegalEntityId = state.activeProduct.investorLegalEntityId;
        const annexPayload: { type: 'annex' | 'rollover', ids: string[] } = {
            type: state.activeProduct?.annexType,
            ids: state.activeProduct?.annexSourceReferenceIds,
        }

        delete state.activeProduct?.annexType
        delete state.activeProduct?.annexSourceReferenceIds

        Vue.set(state, 'activeProduct', payload)

        state.activeProduct.investorLegalEntityId = investorLegalEntityId || null;
        state.activeProduct.investmentClientId = investmentClientId || null;

        if(annexPayload.type && annexPayload.ids?.length) {
            state.activeProduct.annexType = annexPayload.type
            state.activeProduct.annexSourceReferenceIds = annexPayload.ids
        }

    },
    setNewProductSubscriptionId(state, id): void {
        state.activeProduct.subscriptionId = id
    },
    setNewProductInvestor(state, payload): void {
        state.activeProduct.investmentClientId = payload;
        state.activeProduct.investorLegalEntityId = null
    },
    setNewProductLe(state, payload): void {
        state.activeProduct.investmentClientId = null
        state.activeProduct.investorLegalEntityId = payload
    },
    setNewProductAnnexData(state, payload: { type: 'annex' | 'rollover', ids: string[] }) {
        Vue.set(state.activeProduct, 'annexType', payload.type)
        Vue.set(state.activeProduct, 'annexSourceReferenceIds', payload.ids)
        // state.activeProduct.annexType = payload.type
        // state.activeProduct.annexSourceReferenceIds = payload.ids
    },
    setAnnexingProducts(state, payload: any[]) {
        state.annexingProducts = payload;
    },
    clearAnnexingData(state) {
        delete state.activeProduct?.annexType
        state.activeProduct.annexSourceReferenceIds = []
        state.annexingProducts = []
    },
    setInvestorsQuery(state, payload: Query): void {
        if(payload.filters){
            state.investorsQuery = {...state.investorsQuery,...{limit: 'limit=10&offset=0'}, ...payload};
        } else {
            state.investorsQuery = {...state.investorsQuery, ...payload};
        }
    },
    setInvestorsLength(state, payload: number): void {
        state.investorsLength = payload;
    },
    setProductsQuery(state, payload: string): void {
        state.productsQuery = payload;
    },
    setProductsQueryNEW(state, payload: TableQuery): void {
        state.productsQueryNEW = payload;
    },
    setGetTransactionsQuery(state, payload: TableQuery): void {
        state.transactionsHistoryQuery = payload;
    },
    setInvestorsTableBusy(state,payload: boolean): void {
        state.investorsTableBusy = payload;
    },
    setInvestorsTablePerPage(state, perPage: number) {
        state.investorsTablePerPage = perPage
    },
    setInvestmentClientToEdit(state, payload: InvestmentClient): void {
        state.editInvestmentClientData = payload;
    },
    setTaxOfficeCodes(state, payload: any) {
        payload = payload.map((el: any) => {
            el.value = String(el.value)
            return el
        })
        state.taxOfficeCodes = payload
    },
    setAutentiStatusData(state, payload: any) {
        state.autentiStatusData = payload
    }
}

export const actions: ActionTree<InvestorsState, RootState> = {
    async getInvestors({state, commit}): Promise<Array<any>> {
        const response = await
            axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client${Utils.parseGetItemsQuery(state.investorsQuery)}`, {
                headers: { 'x-total-count': true}
            });
        commit('setInvestors', response.data.items);
        commit('setInvestorsLength', response.headers['x-total-count']);
        return response.data.items;
    },
    async getAllInvestors({state, commit}): Promise<Array<any>> {
        const response = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client?limit=999999&offset=0`)
        return response?.data?.items || []
    },
    async getLegalEntitiesList({state, commit}) {
        const response = await axios.get(`${BACKEND_API_URL}/crm/legal-entity/list`)

        return response?.data
    },
    async getInvestorProducts({state,commit}, id: string): Promise<any> {
        const productsQuery: string = _.cloneDeep(state.productsQuery)?.replaceAll('productInterestPeriodTo', 'interestPeriodTo')?.replaceAll('productPaymentDate', 'paymentDate')

        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client/${id}/products${productsQuery ? `${productsQuery}&` : '?'}limit=99999`);
        return data;
    },
    async getInvestorProductsNEW({state,commit}, id: string): Promise<GetInvestorProductResponse> {
        const queryString = state.productsQueryNEW.getStringQuery();
        const response = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client/${id}/products${queryString}`,
          { headers: { 'x-total-count': true} });
        const payload: GetInvestorProductResponse = { items: response.data, totalCount: response.headers['x-total-count'] }
        return payload;
    },
    async getInvestorKeyKPIs({state, commit}, id: string): Promise<KPIs> {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client/${id}/key-kpis`);
        return data;
    },
    async getInvestorTransactionHistory({state, commit}, id: string): Promise<any> {
        const queryString = state.transactionsHistoryQuery.getStringQuery();
      const response = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client/${id}/transaction-history${queryString}`,
        { headers: { 'x-total-count': true} });
      const data: TableData<Transaction> = { items: response.data, totalCount: response.headers['x-total-count']};
      return data;
      },
    async getInvestorProfile({state, commit}, id: string): Promise<ProfileDataState> {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client/${id}`);
        return data;
    },
    async addNewInvestmentClient({state, commit}, payload): Promise<any> {
        payload = buildInvestmentClientPayloadData(payload)
        const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client`,payload);
        commit('setNewInvestor', {...state.newInvestmentClient , id:data});
        return data;
    },
    async editInvestmentClient({state, commit}, payload: EditInvestmentClientPayload): Promise<any> {
        payload.data = buildInvestmentClientPayloadData(payload.data)
        const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client/${payload.id}`, payload.data);
        return data;
    },
    async editInvestmentClientAndPropagate({state, commit}, payload: EditInvestmentClientPayload): Promise<any> {
        payload.data = buildInvestmentClientPayloadData(payload.data)
        const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client/${payload.id}/propagate`, payload.data);
        return data;
    },
    async authorizeInvestmentClient({state, commit},payload): Promise<any> {
        return axios.put(`${BACKEND_API_URL}/profile/authorize-data`, payload);
    },
    async addNewProduct({state,commit}, payload: Product): Promise<void> {
        payload.code = (payload?.autogeneratedCode) ? null : payload.code;
        const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/product`, payload);

        return data;
    },
    async editProduct({state,commit}, payload: { productId: string, data: Product }): Promise<void> {
        const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}`, payload.data);

        return data;
    },
    async saveProductRateInfo({state,commit}, payload: { productId: string, data: { rate: number, rateDate: string } }): Promise<void> {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/rate`, payload.data);

        return data;
    },
    async acceptProductByClient({state,commit}, payload): Promise<any> {
        return axios.put(`${BACKEND_API_URL}/marketplace/investor/product/${payload.productId}/accept`, payload.data);
    },
    async acceptProductByLegalEntity({state,commit}, payload): Promise<any> {
        return axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload}/accept`);
    },
    async declineProductByLegalEntity({state, commit}, productId: string): Promise<any> {
        return axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/decline`);
    },
    async sendToInvestorForAcceptation({state, commit}, productId: string): Promise<any> {
        return axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/sent-to-investor-for-acceptation`);
    },
    async rejectProduct({state,commit}, id: string): Promise<any> {
        return axios.patch(`${BACKEND_API_URL}/marketplace/investor/product/${id}/reject`);
    },
    async getInvestmentClientAddress({state, commit}, clientId: string): Promise<Address> {
        const {data} = await axios.get<Address & {name: string}>(
            `${BACKEND_API_URL}/marketplace/legal-entity/investment-client/${clientId}/address`);
        return data;
    },
    async getProduct({state,commit}, product: GetProduct): Promise<any> {
        if(product.type === 'for-investment-client'){
            return axios.get<Address & {name: string}>(
              `${BACKEND_API_URL}/marketplace/investor/product/${product.id}`);
        } else if (product.type === 'for-legal-entity'){
            return axios.get<Address & {name: string}>(
              `${BACKEND_API_URL}/marketplace/legal-entity/product/${product.id}`);
        }
    },
    async getProductStatuses({state, commit}, id: string) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/product/${id}/status`)

        commit('setAutentiStatusData', data)

        return data
    },
    async markAsSentToInvestor({state, commit}, productId: string) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/agreement/send-to-investor`)

        return data
    },
    async markAsNotSentToInvestor({state, commit}, productId: string) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/agreement/unsend-to-investor`)

        return data
    },
    async getProductPayments({state,commit}, productId: string) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/payment-history`);

        return data
    },
    async getProductForAccept({state, commit}, id = ''){
        const textId = id ? '/'+id : '';
        return axios.get<Address & {name: string}>(
            `${BACKEND_API_URL}/marketplace/investor/product/awaiting-by-logged-user${textId}`);
    },
    async getDataForAuthorize({state,commit}, productId: string): Promise<InvestmentClient> {
        const {data} = await axios.get<any>( // nie da sie dodac typu jak kazdy endpoint zwraca te same dane w inny sposob...
            `${BACKEND_API_URL}/marketplace/investor/product/${productId}/data-for-accept`);
        const legitData: InvestmentClient = { // wiec trzeba je przeparsowac na jakies normalne dane.
            firstName: data.name,
            surname: data.surname,
            email: data.email,
            isMailingAddressSameResidential: data.mailingAddressSameResidential,
            details: {
                title: data.title,
                middleName: data.middleName,
                birthDate: data.birthdate,
                fathersName: data.fathersName,
                mothersName: data.mothersName,
                phone: data.phone,
                identificationNumber: data.identificationNumber,
                documentType: data.documentType,
                documentNumber: data.documentNumber,
                documentExpiryDate: data.documentExpiryDate,
                documentCountry: data.documentCountry,
                country: data.country
            },
            mailingAddress: {
                city: data.mailingAddressCity,
                country: data.mailingAddressCountry,
                street: data.mailingAddressStreet,
                houseNumber: data.mailingAddressHouseNumber,
                flatNumber: data.mailingAddressFlatNumber,
                zipCode: data.mailingAddressZipCode,
            },
            residentialAddress: {
                city: data.residentialAddressCity,
                country: data.residentialAddressCountry,
                street: data.residentialAddressStreet,
                houseNumber: data.residentialAddressHouseNumber,
                flatNumber: data.residentialAddressFlatNumber,
                zipCode: data.residentialAddressZipCode,
            }
        }
        return legitData;
    },
    async getProductHistoryFromInvestor({state, commit}, productId: string){
        const {data} = await axios.get<any>(`${BACKEND_API_URL}/marketplace/investor/product/${productId}/history`);
        return data;
    },
    async getProductHistoryFromLegalEntity({state, commit}, productId: string){
        const {data} = await axios.get<any>(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/history`);
        return data;
    },
    async saveAgreement({state, commit}, payload: { productId: string, data: any }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/agreement`, payload.data);
        return data;
    },
    async saveAgreementData({state, commit}, payload: { productId: string, data: any }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/agreement-data`, { agreementData: payload.data });
        return data;
    },
    async finishProduct({state, commit}, productId: string) {
        await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/finish`);
    },
    async changeOffersOwner({state, commit}, payload: { investmentClientId: string, ownerId: string }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client/${payload.investmentClientId}/owner/${payload.ownerId}`)

        return data
    },
    async getImpersonateInvestorToken({state}, investmentClientId: string) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/investment-client/impersonate/${investmentClientId}`)

        return data
    },
    async getProductTaxOfficeCodes({ state, commit }) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/product/tax-office-codes`)

        commit('setTaxOfficeCodes', data)
        
        return data
    },
    async generateClatTaxDeclaration({ state, commit }, payload: { taxOfficeCode: string, productIds: string[], correction: boolean }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/product/clat-tax-statement/generate`, payload)

        return data
    },
    async getClatTaxDeclarations({ state, commit }, productId: string) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/clat-tax-statement`)

        return data
    },
    async getPaymentConfirmations({ state, commit }, productId: string) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/payment-confirmation`)

        return data
    },
    async uploadPaymentConfirmationFile({ state, commit }, payload: { productId: string, fileId: string }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/payment-confirmation`, { fileId: payload.fileId })

        return data
    },
    async deletePaymentConfirmation({ state, commit }, payload: { productId: string, paymentConfirmationId: string }) {
        const { data } = await axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/payment-confirmation/${payload.paymentConfirmationId}`)
        
        return data
    },
    async autentiSendProduct({state}, payload: { productId: string, data: { fileToSignIds: string[] } }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/product/${payload?.productId}/send`, payload?.data)

        return data
    },
    async autentiWithdrawProduct({state}, id: string) {
        const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/product/${id}/withdraw`)

        return data
    }
}



export const investors: Module<InvestorsState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

