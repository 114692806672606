































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import {Prop} from "vue-property-decorator";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import {LegalEntity} from "@/modules/genprox/models/User";
import moment from 'moment';

@Component({
  components: {ExpandCollapse, SygniCard},
})
export default class FundInformationCard extends Vue {
  @Prop() legalEntity!: LegalEntity;
  @Prop({default: false}) company!: boolean;
  showMore: boolean = false;
  additionalCustom: any = {};

  get legalEntityDataToShow(): LegalEntity {
    return {
      registerNumber: this.legalEntity?.registerNumber,
      statisticalNumber: this.legalEntity?.statisticalNumber,
      taxNumber: this.legalEntity?.taxNumber,
    };
  }

  get additionalLegalEntityDataToShow(): LegalEntity & any {
    return {
      email: this.legalEntity?.email,
      city: this.legalEntity?.address.city,
      country: this.legalEntity?.address.country,
      flatNumber: this.legalEntity?.address.flatNumber,
      houseNumber: this.legalEntity?.address.houseNumber,
      street: this.legalEntity?.address.street,
      zipCode: this.legalEntity?.address.zipCode,
      establishmentDate: this.legalEntity?.establishmentDate ? moment(this.legalEntity?.establishmentDate).format('YYYY-MM-DD') : '',
    };
  }

  get viewedId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  async beforeMount() {
    if (this.company) {
      const companyData = await this.$store.dispatch('genprox/getCompanyData', this.viewedId);
      if (companyData) {
        this.additionalCustom = companyData;
      }
    }
  }

  mounted() {
  }

  afterAdditionalInformationExpand(el: HTMLDivElement): any {
    el.style.overflow = 'visible';
  }
}
