

import {ActionTree, GetterTree, Module, MutationTree, Store} from "vuex";
import {RootState, SelectOption} from "@/store/types";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";
import { TableData } from "@/modules/genprox/modules/profile/store/types";
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL, BACKEND_BASE_URL} from "@/shared/consts";
import { StringBoolMap } from "portal-vue/types/lib/types";
import { Attendant, CaseItem, CategoryItem, LegalEntityData } from './types';
import { UploadFileDTO } from "@/modules/genprox/models/Shared";

export interface WhistleBlowerState {
    casesTableIsBusy: boolean,
    casesTable: TableData<CaseItem>,
    attendants: Array<Attendant>,
    attendant: any,
    legalEntityData?: LegalEntityData,
    categories: Array<any>,
    settingCategories: Array<any>,
    caseStatusOptions: Array<SelectOption<string>>,
}

export const state: WhistleBlowerState = {
    casesTableIsBusy: false,
    casesTable: {
        items: [],
        totalCount: 0,
        perPage: 10,
        query: new TableQuery(10),
    },
    attendants: [],
    attendant: {},
    legalEntityData: null,
    categories: [],
    settingCategories: [],
    caseStatusOptions: [
        {label: 'New', value: 'new'},
        {label: 'Investigated', value: 'investigated'},
        {label: 'Awaiting', value: 'awaiting'},
        {label: 'Closed', value: 'closed'},
        {label: 'Spam', value: 'spam'},
    ]
}

export const getters: GetterTree<WhistleBlowerState, RootState> = {
    getCasesTableItems(state) {
        return JSON.parse(JSON.stringify(state.casesTable.items));
    },
    getCasesTableQuery(state) {
        return JSON.parse(JSON.stringify(state.casesTable.query));
    },
    getCasesTablePerPage(state) {
        return JSON.parse(JSON.stringify(state.casesTable.perPage));
    },
    getCasesTableFiltersQuery(state) {
        return JSON.parse(JSON.stringify(state.casesTable.query.filters));
    },
    getAttendants(state) {
        return JSON.parse(JSON.stringify(state.attendants));
    },
    getLegalEntityData(state) {
        return JSON.parse(JSON.stringify(state.legalEntityData));
    },
    getCategories(state) {
        return JSON.parse(JSON.stringify(state.categories));
    },
    getSettingCategories(state) {
        return JSON.parse(JSON.stringify(state.settingCategories));
    },
    getStatuses(state) {
        return JSON.parse(JSON.stringify(state.caseStatusOptions));
    }
}

export const mutations: MutationTree<WhistleBlowerState> = {
    setCases(state, payload: {items: Array<any>, totalCount: number}) {
        state.casesTable = { ...state.casesTable, items: payload.items, totalCount: payload.totalCount};
    },
    setAttendants(state, payload: Array<Attendant>) {
        state.attendants = payload;
    },
    setAttendant(state, payload: Attendant) {
        state.attendant = payload;
    },
    setLegalEntityData(state, payload: any) {
        state.legalEntityData = payload;
    },
    setCategories(state, payload: Array<CategoryItem>) {
        state.categories = payload;
    },
    setSettingCategories(state, payload: Array<CategoryItem>) {
        state.settingCategories = payload;
    },
    setCasesTableQuery(state, payload: TableQuery): void {
        state.casesTable.query = payload;
    },
    setCasesTableSortingQuery(state, payload: Sorting): void {
        state.casesTable.query.sorting = payload;
        state.casesTable.query.offset = 0;
    },
    setCasesTableFiltersQuery(state, payload: string) {
        state.casesTable.query.filters = payload;
    },
    setCasesTableBusy(state,payload: boolean): void {
        state.casesTableIsBusy = payload;
    },
    setCasesTablePerPage(state, payload: number): void {
        state.casesTable.perPage = payload;
    },
}

export const actions: ActionTree<WhistleBlowerState, RootState> = {
    async getCases({state, commit}, filtersQuery: string = ''): Promise<any> {
        const queryString = state.casesTable.query.getStringQuery();
        filtersQuery = filtersQuery ? filtersQuery : '';

        const response = await axios.get(`${BACKEND_API_URL}/whistleblower/case${queryString}${filtersQuery}`, { headers: { 'x-total-count': true } });

        commit('setCases', {
            items: response.data.items,
            totalCount: response.headers['x-total-count']
        });
        
        return response.data;
    },
    async getCase({state, commit}, caseId: string = ''): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/whistleblower/case/${caseId}`);
        return data;
    },
    async createCase({state, commit}, payload): Promise<any> {
        const { data } = await axios.post(`${BACKEND_API_URL}/whistleblower/case`, payload);

        return data;
    },
    async getAttendants({state, commit}, payload): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/whistleblower/settings/attendant/candidates`);

        commit('setAttendants', data);
        
        return data;
    },
    async getAttendant({state, commit}, payload): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/whistleblower/settings/attendant`);

        commit('setAttendant', data);
        
        return data;
    },
    async getLegalEntityData({state, commit}, payload): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/whistleblower/settings/legal-entity`);

        commit('setLegalEntityData', data);

        return data;
    },
    async editLegalEntity({state,commit}, payload) {
        const { data } = await axios.put(`${BACKEND_API_URL}/whistleblower/settings/legal-entity`, payload);

        return data;
    },
    async setAttendant({state,commit}, id: string) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/whistleblower/settings/attendant/${id}`);

        commit('setAttendant', data);

        return data;
    },
    async uploadLegalEntityLogo({commit}, payload: UploadFileDTO): Promise<any> {
        const formData = new FormData();
        formData.append('logo', payload.file);
        return axios.post(`${BACKEND_API_URL}/whistleblower/settings/legal-entity/logo`, formData, payload.config);
    },
    async deleteLegalEntityLogo({state,commit}) {
        const { data } = await axios.delete(`${BACKEND_API_URL}/whistleblower/settings/legal-entity/logo`);

        return data;
    },
    async getCategories({commit}) {
        const { data } = await axios.get(`${BACKEND_API_URL}/whistleblower/category`);

        commit('setCategories', data);

        return data;
    },
    async getSettingCategories({commit}) {
        const { data } = await axios.get(`${BACKEND_API_URL}/whistleblower/settings/category`);

        commit('setSettingCategories', data);

        return data;
    },
    async postCategory({commit}, name: string) {
        const { data } = await axios.post(`${BACKEND_API_URL}/whistleblower/settings/category`, {
            category: name
        });

        return data;
    },
    async deleteCategory({commit}, name: string) {
        const { data } = await axios.delete(`${BACKEND_API_URL}/whistleblower/settings/category/${name}`);

        return data;
    },
    async postComment({commit}, payload: { id: string, content: string }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/whistleblower/case/${payload.id}/comment`, {
            content: payload.content
        });

        return data;
    },
    async uploadCaseDocument({commit}, payload: { id: string, documentIds: Array<string> }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/whistleblower/case/${payload.id}/documents`, {
            documentIds: payload.documentIds
        });

        return data;
    },
    async setCaseStatus({commit}, payload: { id: string, status: string }) {
        const { data } = await axios.put(`${BACKEND_API_URL}/whistleblower/case/${payload.id}/status`, {
            status: payload.status
        })

        return data;
    },
    async markCommentsAsRead({commit}, caseId: string) {
        const { data } = await axios.put(`${BACKEND_API_URL}/whistleblower/case/${caseId}/mark-as-read`);

        return data;
    }
}

export const whistleblower: Module<WhistleBlowerState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
