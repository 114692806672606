

import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { BACKEND_API_URL } from "@/shared/consts";
import { _axios as axios } from "@/plugins/axios";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";

export interface CapitalState {
    capitalTableIsBusy: boolean,
    capitalTable?: any,
}

export const state: CapitalState = {
    capitalTableIsBusy: false,
    capitalTable: null,
}

export const getters: GetterTree<CapitalState, RootState> = {
    getCapitalTableData(state) {
        return JSON.parse(JSON.stringify(state.capitalTable));
    },
    getTotals(state) {
        return JSON.parse(JSON.stringify(state.capitalTable.totals))
    },
    getShareholderTotals(state) {
        return JSON.parse(JSON.stringify(state.capitalTable.shareHoldersTotals));
    },
    getOptionsStats(state) {
        return JSON.parse(JSON.stringify(state.capitalTable.optionsStats));
    },
}

export const mutations: MutationTree<CapitalState> = {
    setCapitalTableData(state, payload: any): void {
        state.capitalTable = payload;
    },
    setCapitalTableBusy(state,payload: boolean): void {
        state.capitalTableIsBusy = payload;
    },
}

export const actions: ActionTree<CapitalState, RootState> = {
    async getItems({state,commit}, payload: { filtersQuery?: string, viewedContext?: string }): Promise<Array<any>> {
        payload.filtersQuery = payload.filtersQuery ? payload.filtersQuery : 'by-nominal';

        const headers: any = {
            'x-total-count': true,
        }

        if (payload?.viewedContext) {
            headers['Viewed-Context'] = payload?.viewedContext
        }
        
        const queryData = await axios.get(`${BACKEND_API_URL}/capital/${payload.filtersQuery}`, { headers });

        const data: any = queryData.data.items
        commit('setCapitalTableData', data);

        return data;
    },
}

export const capital: Module<CapitalState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
